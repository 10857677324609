import { Route } from 'react-router-dom';
import Agenda from './modules/agenda/estabelecimento/Agenda';
import Content from './dashboard/content/Content';
import PageNotFound from './components/PageNotFound';
import Servicos from './modules/servicos/Servicos';
import Estabelecimento from './modules/configuracoes/estabelecimento/Estabelecimento';
import Clientes from './modules/clientes/Clientes';
import Usuarios from './modules/usuarios/Usuarios';
import ContentActions from './dashboard/content/Actions';
import ConfiguracoesConta from './modules/configuracoes/usuario/Configuracoes';
import UsuariosSistema from './modules/usuarios-sistema/UsuariosSistema';
import { UsersProvider } from './modules/usuarios-sistema/Context';
import { ServicosProvider } from './modules/servicos/Context';
import NotificationsAll from './notifications/NotificationsAll';
import Atendentes from './modules/atendentes/Atendentes';
import { AtendentesProvider } from './modules/atendentes/Context';
import { AgendaProvider } from './modules/agenda/estabelecimento/ContextAgenda';
import AgendaCliente from './modules/agenda/cliente/Agenda';
import { AgendaClienteProvider } from './modules/agenda/cliente/Context';
import EstabelecimentosClient from './modules/estabelecimentos/client/Estabelecimentos';
import { EstabsClientProvider } from './modules/estabelecimentos/client/Context';
import Planos from './modules/planos/estabelecimento/Planos';
import { PlanosProvider } from './modules/planos/estabelecimento/Context';
import PlanosCliente from './modules/planos/cliente/Planos';
import { brandingEstabId } from './branding';
import { EstabsProvider } from './modules/estabelecimentos/system-admin/Context';
import Estabelecimentos from './modules/estabelecimentos/system-admin/Estabelecimentos';
import { ActionsClients } from './modules/clientes/Actions';
import { ClientsProvider } from './modules/clientes/Context';

const menus = (user: any) => {
    let menus = [
        {
            title: 'Serviços',
            path: '/servicos',
            types: ['root', 'admin'],
            icon: 'fa-solid fa-briefcase',
        },
        {
            title: 'Clientes',
            path: '/clientes',
            types: ['root', 'admin', 'worker'],
            icon: 'fa-solid fa-address-card',
        },
        {
            title: 'Atendentes',
            path: '/atendentes',
            types: ['root', 'admin'],
            icon: 'fa-solid fa-users',
        },
        {
            title: 'Estabelecimentos',
            path: '/estabelecimentos',
            types: ['root'],
            icon: 'fa-solid fa-store',
        },
        {
            title: 'Usuários Sistema',
            path: '/usuario-sistema',
            types: ['root'],
            icon: 'fas fa-users-cog',
        },
        {
            title: 'Meus estabelecimentos',
            path: '/meus-estabelecimentos',
            types: [brandingEstabId ? '' : 'client'],
            icon: 'fa-solid fa-store',
        },
        {
            title: 'Planos',
            path: '/planos',
            types: ['root', 'admin', 'worker', 'client'],
            icon: 'fa-solid fa-briefcase',
        },
    ];

    if (user.client && (user.root || user.worker || user.admin)) {
        menus = [
            {
                title: 'Agenda',
                path: '/',
                types: ['root', 'admin', 'worker'],
                icon: 'fa-solid fa-house',
            },
            {
                title: 'Meus agendamentos',
                path: '/minha-agenda',
                types: ['client'],
                icon: 'fa-solid fa-house',
            },
            ...menus,
        ];
    } else {
        menus = [
            {
                title: 'Agenda',
                path: '/',
                types: ['root', 'admin', 'worker', 'client'],
                icon: 'fa-solid fa-house',
            },
            ...menus,
        ];
    }

    return menus.filter((e) => e?.types?.some((type) => !!user?.[type]));
};

const userRoutes = (user: any) => {
    let routes = [
        {
            path: '/servicos',
            title: 'Serviços',
            types: ['root', 'admin'],
            Element: () => (
                <ServicosProvider>
                    <Servicos />
                </ServicosProvider>
            ),
            Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
        },
        {
            path: '/atendentes',
            title: 'Atendentes',
            types: ['root', 'admin'],
            Element: () => (
                <AtendentesProvider>
                    <Atendentes />
                </AtendentesProvider>
            ),
            Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
        },
        {
            path: '/clientes',
            title: 'Clientes',
            types: ['root', 'admin', 'worker'],
            Provider: ClientsProvider,
            Element: () => <Clientes />,
            Actions: () => <ActionsClients />,
        },
        {
            path: '/estabelecimentos',
            title: 'Estabelecimentos',
            types: ['root'],
            Element: () => (
                <EstabsProvider>
                    <Estabelecimentos />
                </EstabsProvider>
            ),
            Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
        },
        {
            path: '/meus-estabelecimentos',
            title: 'Meus Estabelecimentos',
            types: [brandingEstabId ? '' : 'client'],
            Element: () => (
                <EstabsClientProvider>
                    <EstabelecimentosClient />
                </EstabsClientProvider>
            ),
            Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
        },
        {
            path: '/planos',
            title: 'Planos',
            types: ['root', 'admin'],
            Element: () => (
                <PlanosProvider>
                    <Planos />
                </PlanosProvider>
            ),
            Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
        },
        {
            path: '/planos',
            title: 'Planos',
            types: ['client'],
            Element: () => <PlanosCliente />,
            Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
        },
        {
            path: '/usuarios',
            title: 'Usuários',
            types: ['root'],
            Element: Usuarios,
        },
        {
            path: '/usuario-sistema',
            title: 'Usuários sistema',
            types: ['root'],
            Element: () => (
                <UsersProvider>
                    <UsuariosSistema />
                </UsersProvider>
            ),
            Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
        },
        {
            path: '/configuracoes/usuario',
            title: 'Configurações da Conta',
            types: ['root', 'admin', 'worker', 'client'],
            Element: ConfiguracoesConta,
            returnHome: true,
        },
        {
            path: '/configuracoes/estabelecimento',
            title: 'Estabelecimento',
            types: ['root', 'admin'],
            Element: Estabelecimento,
            returnHome: true,
        },
        {
            path: '/notificacoes',
            title: 'Notificações',
            Element: NotificationsAll,
            returnHome: true,
        },
    ];

    if (user.client && (user.root || user.worker || user.admin)) {
        routes = [
            {
                path: '/',
                title: 'Agenda',
                types: ['root', 'admin', 'worker'],
                Element: () => (
                    <AgendaProvider>
                        <Agenda />
                    </AgendaProvider>
                ),
                Actions: () => <ContentActions actions={[{ type: 'settings' }]} />,
            },
            {
                path: '/agenda',
                title: 'Agenda',
                types: ['root', 'admin', 'worker'],
                Element: () => (
                    <AgendaProvider>
                        <Agenda />
                    </AgendaProvider>
                ),
                Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
            },
            {
                path: '/minha-agenda',
                title: 'Meus agendamentos',
                types: ['client'],
                Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
                Element: () => (
                    <AgendaClienteProvider>
                        <AgendaCliente />
                    </AgendaClienteProvider>
                ),
            },
            ...routes,
        ];
    } else {
        routes = [
            {
                path: '/',
                title: 'Agenda',
                types: ['root', 'admin', 'worker'],
                Element: () => (
                    <AgendaProvider>
                        <Agenda />
                    </AgendaProvider>
                ),
                Actions: () => <ContentActions actions={[{ type: 'settings' }]} />,
            },
            {
                path: '/agenda',
                title: 'Agenda',
                types: ['root', 'admin', 'worker'],
                Element: () => (
                    <AgendaProvider>
                        <Agenda />
                    </AgendaProvider>
                ),
                Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
            },
            {
                path: '/',
                title: 'Agenda',
                types: ['client'],
                Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
                Element: () => (
                    <AgendaClienteProvider>
                        <AgendaCliente />
                    </AgendaClienteProvider>
                ),
            },
            {
                path: '/agenda',
                title: 'Agenda',
                types: ['client'],
                Actions: () => <ContentActions actions={[{ type: 'add' }]} />,
                Element: () => (
                    <AgendaClienteProvider>
                        <AgendaCliente />
                    </AgendaClienteProvider>
                ),
            },
            ...routes,
        ];
    }

    return [
        ...routes
            .filter((e) => e?.types?.some((type) => !!user?.[type]))
            .map(({ path, Element, title, Actions, returnHome, Provider }, i) => (
                <Route
                    key={i}
                    path={path}
                    element={
                        <Content
                            title={title}
                            Element={Element}
                            Actions={Actions}
                            returnHome={returnHome}
                            Provider={Provider}
                        />
                    }
                />
            )),
        <Route key="not-found" path="*" element={<PageNotFound />} />,
    ];
};

export { menus, userRoutes };
