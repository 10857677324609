import React from 'react';

const getInitialName = (name: string): string => {
    if (!name) return '';
    const nameParts = name.split(' ');
    const firstNameInitial = nameParts[0][0];
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : '';
    return (firstNameInitial + lastNameInitial).toUpperCase();
};

interface AvatarUserProps {
    photoURL: string | undefined;
    name: string;
}

export default function AvatarUser({ photoURL, name }: AvatarUserProps) {
    return (
        <div className="avatar placeholder">
            {photoURL ? (
                <div className="w-10 rounded-full">
                    <img src={photoURL} alt="Avatar" />
                </div>
            ) : (
                <div className="bg-neutral text-neutral-content rounded-full w-10 flex items-center justify-center">
                    <span className="text-lg">{getInitialName(name)}</span>
                </div>
            )}
        </div>
    );
}
