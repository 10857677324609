import { api } from '../../../AxiosConfig';

class PlanosService {
    create(data: any) {
        return api.post('/planos/create', data);
    }

    findAll() {
        return api.get('/planos/findAll');
    }

    getInfoById(id: string) {
        return api.get(`/planos/getInfoById/${id}`);
    }

    update(id: string, update: any) {
        return api.post('/planos/update', { id, update });
    }

    delete(id: string) {
        return api.post('/planos/delete', { id });
    }
}

export { PlanosService };
