import { useAppContext } from '../../contexts/AppContext';
import { useEstabsContext } from '../../contexts/EstabsContext';
import Avatar from './Avatar';
import Notifications from '../../notifications/Notifications';
import { urlFile } from '../../AxiosConfig';
import { brandingEstabId } from '../../branding';
import { EstabelecimentosService } from '../../modules/estabelecimentos/system-admin/Service';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { userRoutes } from '../../routes';

const estabelecimentosService = new EstabelecimentosService();

export default function Header() {
    const { estabSession } = useEstabsContext();
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState<any>();
    const { toggleShortMenu, isScrolled, toggleShowDrawerMenu } = useAppContext();
    const { user } = useAuthContext();

    const [title, setTitle] = useState(estabSession?.name);
    const [image, setImage] = useState(estabSession?.imageId);

    useEffect(() => {
        if (!!brandingEstabId) {
            estabelecimentosService.getInfoById(brandingEstabId).then(({ data }) => {
                if (!!data.name) {
                    setTitle(data.name);
                }

                if (!!data.imageId) {
                    setImage(data.imageId);
                }
            });
        }

        setCurrentPath(userRoutes(user).find((e: any) => e?.path === location.pathname));
    }, []);

    useEffect(() => {
        if (!!estabSession) {
            setTitle(estabSession?.name);
            setImage(estabSession?.imageId);
        }

    }, [estabSession]);

    return (
        <div
            className={`flex justify-between items-center py-2 backdrop-blur-lg bg-white bg-opacity-75 px-3 ${
                isScrolled ? 'shadow-[0_15px_15px_-14px_rgba(0,0,0,0.3)]' : 'border-b border-gray-50'
            }`}
        >
            <div className="flex items-center">
                <button
                    className="btn btn-circle"
                    onClick={() => {
                        toggleShortMenu();
                        toggleShowDrawerMenu();
                    }}
                >
                    <i className="fa-solid fa-bars"></i>
                </button>

                <div
                    className={`ml-20 absolute font-medium transition-opacity duration-300 hidden sm:block ${
                        isScrolled ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    {currentPath?.title}
                </div>
            </div>

            <div className="avatar">
                {image ? (
                    <div className="h-10 w-40 text-center content-center relative">
                        <img src={`${urlFile}/${image}`} alt="file preview" />
                    </div>
                ) : (
                    <span>{title}</span>
                )}
            </div>

            <div className="flex justify-center">
                <Notifications />
                <Avatar />
            </div>
        </div>
    );
}
