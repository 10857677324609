export const Footer = () => {
    return (
        <footer className="footer footer-center p-10 text-base-content rounded">
            <aside>
                <span>Copyright © {new Date().getFullYear()} - Salon Flow</span>
                <span className="text-xs gap-4 flex">
                    <a href="/termosDeUso" target="_blank" rel="noopener noreferrer">
                        Termos de uso
                    </a>
                    <a href="/politicaDePrivacidade" target="_blank" rel="noopener noreferrer">
                        Política de privacidade
                    </a>
                </span>
            </aside>
        </footer>
    );
};
