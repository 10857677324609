import { api } from '../../../AxiosConfig';

class EstabelecimentosService {
    create(data: any) {
        return api.post('/estabelecimentos/create', data);
    }

    findAll() {
        return api.get('/estabelecimentos');
    }

    getInfoById(id: string) {
        return api.get(`/estabelecimentos/getInfoById/${id}`);
    }

    update(id: string, update: any) {
        return api.post('/estabelecimentos/update', { id, update });
    }

    delete(id: string) {
        return api.post('/estabelecimentos/delete', { id });
    }
}

export { EstabelecimentosService };
