import React from 'react';
import { useParamsContext } from '../../contexts/ParamsContext';
import CustomDataTable from '../../components/table/Table';

interface DataRow {
    date: string;
    description: string;
}

const data: DataRow[] = [
    {
        date: '15/02/2024 às 14:35',
        description: 'Agendou um serviço: Corte raspado',
    },
    {
        date: '16/02/2024 às 10:00',
        description: 'Finalizou o serviço: Barba completa',
    },
    {
        date: '17/02/2024 às 09:00',
        description: 'Iniciou um serviço: Massagem relaxante',
    },
    {
        date: '18/02/2024 às 13:20',
        description: 'Cancelou o serviço: Corte de cabelo',
    },
    {
        date: '19/02/2024 às 11:15',
        description: 'Agendou um serviço: Manicure',
    },
    {
        date: '20/02/2024 às 16:45',
        description: 'Recebeu um reembolso: Serviço de pedicure',
    },
    {
        date: '21/02/2024 às 14:00',
        description: 'Finalizou o serviço: Tratamento facial',
    },
    {
        date: '22/02/2024 às 09:30',
        description: 'Iniciou um serviço: Spa completo',
    },
    {
        date: '23/02/2024 às 10:50',
        description: 'Reagendou um serviço: Tintura de cabelo',
    },
    {
        date: '24/02/2024 às 15:30',
        description: 'Adicionou um serviço extra: Depilação',
    },
    {
        date: '21/02/2024 às 14:00',
        description: 'Finalizou o serviço: Tratamento facial',
    },
    {
        date: '22/02/2024 às 09:30',
        description: 'Iniciou um serviço: Spa completo',
    },
    {
        date: '23/02/2024 às 10:50',
        description: 'Reagendou um serviço: Tintura de cabelo',
    },
    {
        date: '24/02/2024 às 15:30',
        description: 'Adicionou um serviço extra: Depilação',
    },
    {
        date: '21/02/2024 às 14:00',
        description: 'Finalizou o serviço: Tratamento facial',
    },
    {
        date: '22/02/2024 às 09:30',
        description: 'Iniciou um serviço: Spa completo',
    },
    {
        date: '23/02/2024 às 10:50',
        description: 'Reagendou um serviço: Tintura de cabelo',
    },
    {
        date: '24/02/2024 às 15:30',
        description: 'Adicionou um serviço extra: Depilação',
    },
];

const columns = [
    {
        name: 'Data',
        selector: (row: DataRow) => row.date,
        sortable: true,
        cell: (row: DataRow) => <div style={{ textAlign: 'right' }}>{row.date}</div>,
    },
    {
        name: 'Descrição',
        selector: (row: DataRow) => row.description,
        sortable: true,
        cell: (row: DataRow) => <div style={{ textAlign: 'right' }}>{row.description}</div>,
    },
];

export default function ClientesHistory() {
    return (
        <div>
            <CustomDataTable columns={columns} data={data} pagination highlightOnHover striped />
        </div>
    );
}
