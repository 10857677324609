import React, { createContext, useContext, useEffect, useState } from 'react';
import { useEstabsContext } from '../../../../contexts/EstabsContext';
import { EstabelecimentosService } from '../../../estabelecimentos/system-admin/Service';
import { useAppContext } from '../../../../contexts/AppContext';

const service = new EstabelecimentosService();

interface EstabFormParams {
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    imageId: string;
    setImageId: React.Dispatch<React.SetStateAction<string>>;
    cnpj: string;
    setCnpj: React.Dispatch<React.SetStateAction<string>>;
    street: string;
    setStreet: React.Dispatch<React.SetStateAction<string>>;
    number: string;
    setNumber: React.Dispatch<React.SetStateAction<string>>;
    complement: string;
    setComplement: React.Dispatch<React.SetStateAction<string>>;
    neighborhood: string;
    setNeighborhood: React.Dispatch<React.SetStateAction<string>>;
    city: string;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    state: string;
    setState: React.Dispatch<React.SetStateAction<string>>;
    zipCode: string;
    setZipCode: React.Dispatch<React.SetStateAction<string>>;
    description: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    twitter: string;
    setTwitter: React.Dispatch<React.SetStateAction<string>>;
    addressUrl: string;
    setAddressUrl: React.Dispatch<React.SetStateAction<string>>;
    openingHours: string;
    setOpeningHours: React.Dispatch<React.SetStateAction<string>>;
    website: string;
    setWebsite: React.Dispatch<React.SetStateAction<string>>;
    facebook: string;
    setFacebook: React.Dispatch<React.SetStateAction<string>>;
    instagram: string;
    setInstagram: React.Dispatch<React.SetStateAction<string>>;
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    isPhoneNumberWhatsapp: boolean;
    setIsPhoneNumberWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
    activeLandingPage: boolean;
    setActiveLandingPage: React.Dispatch<React.SetStateAction<boolean>>;
    landingPageTitle: string;
    setLandingPageTitle: React.Dispatch<React.SetStateAction<string>>;
    landingPageDescription: string;
    setLandingPageDescription: React.Dispatch<React.SetStateAction<string>>;
    landingPageImagesIdsHeader: string[];
    setLandingPageAbout: React.Dispatch<React.SetStateAction<string>>;
    landingPageAbout: string;
    setLandingName: React.Dispatch<React.SetStateAction<string>>;
    landingPageName: string;
    setLandingPageIcoId: React.Dispatch<React.SetStateAction<string>>;
    landingPageIcoId: string;
    setLandingPageImagesIdsHeader: React.Dispatch<React.SetStateAction<string[]>>;
    landingPageServicesIds: string[];
    setLandingPageServicesIds: React.Dispatch<React.SetStateAction<string[]>>;
    landingPageImagesIds: string[];
    setLandingPageImagesIds: React.Dispatch<React.SetStateAction<string[]>>;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const EstabFormContext = createContext<EstabFormParams | undefined>(undefined);

export const useEstabFormContext = () => {
    const context = useContext(EstabFormContext);
    if (!context) {
        throw new Error('useEstabFormContext must be used within a EstabFormProvider');
    }
    return context;
};

export const EstabFormProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { estabSession, reloadEstab } = useEstabsContext();
    const { errorSnackbar, successSnackbar } = useAppContext();

    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [landingPageAbout, setLandingPageAbout] = useState('');
    const [openingHours, setOpeningHours] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [landingPageName, setLandingName] = useState('');
    const [landingPageIcoId, setLandingPageIcoId] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [addressUrl, setAddressUrl] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [description, setDescription] = useState('');
    const [imageId, setImageId] = useState('');
    const [email, setEmail] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhoneNumberWhatsapp, setIsPhoneNumberWhatsapp] = useState(false);
    const [activeLandingPage, setActiveLandingPage] = useState(true);

    const [landingPageTitle, setLandingPageTitle] = useState('');
    const [landingPageDescription, setLandingPageDescription] = useState('');
    const [landingPageImagesIdsHeader, setLandingPageImagesIdsHeader] = useState<string[]>([]);
    const [landingPageServicesIds, setLandingPageServicesIds] = useState<string[]>([]);
    const [landingPageImagesIds, setLandingPageImagesIds] = useState<string[]>([]);

    useEffect(() => {
        if (!!estabSession?._id) {
            setName(estabSession?.name || '');
            setCnpj(estabSession?.cnpj || '');
            setLandingPageAbout(estabSession?.landingPage?.about || '');
            setOpeningHours(estabSession?.openingHours || '');
            setStreet(estabSession?.address?.street || '');
            setNumber(estabSession?.address?.number || '');
            setComplement(estabSession?.address?.complement || '');
            setNeighborhood(estabSession?.address?.neighborhood || '');
            setCity(estabSession?.address?.city || '');
            setState(estabSession?.address?.state || '');
            setAddressUrl(estabSession?.address?.addressUrl || '');
            setZipCode(estabSession?.address?.zipCode || '');
            setDescription(estabSession?.description || '');
            setEmail(estabSession?.email || '');
            setTwitter(estabSession?.twitter || '');
            setWebsite(estabSession?.website || '');
            setFacebook(estabSession?.facebook || '');
            setInstagram(estabSession?.instagram || '');
            setPhoneNumber(estabSession?.phoneNumber || '');
            setIsPhoneNumberWhatsapp(estabSession?.isPhoneNumberWhatsapp || false);
            setImageId(estabSession?.imageId || '');

            setActiveLandingPage(!!estabSession?.landingPage?.active);
            setLandingPageTitle(estabSession?.landingPage?.title || '');
            setLandingName(estabSession?.landingPage?.pageName || '');
            setLandingPageIcoId(estabSession?.landingPage?.iconId || '');
            setLandingPageDescription(estabSession?.landingPage?.description || '');
            setLandingPageImagesIdsHeader(estabSession?.landingPage?.imagesIdsHeader || []);
            setLandingPageServicesIds(estabSession?.landingPage?.servicesIds || []);
            setLandingPageImagesIds(estabSession?.landingPage?.imagesIds || []);

            setLoading(false);
        }
    }, [estabSession]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            await service.update(estabSession._id, {
                name,
                cnpj,
                openingHours,
                address: {
                    street,
                    number,
                    complement,
                    neighborhood,
                    city,
                    state,
                    zipCode,
                    addressUrl,
                },
                description,
                imageId,
                email,
                website,
                facebook,
                twitter,
                instagram,
                phoneNumber,
                isPhoneNumberWhatsapp,
                landingPage: {
                    active: activeLandingPage,
                    title: landingPageTitle,
                    description: landingPageDescription,
                    imagesIdsHeader: landingPageImagesIdsHeader,
                    servicesIds: landingPageServicesIds,
                    imagesIds: landingPageImagesIds,
                    about: landingPageAbout,
                    pageName: landingPageName,
                    iconId: landingPageIcoId,
                },
            });

            successSnackbar('Estabelecimento alterado com sucesso!');
            reloadEstab();
        } catch (e) {
            console.log(e);
            errorSnackbar('Ocorreu um erro ao alterar o estabelecimento.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <EstabFormContext.Provider
            value={{
                name,
                setName,
                cnpj,
                setCnpj,
                street,
                setStreet,
                twitter,
                setTwitter,
                landingPageAbout,
                setLandingPageAbout,
                number,
                setNumber,
                openingHours,
                landingPageIcoId,
                setLandingPageIcoId,
                landingPageName,
                setLandingName,
                setOpeningHours,
                complement,
                addressUrl,
                setAddressUrl,
                setComplement,
                neighborhood,
                setNeighborhood,
                city,
                activeLandingPage,
                setActiveLandingPage,
                setCity,
                state,
                setState,
                zipCode,
                setZipCode,
                description,
                setDescription,
                email,
                setEmail,
                website,
                setWebsite,
                facebook,
                setFacebook,
                instagram,
                setInstagram,
                phoneNumber,
                setPhoneNumber,
                imageId,
                setImageId,
                handleSubmit,
                loading,
                setLoading,
                isPhoneNumberWhatsapp,
                setIsPhoneNumberWhatsapp,
                landingPageTitle,
                setLandingPageTitle,
                landingPageDescription,
                setLandingPageDescription,
                landingPageImagesIdsHeader,
                setLandingPageImagesIdsHeader,
                landingPageServicesIds,
                setLandingPageServicesIds,
                landingPageImagesIds,
                setLandingPageImagesIds,
            }}
        >
            {children}
        </EstabFormContext.Provider>
    );
};
