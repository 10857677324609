import ReactApexChart from 'react-apexcharts';
import { useEffect, useRef, useState } from 'react';
import { useDashboardContext } from './Context';
import { DashboardService } from './service';
import moment from 'moment/moment';

const service = new DashboardService();

export const ChatsConclusoesMes = () => {
    const [loading, setLoading] = useState(false);
    const { competence } = useDashboardContext();
    const previousCompetenceRef = useRef(competence);

    const [state, setState] = useState<any>({
        series: [
            {
                name: 'Concluídos',
                data: [],
            },
        ],
        options: {
            chart: {
                id: 'area',
                type: 'area',
                zoom: {
                    enabled: false,
                },
            },
            title: {
                text: 'Concluídos',
                align: 'left',
                margin: 0,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '20px',
                },
            },
            subtitle: {
                text: `Competência: ${moment(competence).format('MMMM [de] YYYY')}`,
                align: 'left',
                margin: 0,
                offsetX: 0,
                offsetY: 25,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'dd/MM/yyyy',
                },
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yyyy',
                },
            },
            colors: ['#28a745'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100],
                },
            },
        },
    });

    useEffect(() => {
        const fetchData = async (competence: string) => {
            if (!loading) {
                try {
                    setLoading(true);

                    await service.getConclusoesCompetence(competence).then((response) => {
                        const transformedData = response.data.map((item: any) => [
                            new Date(item.date).getTime(),
                            item.count,
                        ]);

                        setState((prevState: any) => {
                            prevState.options.subtitle.text = `Competência: ${moment(competence).format('MMMM [de] YYYY')}`;

                            return {
                                ...prevState,
                                series: [
                                    {
                                        ...prevState.series[0],
                                        data: transformedData,
                                    },
                                ],
                            };
                        });
                    });
                } catch (e) {
                    console.error('Erro ao buscar dados:', e);
                } finally {
                    setTimeout(function (){
                        setLoading(false);

                        if (previousCompetenceRef.current !== competence) {
                            fetchData(previousCompetenceRef.current);
                        }
                    }, 300)
                }
            }
        };

        previousCompetenceRef.current = competence;
        fetchData(competence);
    }, [competence]);

    return (
        <div id="chart" className={`${loading && 'opacity-30 cursor-not-allowed'}`}>
            <ReactApexChart
                key={state.options.subtitle.text}
                options={state.options}
                series={state.series}
                type="area"
                height={350}
            />
        </div>
    );
};
