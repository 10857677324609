import { api } from '../AxiosConfig';

class NotificationsService {
    getAll() {
        return api.get('notifications/getAll');
    }

    viewAll() {
        return api.post('notifications/viewAll');
    }

    view(_id: string) {
        return api.post('notifications/view', { _id });
    }

    removeAll() {
        return api.post('notifications/removeAll');
    }
}

export { NotificationsService };
