import React, { useEffect, useState } from 'react';

interface DurationInputProps {
    value: number;
    onChange: (value: number) => void;
    placeholder: string;
    label: string;
}

const DurationInput: React.FC<DurationInputProps> = ({ value, onChange, placeholder, label }) => {
    const [displayValue, setDisplayValue] = useState<string>('');

    useEffect(() => {
        const hours = Math.floor(value / 60);
        const mins = value % 60;
        setDisplayValue(`${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const [hours, mins] = event.target.value.split(':').map(Number);
        const totalMinutes = (hours || 0) * 60 + (mins || 0);
        setDisplayValue(event.target.value);
        onChange(totalMinutes);
    };

    return (
        <label className="form-control">
            <div className="label">
                <span className="label-text">{label}</span>
            </div>
            <input
                type="time"
                value={displayValue}
                onChange={handleChange}
                className="input input-bordered"
                placeholder={placeholder}
            />
        </label>
    );
};

export default DurationInput;
