import React, { createContext, useContext, useEffect, useState } from 'react';
import { AgendaClienteService } from './Service';
import { Agendamento, StatusHorario } from '../Enum';
import { useAuthContext } from '../../../contexts/AuthContext';
import WebSocketManager from '../../../WebSocketConfig';
const agendaService = new AgendaClienteService();

interface AgendaClienteParams {
    loading: boolean;
    onlyActive: boolean;
    setOnlyActive: React.Dispatch<React.SetStateAction<boolean>>;
    agendamentos: Agendamento[];
    loadAgendamentos: () => void;
}

const AgendaClienteContext = createContext<AgendaClienteParams | undefined>(undefined);

export const useAgendaClienteContext = () => {
    const context = useContext(AgendaClienteContext);
    if (!context) {
        throw new Error('useAgendaClienteContext must be used within an AgendaClienteContext');
    }
    return context;
};

export const AgendaClienteProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [agendamentos, setAgendamentos] = useState<Agendamento[]>([]);
    const [allAgendamentos, setAllAgendamentos] = useState<Agendamento[]>([]);
    const [onlyActive, setOnlyActive] = useState(localStorage.getItem('onlyActive') === 'true');

    const loadAgendamentos = async () => {
        try {
            const { data } = await agendaService.getAgendamentosCards();

            setAllAgendamentos(data);
        } catch (error) {
            console.error('Error fetching agendamentos:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadAgendamentos();
    }, []);

    useEffect(() => {
        setAgendamentos(
            allAgendamentos?.filter((agendamento: Agendamento) => {
                if (onlyActive) {
                    return ![StatusHorario.CANCELADO, StatusHorario.CONCLUIDO].includes(agendamento.status);
                }
                return agendamento;
            })
        );

        localStorage.setItem('onlyActive', onlyActive.toString());
    }, [onlyActive, allAgendamentos]);

    useEffect(() => {
        const webSocketManager = new WebSocketManager();

        if (user?.uid) {
            webSocketManager.onChannel(`horarios:cliente`, (message) => {
                loadAgendamentos();
            });
        }
    }, [user]);

    return (
        <AgendaClienteContext.Provider
            value={{
                loading,
                onlyActive,
                agendamentos,
                setOnlyActive,
                loadAgendamentos,
            }}
        >
            {children}
        </AgendaClienteContext.Provider>
    );
};
