import NotificationItem from './Item';
import { useNotificationsContext } from './NotificationsContext';

export default function NotificationsAll() {
    const { notifications, onlyNotVisualized, setOnlyNotVisualized } = useNotificationsContext();

    return (
        <div className="w-3/4 m-auto">
            <div className="card-body">
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text">Apenas não lidas</span>
                        <input
                            type="checkbox"
                            className="toggle toggle-sm"
                            defaultChecked={onlyNotVisualized}
                            onClick={() => setOnlyNotVisualized(!onlyNotVisualized)}
                        />
                    </label>
                </div>

                <div className="divider" style={{ padding: 0, margin: 0 }}></div>

                {notifications.length === 0 ? (
                    <p className="text-sm text-gray-500">Nenhuma notificação</p>
                ) : (
                    <ul
                        className="menu menu-lg"
                        style={{
                            padding: 0,
                        }}
                    >
                        {notifications.map((notification, index) => (
                            <NotificationItem key={index} notification={notification} />
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}
