import { useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { StatusHorario } from '../../Enum';
import { HorariosAtendenteService } from '../../Service';
import Dropdown from '../../../../components/Dropdown';

const horarioService = new HorariosAtendenteService();

interface ScheduleStatusProps {
    id: string;
    clientId: string;
    status: StatusHorario;
    size?: 'xs' | 'sm' | 'md' | 'lg';
}

export default function ScheduleStatus({ id, clientId, status, size = 'sm' }: ScheduleStatusProps) {
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(false);

    const isClient = user?.uid === clientId;
    const cursorStyle = isClient ? 'default' : 'pointer';

    switch (status) {
        case StatusHorario.PENDENTE:
            return (
                <Dropdown
                    loading={loading}
                    actions={
                        isClient
                            ? []
                            : [
                                  {
                                      label: 'Aprovar',
                                      action: async () => {
                                          try {
                                              setLoading(true);
                                              await horarioService.approve(id);
                                          } catch (e) {
                                              console.log(e);
                                          } finally {
                                              setLoading(false);
                                          }
                                      },
                                  },
                                  {
                                      label: 'Cancelar',
                                      action: async () => {
                                          try {
                                              setLoading(true);
                                              await horarioService.cancel(id);
                                          } catch (e) {
                                              console.log(e);
                                          } finally {
                                              setLoading(false);
                                          }
                                      },
                                  },
                              ]
                    }
                    label="Pendente"
                    buttonClass={`btn btn-${size} btn-warning`}
                />
            );
        case StatusHorario.AGENDADO:
            return (
                <Dropdown
                    loading={loading}
                    label="Agendado"
                    buttonClass={`btn btn-${size} btn-primary`}
                    actions={
                        isClient
                            ? []
                            : [
                                  {
                                      label: 'Concluir',
                                      action: async () => {
                                          try {
                                              setLoading(true);
                                              await horarioService.conclude(id);
                                          } catch (e) {
                                              console.log(e);
                                          } finally {
                                              setLoading(false);
                                          }
                                      },
                                  },
                                  {
                                      label: 'Cancelar',
                                      action: async () => {
                                          try {
                                              setLoading(true);
                                              await horarioService.cancel(id);
                                          } catch (e) {
                                              console.log(e);
                                          } finally {
                                              setLoading(false);
                                          }
                                      },
                                  },
                              ]
                    }
                />
            );
        case StatusHorario.CONCLUIDO:
            return (
                <Dropdown
                    loading={loading}
                    actions={[]}
                    label="Concluído"
                    buttonClass={`btn btn-${size} btn-success`}
                />
            );
        case StatusHorario.CANCELADO:
            return (
                <Dropdown
                    loading={loading}
                    actions={[]}
                    label="Cancelado"
                    buttonClass={`btn btn-${size} btn-error`}
                />
            );
        default:
            return (
                <button
                    type="button"
                    style={{ cursor: cursorStyle, minWidth: 'fit-content' }}
                    className={`btn opacity-80 btn-${size}`}
                >
                    btn-secondary
                </button>
            );
    }
}
