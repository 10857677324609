import { getToken } from 'firebase/messaging';
import { keyPushNotificationWeb, messaging } from './firebase';
import { UserService } from './modules/configuracoes/usuario/Service';

export class PushNotification {
    private userService: UserService;

    constructor() {
        this.userService = new UserService();
        this.getToken();
    }

    private requestPermission() {
        Notification.requestPermission()
            .then((permission) => {
                if (permission === 'granted') {
                    getToken(messaging, {
                        vapidKey: keyPushNotificationWeb,
                    })
                        .then((currentToken) => {
                            if (currentToken) {
                                this.userService.saveTokenPushNotification(currentToken);
                            }
                        })
                        .catch((err) => {
                            console.error('An error occurred while retrieving token.', err);
                        });
                } else {
                    console.log('Notification permission denied.');
                }
            })
            .catch((err) => {
                console.error('Error while requesting permission.', err);
            });
    }

    private getToken() {
        getToken(messaging, {
            vapidKey: keyPushNotificationWeb,
        })
            .then((currentToken) => {
                if (currentToken) {
                    this.userService.saveTokenPushNotification(currentToken);
                } else {
                    console.log('No registration token available. Requesting permission to generate one.');
                    this.requestPermission();
                }
            })
            .catch((err) => {
                console.error('An error occurred while retrieving token.', err);
            });
    }
}
