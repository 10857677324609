import { tr } from 'date-fns/locale';
import { useState } from 'react';
import { Loading } from '../../../../components/Loading';

export const CancelModal = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
    const [loading, setLoading] = useState(false);

    const onClickConfirm = async () => {
        try {
            setLoading(true);
            await onConfirm();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="card bg-base-100 w-96 shadow-xl text-black">
            <div className="card-body">
                <h2 className="card-title">Cancelamento</h2>
                <p>Deseja realmente cancelar o agendamento?</p>
                <div className="flex justify-between mt-4">
                    <button className="btn btn-ghost" onClick={onCancel} disabled={loading}>
                        Cancelar
                    </button>
                    <button className="btn btn-secondary" onClick={onClickConfirm} disabled={loading}>
                        {loading ? <Loading /> : 'Confirmar'}
                    </button>
                </div>
            </div>
        </div>
    );
};
