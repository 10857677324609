import { useState } from 'react';
import { UserService } from '../modules/configuracoes/usuario/Service';
import { useAppContext } from '../contexts/AppContext';
const service = new UserService();

export default function RecoverPassword({ setRecoverPass }: any) {
    const [form, setForm] = useState(1);
    const { errorSnackbar, successSnackbar } = useAppContext();

    const [code, setCode] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const onSubmitRequest = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!!loading) return;

        setLoading(true);

        try {
            await service.requestRecoverPassword(email);
            setForm(2);
        } catch (error: any) {
            errorSnackbar('Ocorreu um erro ao tentar recuperar a senha.');
        } finally {
            setLoading(false);
        }
    };

    const onSubmitValidRequest = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!!loading) return;

        setLoading(true);

        try {
            const response = await service.validRequestRecoverPassword(email, code);

            setForm(3);
            setToken(response.data.token);
        } catch (error: any) {
            errorSnackbar('Código inválido ou expirado.');
        } finally {
            setLoading(false);
        }
    };

    const changePassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!!loading) return;

        if (password !== confirmPassword) {
            errorSnackbar('As novas senhas não coincidem.');
            return;
        }

        setLoading(true);

        try {
            await service.recoverPassword(email, token, password);

            successSnackbar('Senha alterada com sucesso!');
            setRecoverPass(false);
        } catch (error: any) {
            errorSnackbar('Ocorreu um erro ao tentar alterar a senha.');
        } finally {
            setLoading(false);
        }
    };

    switch (form) {
        case 3:
            return (
                <form key={3} className="space-y-4 md:space-y-6" onSubmit={changePassword}>
                    <div className="flex items-center" onClick={() => setRecoverPass(false)}>
                        <button className="btn btn-xs btn-circle mr-2">
                            <i className="fa-solid fa-angle-left"></i>
                        </button>
                        Recuperar Senha
                    </div>

                    <div>
                        <label className="label" htmlFor="newPassword">
                            <span className="label-text">Nova senha</span>
                        </label>
                        <input
                            type="password"
                            id="newPassword"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="input input-bordered"
                        />
                    </div>

                    <div>
                        <label className="label" htmlFor="confirmPassword">
                            <span className="label-text">Confirme a nova senha</span>
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="input input-bordered"
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full h-10 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-black text-white"
                    >
                        {loading ? <span className="loading loading-bars loading-sm" /> : 'Continuar'}
                    </button>
                </form>
            );

        case 2:
            return (
                <form key={2} className="space-y-4 md:space-y-6" onSubmit={onSubmitValidRequest}>
                    <div className="flex items-center" onClick={() => setRecoverPass(false)}>
                        <button className="btn btn-xs btn-circle mr-2">
                            <i className="fa-solid fa-angle-left"></i>
                        </button>
                        Recuperar Senha
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Digite o código enviado para <b>{email}</b>
                        </label>
                        <input
                            type="text"
                            name="code"
                            id="code"
                            disabled={loading}
                            onChange={(e) => setCode(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full h-10 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-black text-white"
                    >
                        {loading ? <span className="loading loading-bars loading-sm" /> : 'Continuar'}
                    </button>
                </form>
            );
        case 1:
        default:
            return (
                <form className="space-y-4 md:space-y-6" onSubmit={onSubmitRequest} key={1}>
                    <div className="flex items-center" onClick={() => setRecoverPass(false)}>
                        <button className="btn btn-xs btn-circle mr-2">
                            <i className="fa-solid fa-angle-left"></i>
                        </button>
                        Recuperar Senha
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            disabled={loading}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="name@company.com"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full h-10 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-black text-white"
                    >
                        {loading ? <span className="loading loading-bars loading-sm" /> : 'Continuar'}
                    </button>
                </form>
            );
    }
}
