import moment from 'moment';
import 'moment/locale/pt-br';
import CarouselImgs from '../../../../components/files/CarouselImg';
import { Agendamento } from '../../Enum';
import { formatToReal } from '../../../../components/utils';
import { baseURL, urlFile } from '../../../../AxiosConfig';
import ScheduleStatus from '../../estabelecimento/components/ScheduleStatus';

moment.locale('pt-br');

export default function ScheduleView({ schedule }: { schedule: Agendamento }) {
    return (
        <div className="card bg-white overflow-hidden">
            <div className="card-body p-6">
                <div className="flex justify-between items-center">
                    <h2 className="card-title text-2xl font-semibold">Detalhes do Agendamento</h2>
                </div>

                <div className="divider">Estabelecimento</div>
                <div className="flex gap-4 mb-6">
                    {schedule?.estab?.imageId && (
                        <div className="h-44 rounded-lg overflow-hidden ">
                            <img src={`${urlFile}/${schedule?.estab?.imageId}`} alt="Shoes" />
                        </div>
                    )}

                    <div className="flex-1">
                        <h2 className="card-title">
                            {schedule?.estab?.name}
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>{schedule?.estab?.description}</p>

                        <p className="text-xs">
                            {[
                                schedule?.estab?.address?.street,
                                schedule?.estab?.address?.number,
                                schedule?.estab?.address?.complement,
                                schedule?.estab?.address?.neighborhood,
                                schedule?.estab?.address?.city,
                                schedule?.estab?.address?.state,
                                schedule?.estab?.address?.zipCode,
                            ]
                                .filter(Boolean)
                                .join(', ')}
                        </p>

                        <div className="flex gap-3 mt-3">
                            <button
                                onClick={() => {
                                    if (schedule?.estab?.phoneNumber && schedule?.estab?.isPhoneNumberWhatsapp) {
                                        const phoneNumber = schedule?.estab.phoneNumber.replace(/\D/g, '');
                                        window.open(`https://wa.me/${phoneNumber}`, '_blank', 'noopener noreferrer');
                                    }
                                }}
                                className={'btn btn-circle btn-sm'}
                                disabled={!schedule?.estab?.phoneNumber || !schedule?.estab?.isPhoneNumberWhatsapp}
                            >
                                <i className="fa-brands fa-whatsapp"></i>
                            </button>

                            <button
                                onClick={() => {
                                    if (schedule?.estab?.location) {
                                        window.open(schedule?.estab.location, '_blank', 'noopener noreferrer');
                                    }
                                }}
                                className={'btn btn-circle btn-sm'}
                                disabled={!schedule?.estab?.location}
                            >
                                <i className="fa-solid fa-location-dot"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="divider" style={{ padding: 0, margin: 0 }}>
                    Serviço
                </div>

                <div className="flex gap-4 mb-6">
                    <div className="w-52 h-44 rounded-lg overflow-hidden">
                        <CarouselImgs imagesIds={schedule.service.imagesIds} autoPlay />
                    </div>

                    <div className="flex-1">
                        <p className="font-medium mb-2">
                            <strong>Serviço:</strong> {schedule.service.name} - <strong>Preço:</strong>{' '}
                            <span>R$ {formatToReal(schedule.service.price)}</span>
                        </p>

                        <p className="mb-2 text-sm">
                            <strong>Data do Agendamento:</strong>{' '}
                            {moment(schedule.scheduleDate).format('DD/MM/YYYY às HH:mm')}
                        </p>

                        {schedule.confirmationDate && (
                            <p className="mb-2 text-sm">
                                <strong>Conf. do Agendamento:</strong>{' '}
                                {moment(schedule.confirmationDate).format('DD/MM/YYYY às HH:mm')}
                            </p>
                        )}

                        <p className="mb-2 text-sm">
                            <strong>Status:</strong>{' '}
                            <ScheduleStatus
                                clientId={schedule.client.uid}
                                key={schedule._id}
                                id={schedule._id}
                                status={schedule.status}
                                size="xs"
                            />
                        </p>

                        {schedule?.confirmation?.date && (
                            <div className="text-xs text-gray-600 flex items-center gap-1">
                                <i className="fa-solid fa-calendar-check text-green-900"></i>
                                Confirmado{' '}
                                <span className="font-medium">{moment(schedule?.confirmation?.date).fromNow()}</span>
                            </div>
                        )}

                        {schedule?.cancellation?.date && (
                            <div className="text-xs text-gray-600 flex items-center gap-1">
                                <i className="fa-solid fa-calendar-xmark text-red-700"></i>
                                Cancelado{' '}
                                <span className="font-medium">{moment(schedule?.cancellation?.date).fromNow()}</span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="divider">Atendente</div>
                <div className="grid justify-center mb-4">
                    <div className="avatar grid justify-center mb-4">
                        <div className="mask mask-squircle w-24">
                            <img
                                src={`${baseURL}/users/profileImage/${schedule.attendant.uid}`}
                                alt={`${schedule.attendant.name} - Perfil`}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <p className="text-lg font-bold">{schedule.attendant.name}</p>
                        <p className="text-xs">{schedule.attendant.email}</p>
                        {schedule.attendant.phoneNumber && (
                            <div>
                                <p className="text-xs">{schedule.attendant.phoneNumber}</p>

                                <a
                                    href={`https://wa.me/${schedule.attendant.phoneNumber}`}
                                    className="btn btn-circle btn-sm mt-2 flex items-center justify-center w-12 h-12 bg-green-500 hover:bg-green-600 text-white transition-colors"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                {schedule?.assessment?.value && (
                    <div className="text-center">
                        <div className="divider">Avaliação</div>
                        <div>
                            <p>
                                <div className="rating rating-lg">
                                    {[...Array(5)].map((_, index) => (
                                        <input
                                            key={index}
                                            type="radio"
                                            name="rating-8"
                                            className="mask mask-star-2 bg-orange-400"
                                            disabled
                                            defaultChecked={index + 1 === schedule?.assessment?.value}
                                        />
                                    ))}
                                </div>
                            </p>
                            <p>{schedule?.assessment?.observation}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
