import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { UsuariosSistemaService } from './Service';
const service = new UsuariosSistemaService();

interface User {
    uid: string;
    email: string;
    emailVerified: boolean;
    displayName: string;
    photoURL: string;
    phoneNumber: string;
    disabled: boolean;
    metadata: any;
}

interface UsersContextProps {
    users: User[];
    loading: boolean;
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    fetchUsers: () => Promise<void>;
}

const UsersContext = createContext<UsersContextProps | undefined>(undefined);

export const useUsersContext = () => {
    const context = useContext(UsersContext);
    if (!context) {
        throw new Error('useUsersContext must be used within a UsersProvider');
    }
    return context;
};

export const UsersProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);

    const fetchUsers = async () => {
        try {
            const result = await service.getAll().then((result) => {
                return result?.data?.data || [];
            });
            setUsers(result);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return <UsersContext.Provider value={{ users, setUsers, fetchUsers, loading }}>{children}</UsersContext.Provider>;
};
