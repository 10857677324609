import { useNavigate } from 'react-router-dom';
import { menus } from '../../routes';
import { useEstabsContext } from '../../contexts/EstabsContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useAppContext } from '../../contexts/AppContext';
import {brandingEstabId} from "../../branding";

export default function MenuDrawer() {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { switchEstab, estabsUser, estabSession } = useEstabsContext();
    const { toggleShowDrawerMenu } = useAppContext();

    return (
        <div className={`w-min pt-20 bg-base-200 h-full`}>
            <ul className="menu rounded-box p-0">
                {menus(user).map(({ title, icon, path }, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            navigate(path);
                            toggleShowDrawerMenu();
                        }}
                    >
                        <li className="pb-1">
                            <a className="flex items-center">
                                <i className={`${icon} text-lg`}></i>
                                {title}
                            </a>
                        </li>
                    </div>
                ))}

                {(!brandingEstabId && !!estabsUser.filter(({ type }) => type !== 'client')?.length) &&
                    estabsUser.filter(({ type }) => type !== 'client')?.length > 1 && (
                        <>
                            <div className="divider z-50" style={{ height: 0, margin: '5px 0 7px 0' }}></div>
                            <div className={`dropdown`}>
                                <li tabIndex={0} className="pb-1">
                                    <a className="flex items-center">
                                        <i className="fa-solid fa-building text-lg"></i>
                                        {estabSession?.name}
                                        <i className="fa-solid fa-sort-down ml-1"></i>
                                    </a>
                                    <ul className="dropdown-content menu shadow-lg bg-base-100 rounded-box w-52 mt-10 z-50">
                                        {estabsUser
                                            .filter(({ type }) => type !== 'client')
                                            .map((e, i): any => (
                                                <li key={i}>
                                                    <a onClick={() => switchEstab(e?._id)}>{e?.name}</a>
                                                </li>
                                            ))}
                                    </ul>
                                </li>
                            </div>
                        </>
                    )}
            </ul>
        </div>
    );
}
