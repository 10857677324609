import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'animate.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from '@swat-sccs/react-simple-snackbar';
import { ParamsProvider } from './contexts/ParamsContext';
import { NotificationsProvider } from './notifications/NotificationsContext';
import { EstabsProvider } from './contexts/EstabsContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js', { type: 'module' })
        .then((registration) => {
            console.log('Service Worker registrado com sucesso:', registration);
        })
        .catch((error) => {
            console.error('Erro ao registrar o Service Worker:', error);
        });
}

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ParamsProvider>
                <SnackbarProvider>
                    <AuthProvider>
                        <AppProvider>
                            <EstabsProvider>
                                <NotificationsProvider>
                                    <App />
                                </NotificationsProvider>
                            </EstabsProvider>
                        </AppProvider>
                    </AuthProvider>
                </SnackbarProvider>
            </ParamsProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
