import { api } from '../../../../AxiosConfig';

export class DashboardService {
    getInfoHeader(competence: string) {
        return api.get(`dashboard/getInfoHeader/${competence}`);
    }

    getConclusoesCompetence(competence: string) {
        return api.get(`dashboard/getConclusoesCompetence/${competence}`);
    }

    getFaturamentoMes(competence: string) {
        return api.get(`dashboard/getFaturamentoMes/${competence}`);
    }

    getStatusAtual() {
        return api.get('dashboard/getStatusAtual');
    }

    getVisaoGeral(currentDate: string) {
        return api.get(`dashboard/getVisaoGeral/${currentDate}`);
    }
}
