import { useParamsContext } from '../../../contexts/ParamsContext';
import PlanosDelete from './Delete';
import PlanosForm from './Form';
import PlanosList from './List/List';

export default function Planos() {
    const { action } = useParamsContext();

    switch (action) {
        case 'edit':
        case 'add': {
            return <PlanosForm />;
        }
        case 'delete':
            return <PlanosDelete />;
        case 'list':
        default:
            return <PlanosList />;
    }
}
