import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface RouteParams {
    id: string;
    action: string;
    setParams: any;
    clear: any;
}

const ParamsContext = createContext<RouteParams | undefined>(undefined);

export const useParamsContext = () => {
    const context = useContext(ParamsContext);
    if (!context) {
        throw new Error('useParamsContext must be used within a ParamsProvider');
    }
    return context;
};

export const ParamsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [action, setAction] = useState<string>('');
    const [id, setId] = useState<string>('');
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();

    const setParams = ({ action: newAction, id: newId }: any) => {
        const newParams: any = {};

        if (newAction || action) {
            newParams.action = newAction || action;
        }

        if (newId || id) {
            newParams.id = newId || id;
        }

        setSearchParams(newParams);
    };

    const clear = () => {
        setSearchParams();
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        setAction(searchParams.get('action') || '');
        setId(searchParams.get('id') || '');
    }, [location.search]);

    return <ParamsContext.Provider value={{ action, id, setParams, clear }}>{children}</ParamsContext.Provider>;
};
