import { api } from '../../../AxiosConfig';

class AgendaClienteService {
    createAgendamento(agendamentoData: {
        estabId: string;
        startAt: Date;
        servicoId: string;
        attendantId: string;
        startMinutes: number;
    }) {
        return api.post(`/horarios/cliente/agendar`, agendamentoData);
    }

    getHorarios(estabId: string, data: Date, servicoId: string, atendenteId: string) {
        return api.get(`/horarios`, {
            params: {
                estabId,
                data,
                servicoId,
                atendenteId,
            },
        });
    }

    cancel(horarioId: string) {
        return api.post(`/horarios/cliente/cancel`, { horarioId });
    }

    confirm(horarioId: string) {
        return api.post(`/horarios/cliente/confirm`, { horarioId });
    }

    getAgendamentosCards() {
        return api.get(`/horarios/cliente/cards`);
    }

    getAgendamentoById(id: string) {
        return api.get(`/horarios/agendamentos/${id}`);
    }
}

export { AgendaClienteService };
