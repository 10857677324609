import React from 'react';
import { useLandingPageContext } from '../Context';

export const About = () => {
    const { estab } = useLandingPageContext();

    return (
        <section id="about" className="py-16">
            <div className="container mx-auto px-4">
                <div className="flex items-center mb-8">
                    <hr className="flex-grow border-t border-gray-700" />
                    <h2 className="text-3xl font-bold text-center mx-4 text-white text-shadow">Sobre nós</h2>
                    <hr className="flex-grow border-t border-gray-700" />
                </div>
                <p className="text-center text-gray-300">{estab?.about}</p>
            </div>
        </section>
    );
};
