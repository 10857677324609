import { useEffect, useState } from 'react';
import { useEstabsContext } from '../../../contexts/EstabsContext';
import { CardEstab } from '../componentes/CardEstab';
import { EstabelecimentosService } from '../system-admin/Service';
const estabsService = new EstabelecimentosService();

export const EstabsClientAddFirst = () => {
    const [add, setAdd] = useState(false);
    const { estabsUser } = useEstabsContext();

    return add ? (
        <div className="flex flex-wrap gap-4">
            {estabsUser.map((e, i) => (
                <div key={i} className="w-80">
                    <CardEstab estabId={e._id} />
                </div>
            ))}
        </div>
    ) : (
        <div>
            <div className="text-center text-4xl  text-gray-700 mb-6">
                Parece que você ainda não vinculou nenhum estabelecimento ao seu perfil.
            </div>

            <div className="flex flex-col items-center">
                <button
                    onClick={() => setAdd(true)}
                    className="btn btn-secondary btn-lg bottom-6 shadow-2xl hover:bg-blue-600 transform hover:scale-105 transition-all duration-300"
                >
                    Adicionar
                </button>
            </div>
        </div>
    );
};

export const UserClientEstabAdd = () => {
    const [loading, setLoading] = useState(false);
    const [estabs, setEstabs] = useState<any[]>([]);

    const loadEstabs = async () => {
        try {
            setLoading(true);
            const { data } = await estabsService.findAll();
            setEstabs(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadEstabs();
    }, []);

    return (
        <div className="flex flex-wrap gap-4">
            {loading && (
                <div className="flex flex-wrap gap-4">
                    <div className="flex w-52 flex-col gap-4">
                        <div className="skeleton h-32 w-full"></div>
                        <div className="skeleton h-4 w-28"></div>
                        <div className="skeleton h-4 w-full"></div>
                        <div className="skeleton h-4 w-full"></div>
                    </div>

                    <div className="flex w-52 flex-col gap-4">
                        <div className="skeleton h-32 w-full"></div>
                        <div className="skeleton h-4 w-28"></div>
                        <div className="skeleton h-4 w-full"></div>
                        <div className="skeleton h-4 w-full"></div>
                    </div>
                </div>
            )}

            {estabs.map((e, i) => (
                <div key={i} className="w-80">
                    <CardEstab estabId={e._id} />
                </div>
            ))}
        </div>
    );
};
