import { useState } from 'react';
import { PlanosService } from '../Service';
import Dropdown from '../../../../components/Dropdown';
import { useAppContext } from '../../../../contexts/AppContext';
import { usePlanosContext } from '../Context';

const service = new PlanosService();

interface StatusProps {
    id: string;
    status: number;
}

export default function EstabelecimentosListStatus({ id, status }: StatusProps) {
    const { errorSnackbar } = useAppContext();
    const [loading, setLoading] = useState(false);
    const { reloadData } = usePlanosContext();

    const update = async (status: number) => {
        try {
            setLoading(true);
            await service.update(id, { status });
            reloadData();
        } catch (e) {
            console.log(e);

            errorSnackbar(`Ocorreu um erro ao ${status === 1 ? 'ativar' : 'desativar'} o estabelecimento.`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dropdown
            label={status === 1 ? 'Ativo' : 'Desativado'}
            isDisabled={loading}
            buttonClass={status === 2 ? 'bg-red-600 text-white' : 'bg-green-600 text-white'}
            actions={[
                status === 2
                    ? {
                          label: 'Ativar',
                          action: async () => {
                              update(1);
                          },
                      }
                    : {
                          label: 'Desativar',
                          action: async () => {
                              update(2);
                          },
                      },
            ]}
        />
    );
}
