import { onAuthStateChanged, signOut, getIdTokenResult } from 'firebase/auth';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { auth } from '../firebase';
import { UserService } from '../modules/configuracoes/usuario/Service';

const userService = new UserService();

interface User {
    uid: string;
    email: string | null;
    displayName: string | null;
    phoneNumber: string | null;
    photoURL: string | null;
    estabIdSession: string | null;
    client: boolean;
    root: boolean;
    admin: boolean;
    worker: boolean;
}

interface AuthContextProps {
    logged: boolean;
    loadingAuth: boolean;
    logout: () => void;
    user: User | null;
    changeUser: any;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [logged, setLogged] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setLogged(true);
                try {
                    const { claims } = await getIdTokenResult(user, true);

                    const estabs = await userService.getEstabsUser().then(async (response) => response?.data);

                    const estab = estabs?.find((e: any) => e._id === claims?.estabIdSession);

                    setUser({
                        uid: user.uid,
                        email: user.email,
                        displayName: user.displayName,
                        phoneNumber: user.phoneNumber,
                        photoURL: user.photoURL,
                        estabIdSession: (claims?.estabIdSession || '') as string,
                        worker: estab?.type === 'worker',
                        admin: estab?.type === 'admin',
                        root: !!claims?.root,
                        client: estabs?.some(({ type }: any) => type === 'client') || !estabs?.length,
                    });
                } catch (error) {
                    console.error('Erro ao obter custom claims:', error);
                }
            } else {
                setLogged(false);
                setUser(null);
            }
            setLoadingAuth(false);
        });

        return () => unsubscribe();
    }, []);

    const changeUser = async (update: any) => {
        if (user) {
            if (update.displayName) {
                user.displayName = update.displayName;
            }

            if (update.email) {
                user.email = update.email;
            }

            if (update.photoURL) {
                user.photoURL = update.photoURL;
            } else if (update.photoURL === '') {
                user.photoURL = null;
            }

            if (update.phoneNumber) {
                user.phoneNumber = update.phoneNumber;
            } else if (update.phoneNumber === '') {
                user.phoneNumber = null;
            }

            setUser(user);
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error('Erro ao fazer logout:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ changeUser, logged, loadingAuth, logout, user }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuthContext };
