import { api } from '../AxiosConfig';

enum HistoryAction {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    VIEW = 'VIEW',

    CANCEL = 'CANCEL',
    CONFIRM = 'CONFIRM',
    CONCLUDE = 'CONCLUDE',
    APPROVE = 'APPROVE',
}

enum HistoryResource {
    AGENDAMENTO = 'AGENDAMENTO',
    SERVICO = 'SERVICO',
    USER = 'USER',
}

export interface HistoryToView {
    _id: string;
    message: string;
    createdAt: Date;
    user: {
        uid: string;
        name: string;
        email: string;
        phoneNumber?: string;
    };
    changes: string;
}

export class HistoryService {
    find(data: { resource?: string; resourceId?: string }) {
        return api.post('/history/find', data);
    }

    parseHistory(history: any): HistoryToView {
        let resourceName: string;
        let actionMessage: string;

        switch (history.resource) {
            case HistoryResource.AGENDAMENTO:
                resourceName = 'o agendamento';
                break;
            case HistoryResource.SERVICO:
                resourceName = 'o serviço';
                break;
            case HistoryResource.USER:
                resourceName = 'o usuário';
                break;
            default:
                resourceName = 'o recurso';
        }

        switch (history.action) {
            case HistoryAction.CREATE:
                actionMessage = `Criou ${resourceName}`;
                break;
            case HistoryAction.UPDATE:
                actionMessage = `Atualizou ${resourceName}`;
                break;
            case HistoryAction.CONFIRM:
                actionMessage = `Confirmou ${resourceName}`;
                break;
            case HistoryAction.CONCLUDE:
                actionMessage = `Concluiu ${resourceName}`;
                break;
            case HistoryAction.APPROVE:
                actionMessage = `Aprovou ${resourceName}`;
                break;
            case HistoryAction.CANCEL:
                actionMessage = `Cancelou ${resourceName}`;
                break;
            case HistoryAction.DELETE:
                actionMessage = `Removeu ${resourceName}`;
                break;
            case HistoryAction.VIEW:
                actionMessage = `Visualizou ${resourceName}`;
                break;
            default:
                actionMessage = `Realizou uma ação em ${resourceName}`;
        }

        history.message = actionMessage

        return history
    }
}
