import moment from 'moment';
import { useFormHorarioClienteContext } from './Context';
import { convertMinutesToHHMM } from '../../../../components/utils';
import { useEffect } from 'react';

export const AgendaFormHeader = () => {
    const { estab, setEstab, servico, setServico, data, setData, setAtendente, horario, setHorario, loading } =
        useFormHorarioClienteContext();

    const getHeaderMessage = () => {
        let message = '';
        if (!estab?._id) {
            message = 'Vamos começar! Selecione um Estabelecimento para dar o primeiro passo.';
        } else if (!servico?._id) {
            message = 'Escolha um Serviço que você deseja.';
        } else if (!horario) {
            message = 'Quase lá! Selecione o Dia, um Atendente e o horário que você gostaria de agendar.';
        } else {
            message = 'Tudo pronto! Basta confirmar seu agendamento e aproveitar o serviço! 😊';
        }
        return (
            <div key={message} className="text-lg mb-5 text-center animate__animated animate__fadeIn animate__fast">
                {message}
            </div>
        );
    };

    useEffect(() => {
        getHeaderMessage();
    }, [estab, servico, horario, data]);

    return (
        <div>
            <div className="flex justify-center breadcrumbs">
                <ul className="flex space-x-2">
                    {estab?._id ? (
                        <li className="animate__animated animate__fadeIn animate__fast">
                            <button
                                disabled={loading}
                                className="btn btn-sm p-0 hover:animate__animated hover:animate__bounce"
                                onClick={() => {
                                    setEstab({});
                                    setServico({});
                                    setData({});
                                    setAtendente({});
                                    setHorario(0);
                                }}
                            >
                                {estab?.name}
                            </button>
                        </li>
                    ) : (
                        <li className="animate__animated animate__fadeIn animate__fast">
                            <button className="btn btn-sm btn-ghost p-0 hover:animate__animated hover:animate__bounce">
                                Estabelecimento
                            </button>
                        </li>
                    )}

                    {servico?._id ? (
                        <li className="animate__animated animate__fadeIn animate__fast">
                            <button
                                disabled={loading}
                                className="btn btn-sm p-0 hover:animate__animated hover:animate__bounce"
                                onClick={() => {
                                    setServico({});
                                    setData({});
                                    setAtendente({});
                                    setHorario(0);
                                }}
                            >
                                {servico?.name}
                            </button>
                        </li>
                    ) : (
                        estab?._id && (
                            <li className="animate__animated animate__fadeIn animate__fast">
                                <button className="btn btn-sm btn-ghost p-0 hover:animate__animated hover:animate__bounce">
                                    Serviço
                                </button>
                            </li>
                        )
                    )}

                    {!!estab?._id && !!servico?._id && !!horario && !!data ? (
                        <li className="animate__animated animate__fadeIn animate__fast">
                            <button
                                disabled={loading}
                                className="btn btn-sm p-0 hover:animate__animated hover:animate__bounce"
                                onClick={() => {
                                    setData(new Date());
                                    setAtendente({});
                                    setHorario(0);
                                }}
                            >
                                {moment(data).format('dddd, DD [de] MMMM [de] YYYY')} às {convertMinutesToHHMM(horario)}
                            </button>
                        </li>
                    ) : (
                        servico?._id && (
                            <li className="animate__animated animate__fadeIn animate__fast">
                                <button className="btn btn-sm btn-ghost p-0 hover:animate__animated hover:animate__bounce hover:none">
                                    Horário
                                </button>
                            </li>
                        )
                    )}
                </ul>
            </div>
            {getHeaderMessage()}
            <div className="divider mx-2"></div>
        </div>
    );
};
