import React from "react";

export const TermosUso = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-base-100 text-base-content">
            <header className="text-center mb-8">
                <h1 className="text-4xl font-bold text-black">Termos de Uso</h1>
            </header>
            <section className="space-y-6">
                <p>
                    Bem-vindo ao <span className="font-bold">SalonFlow</span>! Ao usar nosso aplicativo, você concorda com os Termos de Uso descritos abaixo. Certifique-se de lê-los atentamente antes de utilizar nossos serviços.
                </p>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">1. Aceitação dos Termos</div>
                    <div className="collapse-content">
                        <p>
                            Ao acessar ou usar o <span className="font-bold">SalonFlow</span>, você concorda em cumprir e estar sujeito a estes Termos de Uso. Se você não concordar com alguma parte dos termos, não use o aplicativo.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">2. Uso do Serviço</div>
                    <div className="collapse-content">
                        <ul className="list-disc ml-6">
                            <li>O uso do aplicativo é permitido apenas para fins lícitos.</li>
                            <li>É proibido realizar atividades que comprometam a segurança do sistema.</li>
                            <li>Os usuários devem fornecer informações precisas e completas durante o cadastro.</li>
                        </ul>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">3. Propriedade Intelectual</div>
                    <div className="collapse-content">
                        <p>
                            Todo o conteúdo do <span className="font-bold">SalonFlow</span>, incluindo design, logotipos, textos e gráficos, é de propriedade exclusiva da empresa e protegido por leis de direitos autorais.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">4. Responsabilidades dos Usuários</div>
                    <div className="collapse-content">
                        <ul className="list-disc ml-6">
                            <li>Manter a confidencialidade das credenciais de acesso.</li>
                            <li>Não compartilhar informações falsas ou enganosas.</li>
                            <li>Não usar o aplicativo para fins ilícitos ou prejudiciais.</li>
                        </ul>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">5. Limitação de Responsabilidade</div>
                    <div className="collapse-content">
                        <p>
                            O <span className="font-bold">SalonFlow</span> não se responsabiliza por danos diretos, indiretos ou incidentais resultantes do uso ou da incapacidade de usar o aplicativo.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">6. Rescisão</div>
                    <div className="collapse-content">
                        <p>
                            Podemos suspender ou encerrar o acesso ao aplicativo a qualquer momento, sem aviso prévio, se considerarmos que você violou estes Termos de Uso.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">7. Alterações nos Termos</div>
                    <div className="collapse-content">
                        <p>
                            Reservamo-nos o direito de atualizar ou modificar estes Termos de Uso a qualquer momento. Quaisquer alterações serão notificadas por meio do aplicativo ou por outros canais apropriados.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">8. Contato</div>
                    <div className="collapse-content">
                        <p>
                            Se você tiver dúvidas ou preocupações sobre estes Termos de Uso, entre em contato pelo e-mail:{" "}
                            <a href="mailto:seuemail@salonflow.com" className="text-primary hover:underline">
                                seuemail@salonflow.com
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};
