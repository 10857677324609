import { useState, useEffect } from 'react';
import { urlFile } from '../../AxiosConfig';
import { Loading } from '../Loading';
import { FileModal } from './FileModal';

interface CarouselImgsProps {
    imagesIds: string[];
    autoPlay?: boolean;
}

const CarouselImgs = ({ imagesIds, autoPlay = false }: CarouselImgsProps) => {
    const [open, setOpen] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [transition, setTransition] = useState<String>('');

    const images = imagesIds?.map((imageId) => ({
        id: imageId,
        url: `${urlFile}/${imageId}`,
        alt: 'Imagem',
    }));

    const handlePrevClick = () => {
        const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;

        if (newIndex !== currentIndex) {
            setTransition('animate__fadeOutLeft');
            setTimeout(() => {
                setCurrentIndex(newIndex);
                setTransition('animate__fadeInRight');
            }, 500);
        }
    };

    const handleNextClick = () => {
        const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
        if (newIndex !== currentIndex) {
            setTransition('animate__fadeOutRight');

            setTimeout(() => {
                setCurrentIndex(newIndex);
                setTransition('animate__fadeInLeft');
            }, 500);
        }
    };

    useEffect(() => {
        if (autoPlay) {
            const interval = setInterval(() => {
                handlePrevClick();
            }, 3500);

            return () => clearInterval(interval);
        }
    }, [currentIndex, autoPlay]);

    const handleImageLoad = () => {
        setLoading(false);
    };

    if (!images?.length) return <></>;

    return (
        <div className="flex items-center justify-center h-full overflow-hidden w-full">
            <div className="relative w-full h-full">
                {loading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                        <Loading />
                    </div>
                )}
                <img
                    key={images[currentIndex].id}
                    src={images[currentIndex].url}
                    alt={images[currentIndex].alt}
                    onClick={() => setOpen(images[currentIndex].id)}
                    className={`w-full object-center object-cover animate__animated ${transition} ${
                        loading ? 'opacity-0' : 'opacity-100'
                    }`}
                    style={{ height: 'inherit' }}
                    onLoad={handleImageLoad}
                    onError={handleImageLoad}
                />
                {!autoPlay && (
                    <div className="absolute flex justify-between items-center transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <button onClick={handlePrevClick} className="btn btn-circle shadow-2xl">
                            ❮
                        </button>
                        <button onClick={handleNextClick} className="btn btn-circle">
                            ❯
                        </button>
                    </div>
                )}
            </div>

            {!!open && <FileModal key={open} fileId={open} onClose={() => setOpen('')} />}
        </div>
    );
};

export default CarouselImgs;
