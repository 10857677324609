export const getInitialName = (name = '') => {
    const nameArray = name.trim().split(' ');
    const initials =
        nameArray.length > 1
            ? nameArray[0][0].toUpperCase() + nameArray[nameArray.length - 1][0].toUpperCase()
            : nameArray[0][0].toUpperCase();
    return initials;
};

export const formatToReal = (valor: number, casasDecimais: number = 2): string => {
    return valor
        .toFixed(casasDecimais)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const convertMinutesToHHMM = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = mins.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
};
