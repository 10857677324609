import React, { createContext, useContext, useState } from 'react';
import { UserService } from '../configuracoes/usuario/Service';

const userService = new UserService();

interface Params {
    data: any[];
    loading: boolean;
    loadClients: () => void;
}

const Context = createContext<Params | undefined>(undefined);

export const useClientsContext = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useParamsContext must be used within a ParamsProvider');
    }
    return context;
};

export const ClientsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const loadClients = async () => {
        try {
            setLoading(true);
            const { data } = await userService.getClients();

            setData(data);
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    return <Context.Provider value={{ data, loadClients, loading }}>{children}</Context.Provider>;
};
