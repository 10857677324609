import AvatarUser from '../../../components/AvatarUser';
import { useAgendaContext } from './ContextAgenda';
import { StatusFilter } from './StatusFilter';

export const ScheduleFilter = () => {
    const { atendentes, setFilter, filter } = useAgendaContext();
    return (
        <div className="card bg-white">
            <div className="card-body">
                Filtro Agenda
                <div className="divider" style={{ padding: 0, margin: 0 }}></div>
                <StatusFilter />
                <label className="mt-5">Atendentes</label>
                <div className="flex gap-1">
                    {atendentes.length &&
                        atendentes.map((atendente: any, index: any) => {
                            return (
                                <button
                                    key={index}
                                    className={`btn btn-circle ${
                                        filter?.atendentesIds?.includes(atendente.uid) ? 'btn-info' : 'btn-ghost'
                                    }`}
                                    style={{ display: 'grid' }}
                                    onClick={() => {
                                        const doc = {
                                            ...filter,
                                            atendentesIds: filter?.atendentesIds?.includes(atendente.uid)
                                                ? filter.atendentesIds.filter((id: any) => id !== atendente.uid)
                                                : [...(filter.atendentesIds || []), atendente.uid],
                                        };

                                        setFilter(doc);
                                    }}
                                >
                                    <AvatarUser
                                        photoURL={atendente.photoURL}
                                        name={atendente.displayName || atendente.email}
                                    />
                                </button>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
