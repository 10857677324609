import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
    children: ReactNode;
    onClose: () => void;
    transparent?: boolean;
}

export const Modal = ({ children, onClose, transparent = true }: ModalProps) => {
    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return createPortal(
        <div
            className={` ${transparent ? 'backdrop-blur-sm bg-transparent' : 'bg-white bg-opacity-5'}`}
            onClick={handleClickOutside}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: transparent ? 'rgba(0, 0, 0, 0.5)' : '',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                padding: '20px 20px',
            }}
        >
            <div className="p-3 relative flex rounded-lg">
                <button
                    className="btn btn-xs btn-circle btn-neutral hover:opacity-80 absolute right-1 top-1 z-50"
                    onClick={onClose}
                >
                    ✕
                </button>
                <div
                    style={{
                        maxWidth: '100vw', // Limita o tamanho máximo do modal na largura
                        maxHeight: '95vh', // Limita o tamanho máximo do modal na altura
                        overflowY: 'auto', // Adiciona scroll vertical caso o conteúdo ultrapasse a altura do modal
                    }}
                >
                    {children}
                </div>
            </div>
        </div>,
        document.getElementById('outside-root') as HTMLElement
    );
};
