import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FileService } from './service';
import { FileModal } from './FileModal';
import { convertFileToBase64 } from './functions';
const fileService = new FileService();

interface FileUploadProps {
    value: string[];
    setValue: (value: string[]) => void;
    appId?: string;
    disabled?: boolean;
    multiple?: boolean;
    children?: (params: {
        fileData: any[];
        handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
        removeFile: (id: string) => void;
        inputRef: React.RefObject<HTMLInputElement>;
        loading: boolean;
    }) => React.ReactNode;
}

export const FileUpload: React.FC<FileUploadProps> = ({
    value,
    setValue,
    children,
    appId,
    disabled = false,
    multiple = true,
}) => {
    const [loading, setLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [fileData, setFileData] = useState<any[]>([]);

    useEffect(() => {
        const loadPreview = async () => {
            if (value) {
                try {
                    setLoading(true);

                    await Promise.all(value.map((fileId) => fileService.findOne(fileId))).then((files) => {
                        setFileData(
                            files
                                .filter((file) => !!file?.data?._id)
                                .map((file) => ({ id: file.data._id, ...file.data }))
                        );
                    });
                } catch (error) {
                } finally {
                    setLoading(false);
                }
            }
        };

        loadPreview();
    }, []);

    const removeFile = (id: string) => {
        setFileData((prevData) => prevData.filter((file) => file.id !== id));
        setValue(fileData.filter((file) => file.id !== id).map((file: any) => file._id));
    };

    const handleChange = async (files: any) => {
        try {
            setLoading(true);

            const selectedFiles = files;

            const newFileDataPromises = selectedFiles.map(async (file: any) => {
                const base64Content = await convertFileToBase64(file);

                return {
                    id: file.name + '-' + file.size + '-' + file.lastModified,
                    file,
                    fileContent: base64Content,
                    uploading: true,
                };
            });

            const newFileData = await Promise.all(newFileDataPromises);

            if (multiple) {
                setFileData((prevFiles) => [...prevFiles, ...newFileData]);
            } else {
                setFileData(newFileData);
            }

            await Promise.all(newFileData.map((file) => handleUpload(file))).then((result) => {
                if (multiple) {
                    setValue([...value, ...result]);
                } else {
                    setValue(result);
                }
            });
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = async (file: any) => {
        try {
            const result = await fileService.upload(
                file.fileContent,
                file.file.name,
                file.file.size,
                file.file.type,
                file.file.lastModifiedDate,
                appId
            );

            setFileData((prevData) =>
                prevData.map((f) =>
                    f.id === file.id ? { ...f, ...result.data, id: result.data._id, uploading: false } : f
                )
            );

            return result.data._id;
        } catch (error) {
            console.error('Upload failed', error);
            removeFile(file.id);
        }
    };

    return (
        <div className="flex gap-2">
            {children &&
                children({
                    fileData,
                    handleChange,
                    removeFile,
                    inputRef,
                    loading,
                })}
        </div>
    );
};

export const PreviewFile = ({ fileData, onRemove }: { fileData: any; onRemove: (id: string) => void }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <>
            <div
                style={{ marginRight: '5px' }}
                className="avatar"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {fileData.uploading ? (
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                            width: '5rem',
                            height: '5rem',
                            backgroundColor: '#1a1a1a',
                        }}
                        className="rounded flex items-center justify-center"
                    >
                        <img
                            src={fileData.fileContent}
                            alt="file preview"
                            className="absolute inset-0 w-full h-full object-cover opacity-50"
                        />
                        <span
                            className="loading loading-spinner loading-sm absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                            style={{ color: '#ffffff' }}
                        ></span>
                    </div>
                ) : (
                    <div className="relative w-20 rounded">
                        <img src={fileData.url} alt="file preview" className="w-full h-full object-cover" />
                        {isHovered && (
                            <div
                                onClick={(e) => e.currentTarget === e.target && openModal()}
                                className="absolute top-0 left-0 w-full h-full rounded bg-black bg-opacity-70 flex flex-col justify-center items-center space-y-4 transition-all duration-300 ease-in-out"
                            >
                                <button
                                    onClick={openModal}
                                    className="btn btn-circle btn-xs btn-active hover:opacity-65"
                                >
                                    <i className="fa-solid fa-eye"></i>
                                </button>

                                <button
                                    onClick={() => onRemove(fileData.id)}
                                    className="btn btn-xs btn-circle btn-error hover:opacity-85"
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isModalOpen && <FileModal fileId={fileData.id} onClose={closeModal} />}
        </>
    );
};
