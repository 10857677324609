import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { LandingPageService } from './service';

const service = new LandingPageService();

interface LandingPageContextProps {
    loading: boolean;
    estab: any;
}

const LandingPageContext = createContext<LandingPageContextProps | undefined>(undefined);

export const useLandingPageContext = () => {
    const context = useContext(LandingPageContext);
    if (!context) {
        throw new Error('useLandingPageContext must be used within a LandingPageProvider');
    }
    return context;
};

export const LandingPageProvider = ({
    children,
    brandingEstabId,
    pageName,
}: {
    children: ReactNode;
    brandingEstabId?: string;
    pageName?: string;
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [estab, setEstab] = useState<any>(null);

    const LoadEstab = async () => {
        try {
            setLoading(true);

            const {data} = await service.getData({
                estabPageName: pageName,
                estabId: brandingEstabId,
            });

            setEstab(data);
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        LoadEstab();
    }, [brandingEstabId, pageName]);

    return <LandingPageContext.Provider value={{ loading, estab }}>{children}</LandingPageContext.Provider>;
};
