import moment from 'moment';
import 'moment/locale/pt-br';
import { useAgendaContext } from './ContextAgenda';
import ScheduleItem from './ScheduleItem';
import ScheduleView from './view/ScheduleView';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { Modal } from '../../../components/Modal';
import { ScheduleFilter } from './Filter';
import { Agendamento } from '../Enum';

moment.locale('pt-br');

export default function ScheduleList() {
    const { date, handlePreviousDay, handleNextDay, schedules, selectedSchedule, loading, setDate } =
        useAgendaContext();
    const [openFilter, setOpenFilter] = useState(false);

    const endTime = moment(date).startOf('day').hour(23);
    const startTime = moment(date).startOf('day').hour(3);

    const startOfWeek = moment(date).startOf('week');
    const endOfWeek = moment(date).endOf('week');
    const days = [];

    for (let day = startOfWeek; day <= endOfWeek; day.add(1, 'days')) {
        days.push(day.clone());
    }

    const renderTimeSlots = () => {
        const timeSlots = [];

        while (startTime <= endTime) {
            const slotTasks = schedules
                ? schedules.filter((task: Agendamento) => {
                      const taskStart = moment(task.startAt);

                      return (
                          taskStart.isSameOrAfter(startTime) && taskStart.isBefore(moment(startTime).add(30, 'minutes'))
                      );
                  })
                : [];

            if (slotTasks.length > 0) {
                timeSlots.push(
                    <div key={startTime.format('HH:mm')}>
                        <div className={`h-12 flex items-center`}>{startTime.format('HH:mm')}</div>
                        <div>
                            {slotTasks.map((schedule: any, i: any) => {
                                return <ScheduleItem key={i} schedule={schedule} />;
                            })}
                        </div>
                    </div>
                );
            }

            startTime.add(30, 'minutes');
        }

        return timeSlots;
    };

    return (
        <div className="container my-8">
            <div className="flex justify-center">
                <DatePicker
                    selected={moment(date).toDate()}
                    onChange={setDate}
                    locale="pt-BR"
                    customInput={
                        <button className="btn btn-ghost btn-sm">
                            <div className="text-sm font-bold">{moment(date).format('DD [de] MMMM [de] YYYY')}</div>
                        </button>
                    }
                />
            </div>

            <div className="flex items-center justify-center mt-4 gap-2">
                <div className="hidden md:block flex items-center justify-center">
                    <button className="btn btn-sm mr-2" onClick={() => setDate(new Date())}>
                        Hoje
                    </button>
                    <button className="btn btn-circle btn-sm" onClick={handlePreviousDay}>
                        <i className="fa-solid fa-angle-left"></i>
                    </button>

                    <button className="btn btn-circle btn-sm" onClick={handleNextDay}>
                        <i className="fa-solid fa-angle-right"></i>
                    </button>
                </div>
                <div className="flex items-center gap-1">
                    {days.map((day, index) => {
                        const isSelected = day.isSame(date, 'day');

                        return (
                            <button
                                key={index}
                                className={`btn btn-circle ${isSelected && 'btn-info'}`}
                                onClick={() => {
                                    setDate(day);
                                }}
                            >
                                <div>
                                    <div className="text-xs md:text-md">{day.format('ddd')}</div>
                                    <div className="font-light text-xs md:text-md">{day.format('DD')}</div>
                                </div>
                            </button>
                        );
                    })}
                    {openFilter && (
                        <Modal onClose={() => setOpenFilter(false)}>
                            <ScheduleFilter />
                        </Modal>
                    )}
                </div>
            </div>

            <div className="md:hidden flex items-center justify-center mt-4 gap-2">
                <button className="btn btn-circle btn-sm md:btn-md" onClick={handlePreviousDay}>
                    <i className="fa-solid fa-angle-left"></i>
                </button>

                <button className="btn btn-sm md:btn-md" onClick={() => setDate(new Date())}>
                    Hoje
                </button>

                <button className="btn btn-circle btn-sm md:btn-md" onClick={handleNextDay}>
                    <i className="fa-solid fa-angle-right"></i>
                </button>
            </div>

            <div className="divider"></div>

            {loading ? (
                <div className="w-full h-full items-center justify-center flex">
                    <div className="text-center">
                        <span className="loading loading-bars loading-lg"></span>
                    </div>
                </div>
            ) : (
                <div className="flex gap-4">
                    <div className={`${!!selectedSchedule ? 'hidden xl:block xl:w-4/12' : 'w-full'}`}>
                        {renderTimeSlots()}
                    </div>

                    {!!selectedSchedule && (
                        <div className="w-full xl:w-8/12">
                            <ScheduleView />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
