import { useEffect, useState } from 'react';
import moment from 'moment';
import { AtendentesService } from '../../../atendentes/Service';
import { useFormHorarioClienteContext } from './Context';
import { DurationService } from '../../../servicos/list/Duration';
import { Loading } from '../../../../components/Loading';
import { baseURL } from '../../../../AxiosConfig';
import { AgendaClienteService } from '../Service';

const serviceAtendentes = new AtendentesService();
const horarioService = new AgendaClienteService();

export const SelectHorario = () => {
    const [loading, setLoading] = useState({
        atendentes: false,
        horarios: false,
    });
    const [atendentes, setAtendentes] = useState([] as any);
    const [horarios, setHorarios] = useState([] as any);

    const {
        date,
        data,
        handlePreviousDay,
        handleNextDay,
        setDate,
        estab,
        servico,
        horario,
        setData,
        atendente,
        setAtendente,
        setHorario,
        loading: loadingForm,
    } = useFormHorarioClienteContext();
    const startOfWeek = moment(date).startOf('week');
    const endOfWeek = moment(date).endOf('week');
    const days = [];

    for (let day = startOfWeek; day <= endOfWeek; day.add(1, 'days')) {
        days.push(day.clone());
    }

    useEffect(() => {
        setData(date.toDate());
        setHorario(0);
    }, [date]);

    const loadAtendentes = async () => {
        try {
            setLoading({ ...loading, atendentes: true });

            if (estab._id) {
                const { data } = await serviceAtendentes.getByEstabId(estab._id);
                setAtendentes(data);
            }
        } catch (err) {
        } finally {
            setLoading({ ...loading, atendentes: false });
        }
    };

    const loadHorarios = async () => {
        try {
            setLoading({ ...loading, horarios: true });

            if (estab?._id && atendente?.uid && servico?._id) {
                const { data } = await horarioService.getHorarios(
                    estab?._id,
                    date.toDate(),
                    servico?._id,
                    atendente?.uid
                );

                setHorarios(data);
            } else {
                setHorarios([]);
            }
        } catch (err) {
        } finally {
            setLoading({ ...loading, horarios: false });
        }
    };

    useEffect(() => {
        loadAtendentes();
    }, [estab]);

    useEffect(() => {
        loadHorarios();
    }, [estab, atendente, servico, data]);

    const canGoToPreviousDay = () => {
        const previousDay = moment(date).subtract(1, 'day');
        const isPastDay = previousDay.isBefore(moment(), 'day');
        return !isPastDay; // Retorna `true` se o dia anterior não for no passado
    };

    const canGoToNextDay = () => {
        const daysOpen = estab?.agenda?.daysOpen || 0;
        const hojeInicio = moment().startOf('day');
        const limiteData = hojeInicio.add(daysOpen, 'days');
        const nextDay = moment(date).add(1, 'day');
        const isBeyondDaysOpen = nextDay.isAfter(limiteData, 'day');
        return !isBeyondDaysOpen; // Retorna `true` se o próximo dia não ultrapassar o limite
    };


    return (
        <div>
            {estab?._id && servico?._id && (
                <div className="grid justify-center gap-4 animate__animated animate__fadeInLeft animate__faster">
                    <div className={"text-center text-xs"}>
                        {moment(data).format('dddd, DD [de] MMMM [de] YYYY')}
                    </div>

                    <div className="flex items-center gap-1">
                        <button
                            disabled={loadingForm || !canGoToPreviousDay()}
                            className="btn btn-circle mr-2"
                            onClick={handlePreviousDay}
                        >
                            <i className="fa-solid fa-angle-left"></i>
                        </button>

                        {days.map((day, index) => {
                            const daysOpen = estab?.agenda?.daysOpen || 0;
                            const isSelected = day.isSame(date, 'day');
                            const isPastDay = day.isBefore(moment(), 'day');

                            const hojeInicio = moment().startOf('day');
                            const limiteData = hojeInicio.add(daysOpen, 'days');
                            const isBeyondDaysOpen = day.isAfter(limiteData, 'day');

                            return (
                                <div
                                    key={index}
                                    onClick={() => !loadingForm && !isPastDay && !isBeyondDaysOpen && setDate(day)}
                                    style={{padding: '1px'}}
                                    className={`cursor-pointer rounded-xl shadow-md text-center w-12 transition-all duration-200 ${
                                        loadingForm || isPastDay || isBeyondDaysOpen
                                            ? 'cursor-not-allowed opacity-35'
                                            : 'hover:shadow-2xl'
                                    } ${
                                        isSelected && !(loadingForm || isPastDay || isBeyondDaysOpen)
                                            ? 'brightness-50 shadow-2xl'
                                            : ''
                                    }`}
                                >
                                    <div className="bg-secondary text-white text-xs font-bold rounded-t-xl py-1">
                                        {day.format('ddd').toUpperCase()}
                                    </div>

                                    <div className="text-gray-800 text-sm font-semibold py-1 rounded-b-xl">
                                        {day.format('DD')}
                                    </div>
                                </div>
                            );
                        })}

                        <button
                            disabled={loadingForm || !canGoToNextDay()}
                            className="btn btn-circle ml-2"
                            onClick={handleNextDay}
                        >
                            <i className="fa-solid fa-angle-right"></i>
                        </button>
                    </div>

                    <div className="flex items-center gap-8 justify-center">
                        {loading?.atendentes ? (
                            <>
                                <div className="skeleton h-20 w-20 shrink-0" style={{borderRadius: '100%'}}></div>
                                <div className="skeleton h-20 w-20 shrink-0" style={{borderRadius: '100%'}}></div>
                                <div className="skeleton h-20 w-20 shrink-0" style={{borderRadius: '100%'}}></div>
                            </>
                        ) : (
                            atendentes.map((a: any, index: any) => (
                                <div
                                    key={index}
                                    className="grid justify-items-center hover:cursor-pointer "
                                    onClick={() => setAtendente(a)}
                                >
                                    <div className="avatar">
                                        <div
                                            className={`${
                                                atendente?.uid === a?.uid ? 'ring ring-black shadow-2xl' : 'mask'
                                            } w-20 rounded-full mb-3`}
                                        >
                                            <img
                                                src={`${baseURL}/users/profileImage/${a?.uid}`}
                                                alt="Foto do atendente"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-sm">{a?.displayName}</div>
                                </div>
                            ))
                        )}
                    </div>

                    <div className="grid grid-cols-5 gap-2 mt-2 justify-center">
                        {loading?.horarios ? (
                            <div className="flex justify-center mx-auto w-full col-span-5">
                                <Loading/>
                            </div>
                        ) : (
                            horarios.map((h: any, idx: any) => (
                                <div key={idx} className="flex justify-center">
                                    {' '}
                                    <button
                                        disabled={loadingForm}
                                        onClick={() => setHorario(h)}
                                        className={`btn btn-sm ${h === horario ? 'btn-neutral' : 'btn-outline'}`}
                                    >
                                        <DurationService duration={h}/>
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
