import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParamsContext } from '../../../contexts/ParamsContext';
import { AusenciasProvider } from '../../ausencias/Context';
import { AtendentesService } from '../../atendentes/Service';
import { Agendamento } from '../Enum';
import { HorariosAtendenteService } from '../Service';
import WebSocketManager from '../../../WebSocketConfig';
import { useAuthContext } from '../../../contexts/AuthContext';

const service = new AtendentesService();
const horariosService = new HorariosAtendenteService();

interface AgendaParams {
    loading: boolean;
    date: moment.Moment;
    handlePreviousDay: () => void;
    handleNextDay: () => void;
    setDate: any;
    schedules: Agendamento[] | undefined;
    selectedSchedule: Agendamento | undefined;
    atendentes: any;
    setSelectedSchedule: any;
    filter: any;
    setFilter: any;
}

const AgendaContext = createContext<AgendaParams | undefined>(undefined);

export const useAgendaContext = () => {
    const context = useContext(AgendaContext);
    if (!context) {
        throw new Error('useAgendaContext must be used within an AgendaProvider');
    }
    return context;
};

export const AgendaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuthContext();
    const { action, id, clear } = useParamsContext();

    const [date, setDate] = useState(moment(sessionStorage.getItem('dateAgenda') || moment()));
    const [loading, setLoading] = useState(true);
    const [selectedSchedule, setSelectedSchedule] = useState<Agendamento | undefined>(undefined);

    const [atendentes, setAtendentes] = useState([]);
    const [filter, setFilter] = useState<any>({});

    const [schedules, setSchedules] = useState<Agendamento[] | undefined>(undefined);

    const handlePreviousDay = () => {
        if (!loading) setDate((prevDate) => moment(prevDate).subtract(1, 'days'));
    };

    const handleNextDay = () => {
        if (!loading) setDate((prevDate) => moment(prevDate).add(1, 'days'));
    };

    useEffect(() => {
        const webSocketManager = new WebSocketManager();

        setLoading(true);
        clear();

        const fetchSchedules = async (date: moment.Moment) => {
            try {
                const response = await horariosService.getHorariosCards(date.toDate());
                const filteredSchedules = response.data.filter((agendamento: Agendamento) => {
                    if (
                        filter?.atendentesIds?.length > 0 &&
                        !filter?.atendentesIds.includes(agendamento?.attendant?.uid)
                    ) {
                        return false;
                    }

                    const isSameDate =
                        moment(agendamento.startAt).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');

                    const isStatusMatch = !filter?.status || filter.status.includes(agendamento.status);

                    return isSameDate && isStatusMatch;
                });

                setSchedules(filteredSchedules);
            } catch (error) {
                console.error('Error fetching schedules:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSchedules(date);

        sessionStorage.setItem('dateAgenda', date.toString());

        if (!!user?.estabIdSession) {
            webSocketManager.onChannel(`horarios:atendentes:${user?.estabIdSession}`, (message) => {
                fetchSchedules(date);
            });
        }

        return () => {
            webSocketManager.disconnect();
        };
    }, [date, filter, user?.estabIdSession]);

    useEffect(() => {
        if (action === 'view' && !!id) {
            setSelectedSchedule(schedules?.find((e) => e._id === id));
        } else {
            setSelectedSchedule(undefined);
        }
    }, [action, id, schedules]);

    useEffect(() => {
        service
            .getAll()
            .then((response) => {
                setAtendentes(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });

        const lastFilter = localStorage.getItem('filterAgenda');

        if (lastFilter) {
            setFilter(JSON.parse(lastFilter));
        }
    }, []);

    return (
        <AgendaContext.Provider
            value={{
                loading,
                date,
                handlePreviousDay,
                handleNextDay,
                schedules,
                selectedSchedule,
                setDate,
                atendentes,
                setSelectedSchedule,
                filter,
                setFilter,
            }}
        >
            <AusenciasProvider>{children}</AusenciasProvider>
        </AgendaContext.Provider>
    );
};
