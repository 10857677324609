import React from 'react';
import { NumericFormat } from 'react-number-format';

interface PriceInputProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    label: string;
}

const PriceInput: React.FC<PriceInputProps> = ({ value, onChange, placeholder, label }) => {
    return (
        <label className="form-control">
            <div className="label">
                <span className="label-text">{label}</span>
            </div>
            <NumericFormat
                value={value}
                onValueChange={(values) => {
                    const { floatValue } = values;
                    onChange({
                        target: { value: floatValue ? floatValue : '' },
                    } as React.ChangeEvent<HTMLInputElement>);
                }}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                className="input input-bordered"
                placeholder={placeholder}
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
            />
        </label>
    );
};

export default PriceInput;
