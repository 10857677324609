import { api } from '../../AxiosConfig';

class HorariosAtendenteService {
    cancel(horarioId: string) {
        return api.post(`/horarios/atendente/cancel`, { horarioId });
    }

    approve(horarioId: string) {
        return api.post(`/horarios/atendente/approve`, { horarioId });
    }

    conclude(horarioId: string) {
        return api.post(`/horarios/atendente/conclude`, { horarioId });
    }

    getHorariosCards(date: Date) {
        return api.post(`/horarios/atendente/cards`, { date });
    }
}

export { HorariosAtendenteService };
