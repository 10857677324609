import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { AusenciasService } from './Service';
import { useEstabsContext } from '../../contexts/EstabsContext';
import { AtendentesProvider } from '../atendentes/Context';
import { Horarios, timeToMinutes } from '../agenda/Enum';

const serviceAusencias = new AusenciasService();

export interface Ausencias {
    id: string;
    start: Date;
    end: Date;
    atendentes: string[];
    motivo: string;
}

export const initialHorarios: Horarios = {
    segunda: [],
    terca: [],
    quarta: [],
    quinta: [],
    sexta: [],
    sabado: [],
    domingo: [],
};

interface AusenciasContextProps {
    loading: boolean;
    ausencias: Ausencias[];
    loadAusencias: () => void;
    horarios: Horarios;
    setHorarios: React.Dispatch<React.SetStateAction<Horarios>>;
    handleAddSlot: any;
    handleSlotChange: any;
    handleRemoveSlot: any;
}

const AusenciasContext = createContext<AusenciasContextProps | undefined>(undefined);

const AusenciasProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { estabSession } = useEstabsContext();
    const [loading, setLoading] = useState(true);
    const [ausencias, setAusencias] = useState<Ausencias[]>([]);
    const [horarios, setHorarios] = useState<Horarios>(initialHorarios);

    const loadAusencias = async () => {
        try {
            setLoading(true);

            await serviceAusencias.findAll().then((result) => {
                setAusencias(result?.data || []);
            });
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const handleAddSlot = (day: string) => {
        const newHorarios = { ...horarios };
        newHorarios[day].push({ start: 0, end: 0 });
        setHorarios(newHorarios);
    };

    const handleSlotChange = (day: string, slotIndex: number, field: 'start' | 'end', value: string) => {
        const newHorarios = { ...horarios };
        newHorarios[day][slotIndex][field] = timeToMinutes(value);
        setHorarios(newHorarios);
    };

    const handleRemoveSlot = (day: string, slotIndex: number) => {
        const newHorarios = { ...horarios };
        newHorarios[day].splice(slotIndex, 1);
        setHorarios(newHorarios);
    };

    useEffect(() => {
        if (estabSession) {
            loadAusencias();
        }
    }, [estabSession]);

    return (
        <AusenciasContext.Provider
            value={{
                loading,
                ausencias,
                loadAusencias,
                horarios,
                setHorarios,
                handleAddSlot,
                handleSlotChange,
                handleRemoveSlot,
            }}
        >
            <AtendentesProvider>{children}</AtendentesProvider>
        </AusenciasContext.Provider>
    );
};

const useAusenciasContext = () => {
    const context = useContext(AusenciasContext);
    if (context === undefined) {
        throw new Error('useAusenciasContext must be used within an AusenciasProvider');
    }
    return context;
};

export { AusenciasProvider, useAusenciasContext };
