import { useParamsContext } from '../../../contexts/ParamsContext';
import EstabelecimentosDelete from './Delete';
import EstabelecimentosForm from './Form';
import EstabelecimentosList from './List/List';

export default function Estabelecimentos() {
    const { action } = useParamsContext();

    switch (action) {
        case 'edit':
        case 'add': {
            return <EstabelecimentosForm />;
        }
        case 'delete':
            return <EstabelecimentosDelete />;
        case 'list':
        default:
            return <EstabelecimentosList />;
    }
}
