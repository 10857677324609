import { CreateAccountStep1 } from './Step1';
import { useCreateAccountContext } from './Context';
import { CreateAccountClient } from './FormClient';
import { CreateAccountEstab } from './FormEstab';

export default function CreateAccount() {
    const { typeAccount, returnAction } = useCreateAccountContext();

    return (
        <div>
            {/*{typeAccount === 0 && <CreateAccountStep1 />}*/}

            {/*{typeAccount === 1 && <CreateAccountClient />}*/}

            {/*{typeAccount === 2 && <CreateAccountEstab />}*/}

            <CreateAccountClient />

            <div className={`divider`}></div>

            <div className="flex items-center justify-center mt-4">
                <a
                    href="#"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                    onClick={() => returnAction()}
                >
                    Cancelar
                </a>
            </div>
        </div>
    );
}
