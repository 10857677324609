import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useSnackbar } from '@swat-sccs/react-simple-snackbar';
import { EstabelecimentosService } from '../modules/estabelecimentos/system-admin/Service';
import { brandingEstabId } from '../branding';
import { urlFile } from '../AxiosConfig';

const estabelecimentosService = new EstabelecimentosService();

interface AppContextProps {
    shortMenu: boolean;
    isScrolled: boolean;
    loadingApp: boolean;
    toggleShortMenu: () => void;
    successSnackbar: any;
    errorSnackbar: any;
    showDrawerMenu: any;
    toggleShowDrawerMenu: any;
    image: string;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { loadingAuth } = useAuthContext();
    const [loadingApp, setLoadingApp] = useState(true);

    const [shortMenu, setShortMenu] = useState(localStorage.getItem('shortMenu') === 'true');
    const [isScrolled, setIsScrolled] = useState(false);
    const [showDrawerMenu, setShowDrawerMenu] = useState(false);

    const [image, setImage] = useState('logo.png');

    const [successSnackbar] = useSnackbar({
        style: {
            backgroundColor: 'green',
        },
    });

    const [errorSnackbar] = useSnackbar({
        style: {
            backgroundColor: 'tomato',
        },
    });

    const toggleShortMenu = () => {
        localStorage.setItem('shortMenu', (!shortMenu).toString());
        setShortMenu(!shortMenu);
    };

    const toggleShowDrawerMenu = () => {
        setShowDrawerMenu(!showDrawerMenu);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
            setShowDrawerMenu(false);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!!brandingEstabId) {
            estabelecimentosService.getInfoById(brandingEstabId).then(({ data }) => {
                if (!!data.imageId) {
                    setImage(`${urlFile}/${data.imageId}`);
                }
            });
        }
    }, []);

    useEffect(() => {
        setLoadingApp(loadingAuth);
    }, [loadingAuth]);

    return (
        <AppContext.Provider
            value={{
                shortMenu,
                loadingApp,
                isScrolled,
                toggleShortMenu,
                successSnackbar,
                errorSnackbar,
                showDrawerMenu,
                toggleShowDrawerMenu,
                image,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

export { AppProvider, useAppContext };
