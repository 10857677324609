import React, { useState, ReactNode, FormEvent } from 'react';

interface FormProps {
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    children: ReactNode;
}

const Form: React.FC<FormProps> = ({ onSubmit, children }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);
        onSubmit(event);
    };

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={isSubmitting}>{children}</fieldset>
        </form>
    );
};

export default Form;
