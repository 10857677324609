import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import { useAppContext } from '../../../../contexts/AppContext';
import { useParamsContext } from '../../../../contexts/ParamsContext';
import { useAgendaClienteContext } from '../Context';
import { AgendaClienteService } from '../Service';
const horarioService = new AgendaClienteService();
interface FormHorarioClienteParams {
    date: moment.Moment;
    handlePreviousDay: () => void;
    handleNextDay: () => void;
    setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
    estab: any;
    setEstab: React.Dispatch<React.SetStateAction<any>>;
    servico: any;
    setServico: React.Dispatch<React.SetStateAction<any>>;
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    atendente: any;
    setAtendente: React.Dispatch<React.SetStateAction<any>>;
    horario: number;
    setHorario: React.Dispatch<React.SetStateAction<number>>;
    submitForm: () => void;
    openConfirm: boolean;
    loading: boolean;
    setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormHorarioClienteContext = createContext<FormHorarioClienteParams | undefined>(undefined);

export const useFormHorarioClienteContext = () => {
    const context = useContext(FormHorarioClienteContext);
    if (!context) {
        throw new Error('useFormHorarioClienteContext must be used within a FormHorarioClienteProvider');
    }
    return context;
};

export const FormHorarioClienteProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { loadAgendamentos } = useAgendaClienteContext();
    const { setParams } = useParamsContext();
    const [openConfirm, setOpenConfirm] = useState(false);

    const [loading, setLoading] = useState(false);
    const { successSnackbar, errorSnackbar } = useAppContext();

    const [date, setDate] = useState(moment());
    const handlePreviousDay = () => {
        setDate((prevDate) => moment(prevDate).subtract(1, 'days'));
    };

    const handleNextDay = () => {
        setDate((prevDate) => moment(prevDate).add(1, 'days'));
    };

    const [estab, setEstab] = useState({} as any);
    const [servico, setServico] = useState({} as any);
    const [data, setData] = useState({} as any);
    const [atendente, setAtendente] = useState({} as any);
    const [horario, setHorario] = useState(0);

    const submitForm = async () => {
        const agendamentoData = {
            startMinutes: horario,
            estabId: estab._id,
            startAt: moment(data).startOf('days').add(horario, 'minutes').toDate(),
            servicoId: servico._id,
            attendantId: atendente.uid,
        };

        try {
            setLoading(true);
            const { data } = await horarioService.createAgendamento(agendamentoData);
            successSnackbar('Agendamento criado com sucesso!');
            loadAgendamentos();

            setParams({ action: 'new', id: data?._id });
        } catch (error) {
            errorSnackbar('Ocorreu um erro ao criar o agendamento.');
        } finally {
            setLoading(false);
        }

        setOpenConfirm(false);
    };

    return (
        <FormHorarioClienteContext.Provider
            value={{
                date,
                handlePreviousDay,
                handleNextDay,
                setDate,
                estab,
                setEstab,
                servico,
                setServico,
                data,
                setData,
                atendente,
                setAtendente,
                horario,
                setHorario,
                submitForm,
                openConfirm,
                setOpenConfirm,
                loading,
            }}
        >
            {children}
        </FormHorarioClienteContext.Provider>
    );
};
