import React from 'react';
import { useParamsContext } from '../../contexts/ParamsContext';

export default function ClientesForm() {
    const { id } = useParamsContext();

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };
    return (
        <div>
            <form onSubmit={submit}>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Nome</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Nome do serviço que será exibido para seus clientes"
                    />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Duração</span>
                    </div>
                    <input type="text" className="input input-bordered" placeholder="Duração do serviço" />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Atendentes</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Número de atendentes necessários"
                    />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Descrição</span>
                    </div>
                    <textarea className="textarea textarea-bordered" placeholder="Descrição do serviço"></textarea>
                </label>

                <div className="divider"></div>

                <button className="btn shadow-md" type="submit">
                    {!!id ? 'Editar' : 'Adicionar'}
                </button>
            </form>
        </div>
    );
}
