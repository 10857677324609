import React from "react";

export const PoliticaPrivacidade = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-base-100 text-base-content">
            <header className="text-center mb-8">
                <h1 className="text-4xl font-bold text-black">Política de Privacidade</h1>
            </header>
            <section className="space-y-6">
                <p>
                    Bem-vindo ao <span className="font-bold">SalonFlow</span>! Sua
                    privacidade é muito importante para nós. Esta Política de Privacidade
                    explica como coletamos, usamos e protegemos suas informações. Ao
                    utilizar nosso aplicativo, você concorda com os termos descritos
                    aqui.
                </p>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">1. Informações que Coletamos</div>
                    <div className="collapse-content">
                        <ul className="list-disc ml-6">
                            <li>Nome</li>
                            <li>E-mail</li>
                            <li>Número de telefone</li>
                            <li>Imagem de perfil</li>
                            <li>Dados de agendamento</li>
                            <li>Interações com o sistema</li>
                            <li>Históricos de uso no sistema</li>
                        </ul>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">2. Como Usamos suas Informações</div>
                    <div className="collapse-content">
                        <ul className="list-disc ml-6">
                            <li>Identificação e autenticação no sistema</li>
                            <li>Facilitar o contato entre clientes e atendentes</li>
                            <li>Melhorar a experiência do usuário no aplicativo</li>
                            <li>Gerar relatórios e estatísticas de uso</li>
                        </ul>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">3. Compartilhamento de Informações</div>
                    <div className="collapse-content">
                        <p>
                            Compartilhamos suas informações com os atendentes cadastrados para:
                        </p>
                        <ul className="list-disc ml-6">
                            <li>Identificação de clientes</li>
                            <li>Contato e execução de rotinas necessárias no aplicativo</li>
                        </ul>
                        <p className="mt-2">
                            Não vendemos, alugamos ou compartilhamos suas informações com
                            terceiros para fins comerciais.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">4. Cookies e Tecnologias Semelhantes</div>
                    <div className="collapse-content">
                        <p>
                            Utilizamos cookies e tecnologias semelhantes para garantir o
                            funcionamento adequado do sistema e melhorar sua experiência no
                            aplicativo.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">5. Armazenamento de Dados</div>
                    <div className="collapse-content">
                        <p>
                            As informações dos usuários são armazenadas por tempo
                            indeterminado. Caso um usuário exclua sua conta, todos os seus
                            dados pessoais serão permanentemente deletados de nossos sistemas.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">6. Direitos dos Usuários</div>
                    <div className="collapse-content">
                        <ul className="list-disc ml-6">
                            <li>Solicitar alterações em suas informações pessoais</li>
                            <li>Excluir seus dados da plataforma a qualquer momento</li>
                        </ul>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">7. Público-Alvo</div>
                    <div className="collapse-content">
                        <p>O aplicativo é destinado a:</p>
                        <ul className="list-disc ml-6">
                            <li>Clientes de barbearias</li>
                            <li>Barbearias que desejam oferecer seus serviços através da plataforma</li>
                        </ul>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">8. Dados Sensíveis</div>
                    <div className="collapse-content">
                        <p>
                            O SalonFlow não coleta dados sensíveis, como localização
                            geográfica ou informações financeiras.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">9. Alterações na Política de Privacidade</div>
                    <div className="collapse-content">
                        <p>
                            Podemos atualizar esta Política de Privacidade periodicamente.
                            Notificaremos você sobre quaisquer alterações significativas.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-plus bg-base-200 rounded-box">
                    <input type="checkbox" />
                    <div className="collapse-title text-lg font-medium">10. Contato</div>
                    <div className="collapse-content">
                        <p>
                            Se você tiver dúvidas ou preocupações sobre esta Política de
                            Privacidade, entre em contato conosco pelo e-mail:{" "}
                            <a href="mailto:seuemail@salonflow.com" className="text-primary hover:underline">
                                seuemail@salonflow.com
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};
