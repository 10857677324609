import React from 'react';
import { useLandingPageContext } from '../Context';

export const SocialMedia = () => {
    const { estab } = useLandingPageContext();

    return (
        <section id="redeSocial" className="py-16">
            <div className="container mx-auto px-4">
                <div className="flex items-center mb-8">
                    <hr className="flex-grow border-t border-gray-700" />
                    <h2 className="text-3xl font-bold text-center mx-4 text-white text-shadow">
                        Siga-nos nas Redes Sociais
                    </h2>
                    <hr className="flex-grow border-t border-gray-700" />
                </div>
                <p className="text-center mb-8 text-gray-400 text-shadow">
                    Fique por dentro das últimas tendências e novidades
                </p>

                <div className="flex flex-wrap justify-center gap-8">
                    {estab?.socialLinks?.map((social: any, index: any) => (
                        <a
                            key={index}
                            href={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`flex flex-col items-center gap-2 transition-colors duration-300 ${social.color} text-white`}
                        >
                            <div className="w-16 h-16 rounded-full bg-gray-700 flex items-center justify-center hover:bg-gray-700 transition-colors duration-300">
                                <i className={social.icon} />
                            </div>
                            <span className="font-medium">{social.name}</span>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};
