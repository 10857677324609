import ConfiguracoesContaIdioma from './Idioma';
import ConfiguracoesContaNotifications from './Notifications';
import ConfiguracoesContaGeral from './conta/Geral';
import ConfiguracoesContaEmail from './conta/Email';
import ConfiguracoesContaPhoneNumber from './conta/PhoneNumber';
import ConfiguracoesContaPassword from './conta/Password';
import {useAuthContext} from "../../../contexts/AuthContext";

export default function ConfiguracoesConta() {
    const {user} = useAuthContext()

    return (
        <div className="join join-vertical w-full text-md md:text-xl">
            <div className="collapse collapse-arrow join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" defaultChecked />
                <div className="collapse-title font-medium">Perfil</div>
                <div className="collapse-content">
                    <ConfiguracoesContaGeral />
                </div>
            </div>

            <div className="collapse collapse-arrow join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title font-medium">Email</div>
                <div className="collapse-content">
                    <ConfiguracoesContaEmail />
                </div>
            </div>

            <div className="collapse collapse-arrow join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title font-medium flex gap-2 items-center">
                    {!user?.phoneNumber && <i className="fa-solid fa-triangle-exclamation text-warning"></i>}
                    Número de telefone
                </div>
                <div className="collapse-content">
                    <ConfiguracoesContaPhoneNumber />
                </div>
            </div>

            <div className="collapse collapse-arrow join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title font-medium">Senha</div>
                <div className="collapse-content">
                    <ConfiguracoesContaPassword />
                </div>
            </div>

            <div className="collapse collapse-arrow join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title font-medium">Idioma</div>
                <div className="collapse-content">
                    <ConfiguracoesContaIdioma />
                </div>
            </div>

            <div className="collapse collapse-arrow join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title font-medium">Notificações</div>
                <div className="collapse-content">
                    <ConfiguracoesContaNotifications />
                </div>
            </div>
        </div>
    );
}
