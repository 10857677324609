import { useParamsContext } from '../../../contexts/ParamsContext';
import ScheduleList from './ScheduleList';
import AgendaSettings from './settings/Settings';
import { Dashboards } from './Dashboard/Dashboards';
import { DashboardProvider } from './Dashboard/Context';

const Agenda = () => {
    const { action } = useParamsContext();

    switch (action) {
        case 'settings': {
            return <AgendaSettings />;
        }
        default:
            return (
                <div>
                    <DashboardProvider>
                        <Dashboards />
                    </DashboardProvider>

                    <ScheduleList />
                </div>
            );
    }
};

export default Agenda;
