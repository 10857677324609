import { GeralEstabelecimento } from './Geral';
import { ContatoEstabelecimento } from './Contato';
import { useEstabFormContext } from './Context';
import { EnderecoEstabelecimento } from './Address';
import { ImageEstab } from './ImageEstab';
import {LandingPageForm} from "./LandingPage";

export const EstabelecimentoForm = () => {
    const { imageId, setImageId, handleSubmit, loading } = useEstabFormContext();

    return (
        <div className="container mx-auto p-4">
            <form onSubmit={handleSubmit}>
                <div className="join join-vertical w-full">
                    <div className="collapse collapse-arrow join-item border-base-300 border">
                        <input type="radio" name="my-accordion-4" defaultChecked />
                        <div className="collapse-title text-xl font-medium">Geral</div>
                        <div className="collapse-content">
                            <ImageEstab key={`${!!imageId.length}`} imageId={imageId} setImageId={setImageId} />

                            <GeralEstabelecimento />

                            <div className="divider" />

                            <button className="btn shadow-md" type="submit" disabled={loading}>
                                Salvar
                            </button>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-base-300 border">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">Endereço</div>
                        <div className="collapse-content">
                            <EnderecoEstabelecimento />

                            <div className="divider" />

                            <button className="btn shadow-md" type="submit" disabled={loading}>
                                Salvar
                            </button>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-base-300 border">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">Contato</div>
                        <div className="collapse-content">
                            <ContatoEstabelecimento />

                            <div className="divider" />

                            <button className="btn shadow-md" type="submit" disabled={loading}>
                                Salvar
                            </button>
                        </div>
                    </div>

                    <div className="collapse collapse-arrow join-item border-base-300 border">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">Landing Page</div>
                        <div className="collapse-content">
                            <LandingPageForm />

                            <div className="divider" />

                            <button className="btn shadow-md" type="submit" disabled={loading}>
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
