import { FileUpload, PreviewFile } from '../../../../components/files/FilesUpload';
import { ListServices } from '../../../servicos/componentes/ListServices';
import { useEstabFormContext } from './Context';

export const LandingPageForm = () => {
    const {
        landingPageTitle,
        setLandingPageTitle,
        landingPageDescription,
        setLandingPageDescription,
        landingPageImagesIdsHeader,
        setLandingPageImagesIdsHeader,
        landingPageServicesIds,
        setLandingPageServicesIds,
        landingPageImagesIds,
        setActiveLandingPage,
        activeLandingPage,
        setLandingPageAbout,
        setLandingPageIcoId,
        landingPageIcoId,
        setLandingName,
        landingPageName,
        landingPageAbout,
        setLandingPageImagesIds,
    } = useEstabFormContext();

    return (
        <>
            <div className="form-control mb-4">
                <label className="label" htmlFor="title">
                    <span className="label-text">Ativar?</span>
                </label>
                <input
                    name="active"
                    checked={activeLandingPage}
                    onChange={(e) => setActiveLandingPage(e.target.checked)}
                    type="checkbox"
                    className="toggle toggle-sm w-full max-w-96"
                />
            </div>

            {activeLandingPage && (
                <>
                    <div className="form-control mb-4">
                        <label className="label" htmlFor="title">
                            <span className="label-text">Título</span>
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={landingPageTitle}
                            onChange={(e) => setLandingPageTitle(e.target.value)}
                            className="input input-bordered w-full max-w-96"
                        />
                    </div>

                    <div className="form-control mb-4">
                        <label className="label" htmlFor="description">
                            <span className="label-text">Sub Título</span>
                        </label>
                        <input
                            type="text"
                            name="description"
                            value={landingPageDescription}
                            onChange={(e) => setLandingPageDescription(e.target.value)}
                            className="input input-bordered w-full max-w-96"
                        />
                    </div>

                    <div className="form-control mb-4">
                        <label className="label" htmlFor="about">
                            <span className="label-text">Sobre o estabelecimento</span>
                        </label>
                        <textarea
                            name="about"
                            value={landingPageAbout}
                            onChange={(e) => setLandingPageAbout(e.target.value)}
                            className="textarea textarea-bordered w-full max-w-96 h-24"
                        />
                    </div>

                    <div className="divider"></div>

                    <div className="form-control mb-4">
                        <label className="label" htmlFor="description">
                            <span className="label-text">Icon</span>
                        </label>
                        <FileUpload
                            multiple={false}
                            key={landingPageIcoId?.length}
                            value={[landingPageIcoId]}
                            setValue={(files) => setLandingPageIcoId(files[0])}
                        >
                            {({ fileData, handleChange, removeFile, inputRef, loading }) => {
                                const maxFiles = 1;

                                const handleFileChange = (e: any) => {
                                    const selectedFiles: any = Array.from(e.target.files || []);
                                    const availableSlots = maxFiles - fileData.length;

                                    if (availableSlots <= 0) {
                                        alert(`Você já atingiu o limite de ${maxFiles} arquivos.`);
                                        return;
                                    }

                                    const filesToUpload = selectedFiles.slice(0, availableSlots);

                                    handleChange(filesToUpload);
                                };

                                return (
                                    <div>
                                        {fileData.length < maxFiles && (
                                            <>
                                                <input
                                                    type="file"
                                                    accept="image/vnd.microsoft.icon"
                                                    ref={inputRef}
                                                    multiple
                                                    disabled={loading}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                />
                                                <div
                                                    onClick={() => {
                                                        inputRef?.current?.click();
                                                    }}
                                                    className={`${loading && 'btn-disabled'} text-2xl font-bold text-gray-600 avatar placeholder cursor-pointer hover:opacity-80 w-10 h-10 flex items-center justify-center bg-gray-200 mb-3`}
                                                >
                                                    +
                                                </div>
                                            </>
                                        )}
                                        <div className="flow-root" onClick={(e) => e.preventDefault()}>
                                            {fileData.map((file) => (
                                                <PreviewFile key={file.id} fileData={file} onRemove={removeFile} />
                                            ))}
                                        </div>

                                        <div className="text-xs">
                                            {fileData.length}/{maxFiles}
                                        </div>
                                    </div>
                                );
                            }}
                        </FileUpload>
                    </div>

                    <div className="form-control mb-4 max-w-96">
                        <label className="label" htmlFor="pageName">
                            <span className="label-text">Url</span>
                        </label>
                        <div className="flex items-center space-x-2">
                            <span className="text-gray-500">www.salonflow.com.br/</span>
                            <input
                                type="text"
                                name="pageName"
                                value={landingPageName}
                                onChange={(e) => setLandingName(e.target.value)}
                                className="input input-sm input-bordered"
                                placeholder="Nome da página"
                            />
                        </div>
                    </div>

                    <div className="divider"></div>

                    <div className="form-control mb-4">
                        <label className="label" htmlFor="imageHeader">
                            <span className="label-text">Imagens cabeçalho</span>
                        </label>
                        <FileUpload
                            key={landingPageImagesIdsHeader?.length}
                            value={landingPageImagesIdsHeader}
                            setValue={(files) => setLandingPageImagesIdsHeader(files)}
                        >
                            {({ fileData, handleChange, removeFile, inputRef, loading }) => {
                                const maxFiles = 10;

                                const handleFileChange = (e: any) => {
                                    const selectedFiles: any = Array.from(e.target.files || []);
                                    const availableSlots = maxFiles - fileData.length;

                                    if (availableSlots <= 0) {
                                        alert(`Você já atingiu o limite de ${maxFiles} arquivos.`);
                                        return;
                                    }

                                    const filesToUpload = selectedFiles.slice(0, availableSlots);

                                    handleChange(filesToUpload);
                                };

                                return (
                                    <div>
                                        {fileData.length < maxFiles && (
                                            <>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    ref={inputRef}
                                                    multiple
                                                    disabled={loading}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                />
                                                <div
                                                    onClick={() => {
                                                        inputRef?.current?.click();
                                                    }}
                                                    className={`${loading && 'btn-disabled'} text-2xl font-bold text-gray-600 avatar placeholder cursor-pointer hover:opacity-80 w-10 h-10 flex items-center justify-center bg-gray-200 mb-3`}
                                                >
                                                    +
                                                </div>
                                            </>
                                        )}
                                        <div className="flow-root" onClick={(e) => e.preventDefault()}>
                                            {fileData.map((file) => (
                                                <PreviewFile key={file.id} fileData={file} onRemove={removeFile} />
                                            ))}
                                        </div>

                                        <div className="text-xs">
                                            {fileData.length}/{maxFiles}
                                        </div>
                                    </div>
                                );
                            }}
                        </FileUpload>
                    </div>

                    <div className="divider"></div>

                    <div className="form-control mb-4">
                        <ListServices value={landingPageServicesIds} setValue={setLandingPageServicesIds} />
                    </div>

                    <div className="divider"></div>

                    <div className="form-control mb-4">
                        <label className="label" htmlFor="images">
                            <span className="label-text">Imagens</span>
                        </label>
                        <FileUpload
                            key={landingPageImagesIds?.length}
                            value={landingPageImagesIds}
                            setValue={setLandingPageImagesIds}
                        >
                            {({ fileData, handleChange, removeFile, inputRef, loading }) => {
                                const maxFiles = 10;

                                const handleFileChange = (e: any) => {
                                    const selectedFiles: any = Array.from(e.target.files || []);
                                    const availableSlots = maxFiles - fileData.length;

                                    if (availableSlots <= 0) {
                                        alert(`Você já atingiu o limite de ${maxFiles} arquivos.`);
                                        return;
                                    }

                                    const filesToUpload = selectedFiles.slice(0, availableSlots);

                                    handleChange(filesToUpload);
                                };

                                return (
                                    <div>
                                        {fileData.length < maxFiles && (
                                            <>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    ref={inputRef}
                                                    multiple
                                                    disabled={loading}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                />
                                                <div
                                                    onClick={() => {
                                                        inputRef?.current?.click();
                                                    }}
                                                    className={`${loading && 'btn-disabled'} text-2xl font-bold text-gray-600 avatar placeholder cursor-pointer hover:opacity-80 w-10 h-10 flex items-center justify-center bg-gray-200 mb-3`}
                                                >
                                                    +
                                                </div>
                                            </>
                                        )}
                                        <div className="flow-root" onClick={(e) => e.preventDefault()}>
                                            {fileData.map((file) => (
                                                <PreviewFile key={file.id} fileData={file} onRemove={removeFile} />
                                            ))}
                                        </div>

                                        <div className="text-xs">
                                            {fileData.length}/{maxFiles}
                                        </div>
                                    </div>
                                );
                            }}
                        </FileUpload>
                    </div>
                </>
            )}
        </>
    );
};
