import React, { createContext, ReactNode, useContext, useState } from 'react';
import moment from 'moment';

interface DashboardContextType {
    competence: string;
    setCompetence: (competencia: string) => void;
}

const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
    const [competence, setCompetence] = useState<string>(moment().format('YYYY-MM'));

    return <DashboardContext.Provider value={{ competence, setCompetence }}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = (): DashboardContextType => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error('useDashboardContext must be used within a DashboardProvider');
    }
    return context;
};
