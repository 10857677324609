import moment from 'moment';
import 'moment/locale/pt-br';
import { useAgendaContext } from '../ContextAgenda';
import { useParamsContext } from '../../../../contexts/ParamsContext';
import ScheduleViewHeader from './ScheduleViewHeader';
import { getInitialName } from '../../../../components/utils';
import { Histories } from '../../../../history/Historys';

moment.locale('pt-br');

export default function ScheduleView() {
    const { clear } = useParamsContext();
    const { selectedSchedule: schedule } = useAgendaContext();

    return (
        <div>
            <div className="mb-2">
                <button className="btn btn-circle btn-sm" onClick={clear}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>

            {schedule && <ScheduleViewHeader schedule={schedule} />}

            <div role="tablist" className="tabs tabs-bordered mt-3">
                <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Geral" defaultChecked />
                <div role="tabpanel" className="tab-content">
                    <div className="overflow-x-auto">
                        <table className="table">
                            <tbody>
                                <tr className="hover">
                                    <th>Atendente</th>
                                    <td>
                                        <div className="flex items-center gap-3">
                                            <div className="avatar">
                                                <div className="bg-neutral text-neutral-content mask mask-squircle h-12 w-12 content-center text-center">
                                                    {schedule?.attendant.profilePictureUrl ? (
                                                        <img
                                                            alt="Foto do Cliente"
                                                            src={schedule.attendant.profilePictureUrl}
                                                        />
                                                    ) : (
                                                        <span className="text-xl">
                                                            {getInitialName(schedule?.attendant.name)}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold">{schedule?.attendant.name}</div>
                                                <button
                                                    disabled={!schedule?.attendant.phoneNumber}
                                                    onClick={() => {
                                                        if (schedule?.attendant.phoneNumber) {
                                                            const formattedPhone =
                                                                schedule?.attendant.phoneNumber.replace(/\D/g, '');
                                                            const whatsappLink = `https://wa.me/${formattedPhone}`;
                                                            window.open(whatsappLink, '_blank');
                                                        }
                                                    }}
                                                    className="btn btn-circle btn-xs"
                                                >
                                                    <i className="fa-brands fa-whatsapp"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr className="hover">
                                    <th>Serviço</th>
                                    <td>
                                        {schedule?.service.name}
                                        <br />
                                        {schedule?.service?.price
                                            ? new Intl.NumberFormat('pt-BR', {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                              }).format(schedule.service.price)
                                            : 'R$ 0,00'}
                                    </td>
                                </tr>

                                {!!schedule?.cancellation?.date && (
                                    <tr className="hover">
                                        <th>Cancelamento</th>
                                        <td>
                                            <div className="">
                                                <div>{schedule?.cancellation?.observation}</div>
                                                <div className="text-xs">
                                                    {moment(schedule?.cancellation?.date).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                                <tr className="hover">
                                    <th>Agendado em</th>
                                    <td>{moment(schedule?.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Histórico" />
                <div role="tabpanel" className="tab-content pt-5">
                    <div className="overflow-x-auto">
                        <Histories resourceId={schedule?._id} />
                    </div>
                </div>
            </div>
        </div>
    );
}
