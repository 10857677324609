import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { DashboardService } from './service';

const service = new DashboardService();

export const ChartVisaoGeral = () => {
    const [loading, setLoading] = useState(false);
    const [dadosSemana, setDadosSemana] = useState({
        hoje: {
            totalAgendamentos: 0,
            faturamento: 0,
            atendimentosConcluidos: 0,
        },
        semana: {
            totalAgendamentos: 0,
            faturamento: 0,
            atendimentosConcluidos: 0,
        },
    });

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const response = await service.getVisaoGeral(moment().format('YYYY-MM-DD'));
                setDadosSemana(response.data);
            } catch (e) {
                console.error('Erro ao carregar os dados da visão geral:', e);
            } finally {
                setLoading(false);
            }
        };

        load();
    }, []);

    const dataAtual = moment().locale('pt-br').format('dddd, D [de] MMMM');

    return (
        <div className={`${loading && 'opacity-50 cursor-not-allowed'}`}>
            <section className="mb-8 text-center lg:text-start">
                <h2 className="text-2xl font-bold text-gray-800 mb-2 ">Hoje</h2>
                <h6 className="text-sm text-gray-500 mb-4">{dataAtual}</h6>

                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6">
                    <div
                        className="stat bg-blue-50 rounded-lg"
                        style={{
                            justifyItems: 'center',
                            padding: '10px 0',
                            display: 'block',
                            alignContent: 'center',
                        }}
                    >
                        <div className="stat-title text-sm">Agendados</div>
                        <div className="text-blue-600 text-3xl font-semibold">{dadosSemana.hoje.totalAgendamentos}</div>
                    </div>

                    <div
                        className="stat bg-green-50 rounded-lg"
                        style={{
                            justifyItems: 'center',
                            padding: '10px 0',
                            display: 'block',
                            alignContent: 'center',
                        }}
                    >
                        <div className="stat-title text-sm">Concluídos</div>
                        <div className="text-green-600 text-3xl font-semibold">
                            {dadosSemana.hoje.atendimentosConcluidos}
                        </div>
                    </div>

                    <div
                        className="stat bg-yellow-50 rounded-lg"
                        style={{
                            justifyItems: 'center',
                            padding: '10px 0',
                            display: 'block',
                            alignContent: 'center',
                        }}
                    >
                        <div className="stat-title text-sm">Faturamento</div>
                        <div className="text-yellow-600 text-2xl font-semibold">
                            R$ {dadosSemana.hoje.faturamento.toFixed(2)}
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-center lg:text-start">
                <h2 className="text-2xl font-bold text-gray-800 mb-2 text-center lg:text-start">Essa Semana</h2>

                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6">
                    <div
                        className="stat bg-blue-50 rounded-lg"
                        style={{
                            justifyItems: 'center',
                            padding: '10px 0',
                            display: 'block',
                            alignContent: 'center',
                        }}
                    >
                        <div className="stat-title text-sm">Agendados</div>
                        <div className="text-blue-600 text-3xl font-semibold">
                            {dadosSemana.semana.totalAgendamentos}
                        </div>
                    </div>

                    <div
                        className="stat bg-green-50 rounded-lg"
                        style={{
                            justifyItems: 'center',
                            padding: '10px 0',
                            display: 'block',
                            alignContent: 'center',
                        }}
                    >
                        <div className="stat-title text-sm">Concluídos</div>
                        <div className="text-green-600 text-3xl font-semibold">
                            {dadosSemana.semana.atendimentosConcluidos}
                        </div>
                    </div>

                    <div
                        className="stat bg-yellow-50 rounded-lg"
                        style={{
                            justifyItems: 'center',
                            padding: '10px 0',
                            display: 'block',
                            alignContent: 'center',
                        }}
                    >
                        <div className="stat-title text-sm">Faturamento</div>
                        <div className="text-yellow-600 text-2xl font-semibold">
                            R$ {dadosSemana.semana.faturamento.toFixed(2)}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
