export enum StatusHorario {
    PENDENTE = 'pendente',
    AGENDADO = 'agendado',
    CANCELADO = 'cancelado',
    CONCLUIDO = 'concluído',
}

export const StatusHorarioLabels: Record<StatusHorario, string> = {
    [StatusHorario.PENDENTE]: 'Pendente',
    [StatusHorario.AGENDADO]: 'Agendado',
    [StatusHorario.CANCELADO]: 'Cancelado',
    [StatusHorario.CONCLUIDO]: 'Concluído',
};

interface TimeSlot {
    start: number;
    end: number;
}

export interface Horarios {
    [key: string]: TimeSlot[];
}

export const dayLabels: { [key: string]: string } = {
    segunda: 'Segunda-feira',
    terca: 'Terça-feira',
    quarta: 'Quarta-feira',
    quinta: 'Quinta-feira',
    sexta: 'Sexta-feira',
    sabado: 'Sábado',
    domingo: 'Domingo',
};

export const minutesToTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60)
        .toString()
        .padStart(2, '0');
    const mins = (minutes % 60).toString().padStart(2, '0');
    return `${hours}:${mins}`;
};

export const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};

export const initialHorarios: Horarios = {
    segunda: [],
    terca: [],
    quarta: [],
    quinta: [],
    sexta: [],
    sabado: [],
    domingo: [],
};

export interface Agendamento {
    _id: string;
    status: StatusHorario;
    service: {
        _id: string;
        name: string;
        imagesIds: string[];
        price: number;
    };
    attendant: {
        uid: string;
        name: string;
        email: string;
        phoneNumber: string;
        profilePictureUrl: string;
    };
    assessment?: {
        value: number;
        observation: string;
        date: Date;
    };
    cancellation?: {
        date: string;
        userId: string;
        observation: string;
    };
    scheduleDate: string;
    confirmationDate: string;
    startAt: string;
    endAt: string;
    estab: {
        _id: string;
        name: string;
        imageId: string;
        description: string;
        address: any;
        phoneNumber: string;
        isPhoneNumberWhatsapp: boolean;
        location: string;
    };
    observation?: string;
    client: {
        uid: string;
        name: string;
        email: string;
        phoneNumber?: string;
        profileImageUrl?: string;
        whatsappLink?: string;
        amountSchedules?: Number;
    };
    accessibility?: {
        observation: string;
    };
    confirmation: {
        date?: Date;
        userId?: string;
        required: boolean;
        hoursBeforeConfirmation: number;
    };
    createdAt: string;
}
