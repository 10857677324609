export default function PlanosCliente() {
    const planos = [
        {
            id: 1,
            titulo: 'Plano Básico',
            descricao: 'Ideal para quem está começando. Inclui funcionalidades essenciais.',
            preco: 'R$ 49,90/mês',
            imagem: 'https://img.daisyui.com/images/stock/photo-1635805737707-575885ab0820.webp',
            recursos: ['1 Usuário', 'Suporte via email', 'Armazenamento de 10GB'],
            popular: false,
        },
        {
            id: 2,
            titulo: 'Plano Premium',
            descricao: 'Para usuários avançados com mais funcionalidades e suporte dedicado.',
            preco: 'R$ 99,90/mês',
            imagem: 'https://img.daisyui.com/images/stock/photo-1635805737707-575885ab0820.webp',
            recursos: ['5 Usuários', 'Suporte por chat', 'Armazenamento de 50GB'],
            popular: true,
        },
        {
            id: 3,
            titulo: 'Plano Empresarial',
            descricao: 'Soluções personalizadas para empresas, com suporte 24/7 e recursos avançados.',
            preco: 'Sob consulta',
            imagem: 'https://img.daisyui.com/images/stock/photo-1635805737707-575885ab0820.webp',
            recursos: ['Usuários ilimitados', 'Suporte 24/7', 'Armazenamento de 1TB'],
            popular: false,
        },
    ];

    return (
        <div className="flex flex-col gap-4">
            {planos.map((plano) => (
                <div
                    key={plano.id}
                    className={`bg-white shadow-lg rounded-lg p-6 flex flex-col items-center transform transition hover:scale-105 ${
                        plano.popular ? 'border-4 border-primary' : ''
                    }`}
                >
                    {plano.popular && (
                        <div className="absolute top-4 right-4 bg-primary text-white text-xs font-bold px-3 py-1 rounded-full">
                            Mais Popular
                        </div>
                    )}
                    <img src={plano.imagem} alt={plano.titulo} className="w-32 h-32 object-cover rounded-full mb-4" />
                    <h2 className="text-2xl font-bold text-gray-800">{plano.titulo}</h2>
                    <p className="text-gray-600 text-center my-4">{plano.descricao}</p>
                    <span className="text-xl font-semibold text-primary mb-4">{plano.preco}</span>
                    <ul className="text-sm text-gray-600 mb-6">
                        {plano.recursos.map((recurso, index) => (
                            <li key={index} className="flex items-center mb-2">
                                <i className="fa fa-check-circle text-green-500 mr-2"></i> {recurso}
                            </li>
                        ))}
                    </ul>
                    <button className="btn btn-primary w-full">Assinar</button>
                </div>
            ))}
        </div>
    );
}
