import { FileUpload, PreviewFile } from '../../../components/files/FilesUpload';

export default function FormServicoImages({ value, setValue, disabled = false }: any) {
    return (
        <FileUpload key={`${!!value.length}`} value={value} setValue={setValue} disabled={disabled}>
            {({ fileData, handleChange, removeFile, inputRef, loading }) => {
                const maxFiles = 5;

                const handleFileChange = (e: any) => {
                    const selectedFiles: any = Array.from(e.target.files || []);
                    const availableSlots = maxFiles - fileData.length;

                    if (availableSlots <= 0) {
                        alert(`Você já atingiu o limite de ${maxFiles} arquivos.`);
                        return;
                    }

                    const filesToUpload = selectedFiles.slice(0, availableSlots);

                    handleChange(filesToUpload);
                };

                return (
                    <div>
                        {fileData.length < maxFiles && (
                            <>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={inputRef}
                                    multiple
                                    disabled={loading}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <div
                                    className={`${loading && 'btn-disabled'} text-2xl font-bold text-gray-600 avatar placeholder cursor-pointer hover:opacity-80 w-10 h-10 flex items-center justify-center bg-gray-200 mb-3`}
                                >
                                    +
                                </div>
                            </>
                        )}
                        <div className="flow-root" onClick={(e) => e.preventDefault()}>
                            {fileData.map((file) => (
                                <PreviewFile key={file.id} fileData={file} onRemove={removeFile} />
                            ))}
                        </div>

                        <div className="text-xs">
                            {fileData.length}/{maxFiles}
                        </div>
                    </div>
                );
            }}
        </FileUpload>
    );
}
