import InputMask from 'react-input-mask';
import { useEstabFormContext } from './Context';
import React from 'react';

export function GeralEstabelecimento() {
    const { name, setName, cnpj, setCnpj, description, setDescription, openingHours, setOpeningHours } =
        useEstabFormContext();

    return (
        <>
            <div className="form-control mb-4">
                <label className="label" htmlFor="name">
                    <span className="label-text">Nome:</span>
                </label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="input input-bordered w-full max-w-96"
                />
            </div>

            <div className="form-control mb-4">
                <label className="label" htmlFor="cnpj">
                    <span className="label-text">CNPJ:</span>
                </label>
                <InputMask
                    mask="99.999.999/9999-99"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                    type="text"
                    id="cnpj"
                    className="input input-bordered w-full max-w-96"
                    maskChar={null}
                />
            </div>
            <div className="form-control mb-4">
                <label className="label" htmlFor="description">
                    <span className="label-text">Descrição:</span>
                </label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="textarea textarea-bordered w-full max-w-96"
                />
            </div>

            <div className="form-control mb-4">
                <label className="label" htmlFor="openingHours">
                    <span className="label-text">Horário de Funcionamento</span>
                </label>
                <input
                    type="text"
                    id="openingHours"
                    value={openingHours}
                    onChange={(e) => setOpeningHours(e.target.value)}
                    className="input input-bordered w-full max-w-96"
                    placeholder="Ex.: Segunda a Sábado, 9:00 - 20:00"
                />
            </div>
        </>
    );
}
