import { useEffect, useState } from 'react';
import { useDashboardContext } from './Context';
import { ChatsStatus } from './Statuses';
import { DashboardHeader } from './Header';
import { ChatsConclusoesMes } from './ConclusoesMes';
import { ChatsFaturamentoMes } from './FaturamentoMes';
import { ChartVisaoGeral } from './VisaoGeral';
import moment from 'moment';

export const Dashboards = () => {
    const { competence, setCompetence } = useDashboardContext();
    const [isDashboardOpen, setIsDashboardOpen] = useState(() => {
        const savedState = localStorage.getItem('isDashboardOpen');
        return savedState ? JSON.parse(savedState) : true;
    });

    const toggleDashboard = () => {
        setIsDashboardOpen((prevState: any) => !prevState);
    };

    useEffect(() => {
        localStorage.setItem('isDashboardOpen', JSON.stringify(isDashboardOpen));
    }, [isDashboardOpen]);

    return (
        <div className="join join-vertical w-full">
            <div className="collapse collapse-arrow join-item border-base-200">
                <input type="checkbox" checked={isDashboardOpen} onChange={toggleDashboard} />
                <div className="collapse-title text-xl font-medium">Dashboard</div>

                <div className="collapse-content" style={{ padding: 0 }}>
                    <div className="px-1 pb-4">
                        <div className="card w-full bg-base-100 shadow-md hover:shadow-lg mb-4 border border-gray-200 md:px-5">
                            <div className="card-body" style={{ padding: '5px' }}>
                                <div className="mx-auto items-center grid md:flex gap-2">
                                    <input
                                        type="month"
                                        value={competence}
                                        onChange={(e) => setCompetence(e.target.value)}
                                        className="input float-start mb-5 ml-2"
                                        style={{ margin: 0 }}
                                    />
                                    <div className="flex gap-2 justify-center">
                                        <button
                                            className="btn btn-sm btn-circle"
                                            onClick={() => {
                                                setCompetence(
                                                    moment(competence).subtract(1, 'months').format('YYYY-MM')
                                                );
                                            }}
                                        >
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </button>

                                        <button
                                            className="btn btn-sm btn-circle"
                                            onClick={() => {
                                                setCompetence(moment(competence).add(1, 'months').format('YYYY-MM'));
                                            }}
                                        >
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>

                                <DashboardHeader />

                                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6 mt-8 md:mt-0">
                                    <div className="w-full">
                                        <ChatsConclusoesMes />
                                    </div>
                                    <div className="w-full">
                                        <ChatsFaturamentoMes />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card w-full bg-base-100 shadow-md hover:shadow-lg mb-4 border border-gray-200 md:px-5 pt-5">
                            <div className="card-body" style={{ padding: '5px' }}>
                                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6">
                                    <div className="w-full">
                                        <ChartVisaoGeral />
                                    </div>
                                    <div className="w-full">
                                        <ChatsStatus />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
