import { useEffect, useState } from 'react';
import CarouselImgs from '../../../../components/files/CarouselImg';
import { formatToReal } from '../../../../components/utils';
import { DurationService } from '../../../servicos/list/Duration';
import { Loading } from '../../../../components/Loading';
import { ServicosService } from '../../../servicos/Service';
import { useFormHorarioClienteContext } from './Context';

const service = new ServicosService();

export const SelectServico = () => {
    const [loading, setLoading] = useState(false);
    const [servicos, setServicos] = useState([] as any);
    const { estab, servico, setServico } = useFormHorarioClienteContext();

    const loadServicos = async () => {
        try {
            setLoading(true);
            const { data } = await service.getByEstabId(estab._id);

            setServicos(data);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!!estab?._id) loadServicos();
    }, [estab]);

    if (!!loading)
        return (
            <div className="flex justify-center pt-10">
                <Loading />
            </div>
        );

    return (
        <div className={`flex flex-wrap gap-4`}>
            {!!estab?._id &&
                servicos.map((e: any, i: any) => (
                    <div
                        key={i}
                        className={`w-96 animate__animated ${!!servico?._id ? 'hidden' : 'animate__fadeInLeft animate__faster'}`}
                    >
                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl">
                            {!!e?.imagesIds?.length && (
                                <div className="w-full h-44">
                                    <CarouselImgs imagesIds={e.imagesIds} autoPlay />
                                </div>
                            )}

                            <div className="cursor-pointer" onClick={() => setServico(e)}>
                                <div className="card-body">
                                    <h2 className="card-title justify-center">{e?.name}</h2>
                                    <h4 className="text-xs text-center">{e?.description}</h4>
                                </div>
                                <div className="stats" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="stat">
                                        <div className="stat-title">Duração</div>
                                        <div className="stat-value">
                                            <DurationService duration={e.duration} />
                                        </div>
                                    </div>

                                    <div className="stat">
                                        <div className="stat-title">Valor</div>
                                        <div className="stat-value" style={{ fontSize: '2rem' }}>
                                            R$ {formatToReal(e.price)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
