import { useState } from 'react';
import { useParamsContext } from '../../contexts/ParamsContext';
import { UsuariosSistemaService } from './Service';
import { useAppContext } from '../../contexts/AppContext';
import { useUsersContext } from './Context';
const service = new UsuariosSistemaService();

export default function UsuariosSistemaDelete() {
    const { fetchUsers } = useUsersContext();

    const [loading, setLoading] = useState(false);
    const { id, clear } = useParamsContext();
    const { errorSnackbar } = useAppContext();

    return (
        <div>
            <div role="alert" className="alert">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-info shrink-0 w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                </svg>
                <span>Tem certeza que deseja remover o usuário?</span>
                <div>
                    <button disabled={loading} className="btn btn-sm mr-2" onClick={() => clear()}>
                        Cancelar
                    </button>
                    <button
                        disabled={loading}
                        className="btn btn-sm btn-success"
                        onClick={async () => {
                            setLoading(true);
                            try {
                                await service.delete(id);
                                await fetchUsers();
                            } catch (e) {
                                errorSnackbar('Ocorreu um erro ao remover o usuário.');
                            } finally {
                                clear();
                            }
                        }}
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    );
}
