import { useParamsContext } from '../../contexts/ParamsContext';
import ServicosDelete from './Delete';
import ServicosForm from './form/Form';
import ServicosList from './list/List';

export default function Servicos() {
    const { action } = useParamsContext();

    switch (action) {
        case 'edit':
        case 'add': {
            return <ServicosForm />;
        }
        case 'delete':
            return <ServicosDelete />;
        case 'list':
        default:
            return <ServicosList />;
    }
}
