import { useEstabsContext } from '../../../contexts/EstabsContext';
import { useParamsContext } from '../../../contexts/ParamsContext';
import { CardEstab } from '../componentes/CardEstab';
import { UserClientEstabAdd } from './AddFirst';

export default function EstabelecimentosClient() {
    const { action } = useParamsContext();
    const { estabsUser } = useEstabsContext();

    switch (action) {
        case 'add':
            return <UserClientEstabAdd />;

        default:
            return (
                <div className="flex flex-wrap gap-4">
                    {estabsUser.filter(e => e.type === 'client').map((e, i) => (
                        <div key={i} className="w-80">
                            <CardEstab estabId={e._id} />
                        </div>
                    ))}
                </div>
            );
    }
}
