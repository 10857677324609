import { FormAgendamentoConfirmacao } from './Confirmacao';
import { AgendaFormHeader } from './Header';
import { SelectEstab } from './SelectEstab';
import { SelectHorario } from './SelectHorario';
import { SelectServico } from './SelectServico';

export const AgendaForm = () => {
    return (
        <div className="w-full relative">
            <AgendaFormHeader />
            <SelectEstab />
            <SelectServico />
            <SelectHorario />
            <FormAgendamentoConfirmacao />
        </div>
    );
};
