import { LandingPageProvider, useLandingPageContext } from './Context';
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Hero } from './components/Hero';
import { Services } from './components/Services';
import { Gallery } from './components/Gallery';
import { SocialMedia } from './components/SocialMedia';
import { Contact } from './components/Contact';
import { About } from './components/About';
import { motion } from 'framer-motion';
import moment from 'moment';

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const checkScrollPosition = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollPosition);

        return () => {
            window.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return isVisible ? (
        <motion.button
            onClick={scrollToTop}
            className="fixed bottom-6 right-6 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all transform hover:scale-105"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            aria-label="Voltar ao topo"
        >
            <i className="fas fa-arrow-up w-5 h-5"></i>
        </motion.button>
    ) : null;
};

const Page = () => {
    const navigate = useNavigate();
    const { loading, estab } = useLandingPageContext();

    useEffect(() => {
        if (!loading && !estab?._id) {
            navigate('/');
        }

        if (!!estab?._id) {
            document.title = estab.title + (estab.description ? ` - ${estab.description}` : '');

            const favicon =
                (document.querySelector("link[rel='icon']") as HTMLLinkElement) ||
                (document.createElement('link') as HTMLLinkElement);
            favicon.rel = 'icon';
            favicon.href = estab.icon || '';
            document.head.appendChild(favicon);
        }
    }, [loading, estab]);

    if (loading) {
        return <div></div>;
    }

    if (estab) {
        return (
            <div>
                <div className="bg-gradient-to-r from-neutral-900 via-gray-800 to-neutral-700">
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Navbar />

                        <Hero />

                        {estab?.about && (
                            <motion.div
                                initial={{ opacity: 0, x: -50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true, amount: 0.5 }}
                            >
                                <About />
                            </motion.div>
                        )}

                        {!!estab?.services?.length && (
                            <motion.div
                                initial={{ opacity: 0, x: -50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true, amount: 0.5 }}
                            >
                                <Services />
                            </motion.div>
                        )}

                        {!!estab?.images?.length && (
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true, amount: 0.5 }}
                            >
                                <Gallery />
                            </motion.div>
                        )}

                        {!!estab?.socialLinks?.length && (
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true, amount: 0.5 }}
                            >
                                <SocialMedia />
                            </motion.div>
                        )}

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true, amount: 0.5 }}
                        >
                            <Contact />
                        </motion.div>

                        {/* Adicionando o botão de voltar ao topo */}
                        <BackToTopButton />
                    </motion.div>
                </div>

                <footer className="footer footer-center p-4 text-gray-400 bg-gradient-to-r from-black via-gray-900 to-black">
                    <div>
                        <p>
                            Copyright © {moment().format('YYYY')} - Todos os direitos reservados {estab.estabName}
                        </p>
                    </div>
                </footer>
            </div>
        );
    }

    return null;
};

export const LandingPage = ({ brandingEstabId }: { brandingEstabId?: string }) => {
    const { pageName } = useParams<{ pageName?: string }>();

    return (
        <LandingPageProvider brandingEstabId={brandingEstabId} pageName={pageName}>
            <Page />
        </LandingPageProvider>
    );
};
