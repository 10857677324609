import { useAgendaSettingsContext } from './Context';

export default function AgendaSettingsGeral() {
    const {
        daysOpen,
        setDaysOpen,
        submit,
        loading,
        requireApproval,
        setRequireApproval,
        requireConfirmation,
        setRequireConfirmation,
        hoursBeforeConfirmation,
        setHoursBeforeConfirmation,
        sendWhatsapp,
        setSendWhatsapp,
        sendEmail,
        setSendEmail,
    } = useAgendaSettingsContext();

    return (
        <div>
            <div className="form-control mb-4">
                <label className="label" htmlFor="daysOpen">
                    <span className="label-text">Dias que a agenda ficará aberta</span>
                </label>
                <input
                    type="number"
                    value={daysOpen}
                    onChange={(e) => setDaysOpen(parseInt(e.target.value))}
                    id="daysOpen"
                    className="input input-bordered"
                    min="1"
                />
                <span className="text-sm text-gray-500 mb-2">
                    Defina o número de dias que a agenda estará disponível para agendamento antecipado pelos clientes.
                </span>
            </div>

            <div className="divider" />

            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Aprovação do Atendente Necessária?</span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        checked={requireApproval}
                        onChange={(e) => setRequireApproval(e.target.checked)}
                    />
                </label>
                <span className="text-sm text-gray-500 mb-2">
                    Se habilitado, os agendamentos feitos pelo cliente precisarão ser aprovados manualmente pelo
                    atendente antes da confirmação final.
                </span>
            </div>

            <div className="divider" />

            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Solicitar Confirmação de Agendamento?</span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        checked={requireConfirmation}
                        onChange={(e) => setRequireConfirmation(e.target.checked)}
                    />
                </label>
                <span className="text-sm text-gray-500 mb-2">
                    Quando ativado, o cliente será solicitado a confirmar o agendamento. Essa confirmação pode ajudar a
                    reduzir cancelamentos de última hora.
                </span>
            </div>

            {requireConfirmation && (
                <div className="form-control mb-4">
                    <label className="label" htmlFor="hoursBeforeConfirmation">
                        <span className="label-text">Tempo antes do agendamento para confirmação (em horas)</span>
                    </label>
                    <input
                        type="number"
                        value={hoursBeforeConfirmation}
                        onChange={(e) => setHoursBeforeConfirmation(parseInt(e.target.value))}
                        id="hoursBeforeConfirmation"
                        className="input input-bordered"
                        min="1"
                    />
                    <span className="text-sm text-gray-500 mb-2">
                        Especifique o número de horas antes do horário agendado em que o cliente será solicitado a
                        confirmar o agendamento.
                    </span>
                </div>
            )}

            {requireConfirmation && (
                <div className="form-control mb-4">
                    <label className="cursor-pointer label">
                        <span className="label-text">Enviar Confirmação via WhatsApp?</span>
                        <input
                            type="checkbox"
                            className="toggle toggle-primary"
                            checked={sendWhatsapp}
                            onChange={(e) => setSendWhatsapp(e.target.checked)}
                        />
                    </label>
                    <span className="text-sm text-gray-500 mb-2">
                        Se ativado, uma mensagem será enviada pelo WhatsApp solicitando a confirmação do agendamento por
                        parte do cliente.
                    </span>
                </div>
            )}

            {requireConfirmation && (
                <div className="form-control mb-4">
                    <label className="cursor-pointer label">
                        <span className="label-text">Enviar Confirmação via Email?</span>
                        <input
                            type="checkbox"
                            className="toggle toggle-primary"
                            checked={sendEmail}
                            onChange={(e) => setSendEmail(e.target.checked)}
                        />
                    </label>
                    <span className="text-sm text-gray-500 mb-2">
                        Se ativado, uma mensagem será enviada pelo Email solicitando a confirmação do agendamento por
                        parte do cliente.
                    </span>
                </div>
            )}

            <div className="divider" />

            <button onClick={submit} disabled={loading} className="btn shadow-md" type="submit">
                Salvar Alterações
            </button>
        </div>
    );
}
