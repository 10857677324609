import React, {createContext, useContext, ReactNode, useState, Dispatch, SetStateAction, useEffect} from 'react';
import { UserService } from '../../modules/configuracoes/usuario/Service';
import { useAppContext } from '../../contexts/AppContext';
import {useLocation} from "react-router-dom";

const service = new UserService();

interface CreateAccountContextProps {
    typeAccount: number;
    setTypeAccount: (type: number) => void;
    loading: boolean;
    setLoading: (v: boolean) => void;
    setCreateAccount: Dispatch<SetStateAction<boolean>>;
    handleSubmitAccountClient: (doc: { name: string; email: string }) => void;
    handleSubmitAccountEstab: (doc: { estabName: string; userName: string; userEmail: string }) => void;
    returnAction: () => void;
}

const CreateAccountContext = createContext<CreateAccountContextProps | undefined>(undefined);

const CreateAccountProvider: React.FC<{
    children: ReactNode;
    setCreateAccount: Dispatch<SetStateAction<boolean>>;
    estabId?: string;
}> = ({ children, setCreateAccount, estabId }) => {
    const location = useLocation();

    const [typeAccount, setTypeAccount] = useState(1);
    const [loading, setLoading] = useState(false);
    const { errorSnackbar, successSnackbar } = useAppContext();

    const handleSubmitAccountClient = async ({ name, email }: { name: string; email: string }) => {
        try {
            setLoading(true);
            await service.create({ name, email, estabId });
            successSnackbar('Acesse seu email para confirmar sua conta!');
            setCreateAccount(false);
        } catch (error: any) {
            errorSnackbar(error?.response?.data?.message || 'Ocorreu um erro ao criar a conta.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitAccountEstab = async ({
        estabName,
        userName,
        userEmail,
    }: {
        estabName: string;
        userName: string;
        userEmail: string;
    }) => {
        try {
            setLoading(true);
            await service.create({ name: userName, email: userEmail });
            successSnackbar('Acesse seu email para confirmar sua conta!');
            setCreateAccount(false);
        } catch (error: any) {
            errorSnackbar(error?.response?.data?.message || 'Ocorreu um erro ao criar a conta.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const returnAction = () => {
        if (!!typeAccount) {
            setTypeAccount(0);
        } else {
            setCreateAccount(false);
        }

        setCreateAccount(false);
    };

    useEffect(() => {

        // Acessando o estado
        const { estabId } = location.state || {};

        console.log(estabId)
    }, []);

    return (
        <CreateAccountContext.Provider
            value={{
                typeAccount,
                setTypeAccount,
                setCreateAccount,
                loading,
                setLoading,
                handleSubmitAccountClient,
                returnAction,
                handleSubmitAccountEstab,
            }}
        >
            {children}
        </CreateAccountContext.Provider>
    );
};

const useCreateAccountContext = () => {
    const context = useContext(CreateAccountContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

export { CreateAccountProvider, useCreateAccountContext };
