import { api } from '../../AxiosConfig';

class UsuariosSistemaService {
    create(data: any) {
        return api.post('/users/root/create', data);
    }

    getAll() {
        return api.get('/users/root/getAll');
    }

    disable(uid: string) {
        return api.post('/users/root/disable', { uid });
    }

    active(uid: string) {
        return api.post('/users/root/active', { uid });
    }

    delete(uid: string) {
        return api.post('/users/root/delete', { uid });
    }
}

export { UsuariosSistemaService };
