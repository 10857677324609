import { useState } from 'react';
import { AusenciasService } from './Service';
import { useAusenciasContext } from './Context';
import { useAppContext } from '../../contexts/AppContext';
const service = new AusenciasService();

export default function AusenciasDelete({
    remove,
    setRemove,
}: {
    remove: any;
    setRemove: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [loading, setLoading] = useState(false);
    const { loadAusencias } = useAusenciasContext();
    const { successSnackbar, errorSnackbar } = useAppContext();

    return (
        <div>
            <div role="alert" className="alert">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-info shrink-0 w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                </svg>
                <span>Tem certeza que deseja remover a ausência?</span>
                <div>
                    <button disabled={loading} className="btn btn-sm mr-2" onClick={() => setRemove(false)}>
                        Cancelar
                    </button>
                    <button
                        disabled={loading}
                        className="btn btn-sm btn-success"
                        onClick={async () => {
                            setLoading(true);
                            try {
                                await service.delete(remove._id);
                                loadAusencias();
                                successSnackbar('Ausência removida com sucesso!');
                                setRemove(false);
                            } catch (e) {
                                errorSnackbar('Ocorreu um erro ao remover a ausência.');
                            } finally {
                                setLoading(false);
                            }
                        }}
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    );
}
