import { ServicosService } from '../Service';
import { useEffect, useState } from 'react';
import MultiSelect from '../../../components/inputs/MultiSelect';

const service = new ServicosService();

export const ListServices = ({ value, setValue }: { value: string[]; setValue: (value: string[]) => void }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

    const loadOptions = async () => {
        try {
            setLoading(true);

            await service.findAll().then(({ data }) => {
                setOptions(data?.map(({ _id, name }: { _id: string; name: string }) => ({ value: _id, label: name })));
            });
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadOptions();
    }, []);

    return (
        <MultiSelect
            options={options}
            loading={loading}
            value={value}
            onChange={setValue}
            placeholder="Selecione os serviços"
            label="Serviços"
            name={'servicos'}
        />
    );
};
