import moment from 'moment';
import CarouselImgs from '../../../../components/files/CarouselImg';
import { Modal } from '../../../../components/Modal';
import ScheduleView from '../view/ScheduleView';
import { useEffect, useState } from 'react';
import { useParamsContext } from '../../../../contexts/ParamsContext';
import { Agendamento, StatusHorario } from '../../Enum';
import { CancelModal } from './CancelHorario';
import { useAgendaClienteContext } from '../Context';
import { ConfirmHorario } from './ConfirmHorario';
import ScheduleStatus from '../../estabelecimento/components/ScheduleStatus';
import { AgendaClienteService } from '../Service';

const horariosService = new AgendaClienteService();

export const CardAgendamento: React.FC<{ agendamento: Agendamento }> = ({ agendamento }) => {
    const { loadAgendamentos } = useAgendaClienteContext();
    const [newAg, setNewAg] = useState(false);
    const [open, setOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const { action, id } = useParamsContext();

    useEffect(() => {
        if (action === 'new' && agendamento._id === id) {
            setNewAg(true);
        }
    }, [action, id, agendamento._id]);

    const { startAt, endAt, service, estab, attendant, status, confirmation, cancellation } = agendamento;

    const handleCancel = async () => {
        try {
            await horariosService.cancel(agendamento._id);
            loadAgendamentos();
        } catch (error) {
            console.error('Error cancelling agendamento:', error);
        } finally {
            setIsCancelModalOpen(false);
        }
    };

    const handleConfirm = async () => {
        try {
            await horariosService.confirm(agendamento._id);
            loadAgendamentos();
        } catch (error) {
            console.error('Error confirm agendamento:', error);
        } finally {
            setIsConfirmModalOpen(false);
        }
    };

    // Lógica para verificação de confirmação
    const currentTime = moment();
    const confirmationDeadline = moment(startAt).subtract(confirmation?.hoursBeforeConfirmation || 0, 'hours');
    const shouldRequestConfirmation =
        [StatusHorario.PENDENTE, StatusHorario.AGENDADO].includes(status) &&
        !confirmation?.date &&
        confirmation?.required &&
        confirmation?.hoursBeforeConfirmation &&
        currentTime.isAfter(confirmationDeadline);

    return (
        <div>
            <div
                className={`card bg-base-100 w-full max-w-md shadow-lg mb-4 hover:shadow-xl ${
                    newAg ? 'animate__animated animate__tada' : ''
                }`}
            >
                <div className="card-body">
                    <div className="cursor-pointer" onClick={() => setOpen(true)}>
                        <h2 className="card-title">{moment(startAt).format('dddd, D [de] MMMM [de] YYYY')}</h2>
                        <p className="text-gray-600 mb-1">
                            {moment(startAt).format('HH:mm')} - {moment(endAt).format('HH:mm')}
                        </p>
                        <ScheduleStatus
                            clientId={agendamento.client.uid}
                            key={agendamento._id}
                            id={agendamento._id}
                            status={status}
                            size="xs"
                        />

                        {service?.imagesIds?.length > 0 && (
                            <div className="w-full h-44 my-4">
                                <CarouselImgs imagesIds={service.imagesIds} autoPlay />
                            </div>
                        )}

                        <p className="font-semibold text-lg">{service?.name}</p>
                        <p className="text-xs text-gray-600">
                            Atendente: <span className="font-medium">{attendant?.name}</span>
                        </p>
                        <p className="text-xs text-gray-600">
                            Estabelecimento: <span className="font-medium">{estab.name}</span>
                        </p>

                        {confirmation?.date && (
                            <div className="text-xs text-gray-600 flex items-center gap-1">
                                <i className="fa-solid fa-calendar-check text-green-900"></i>
                                Confirmado <span className="font-medium">{moment(confirmation?.date).fromNow()}</span>
                            </div>
                        )}

                        {cancellation?.date && (
                            <div className="text-xs text-gray-600 flex items-center gap-1">
                                <i className="fa-solid fa-calendar-xmark text-red-700"></i>
                                Cancelado <span className="font-medium">{moment(cancellation?.date).fromNow()}</span>
                            </div>
                        )}
                    </div>

                    {(shouldRequestConfirmation ||
                        ![StatusHorario.CANCELADO, StatusHorario.CONCLUIDO].includes(status)) && (
                        <div className="divider" style={{ padding: 0, margin: 0 }}></div>
                    )}

                    <div className="flex justify-between items-center">
                        {shouldRequestConfirmation && (
                            <button onClick={() => setIsConfirmModalOpen(true)} className="btn btn-sm">
                                Confirmar
                            </button>
                        )}
                        {![StatusHorario.CANCELADO, StatusHorario.CONCLUIDO].includes(status) && (
                            <button onClick={() => setIsCancelModalOpen(true)} className="btn btn-sm">
                                Cancelar
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {open && (
                <Modal onClose={() => setOpen(false)}>
                    <ScheduleView schedule={agendamento} />
                </Modal>
            )}

            {isCancelModalOpen && (
                <Modal onClose={() => setIsCancelModalOpen(false)}>
                    <CancelModal onConfirm={handleCancel} onCancel={() => setIsCancelModalOpen(false)} />
                </Modal>
            )}

            {isConfirmModalOpen && (
                <Modal onClose={() => setIsConfirmModalOpen(false)}>
                    <ConfirmHorario onConfirm={handleConfirm} onCancel={() => setIsConfirmModalOpen(false)} />
                </Modal>
            )}
        </div>
    );
};
