import { UserService } from './Service';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import {Loading} from "../../../components/Loading";

const service = new UserService();

export default function ConfiguracoesContaIdioma() {
    const { errorSnackbar } = useAppContext();
    const [loading, setLoading] = useState(false);
    const [idioma, setIdioma] = useState('pt-BR');

    useEffect(() => {
        service
            .getSettings()
            .then(({ data }) => {
                if (data?.preferredLanguage) {
                    setIdioma(data.preferredLanguage);
                }
            })
            .catch(() => {
                errorSnackbar('Erro ao buscar configurações do usuário.');
            })
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        service
            .updateSettings({ preferredLanguage: idioma })
            .catch(() => {
                errorSnackbar('Erro ao salvar configurações.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setIdioma(e.target.value);
    };

    return (
        <form className="space-y-6 w-full" onSubmit={handleSubmit}>
            <div className="form-control mb-4">
                <label className="label">
                    <span className="label-text">Idioma preferido:</span>
                </label>
                <select
                    className="select select-primary w-full max-w-96"
                    name="preferredLanguage"
                    value={idioma}
                    onChange={handleChange}
                    disabled={loading}
                >
                    <option value="pt-BR">Português (Brasil)</option>
                    <option value="en-US">Inglês (EUA)</option>
                    <option value="es-ES">Espanhol</option>
                </select>
            </div>

            <div className="divider" />

            <button className={`btn shadow-md ${loading ? 'btn-disabled' : ''}`} type="submit" disabled={loading}>
                {loading ? <Loading/> : 'Salvar'}
            </button>
        </form>
    );
}
