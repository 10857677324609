import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SelectAtendentes from '../../components/inputs/SelectAtendentes';
import { AusenciasService } from './Service';
import { useAppContext } from '../../contexts/AppContext';
import {initialHorarios, useAusenciasContext} from './Context';
import { dayLabels, minutesToTime } from '../agenda/Enum';

const service = new AusenciasService();

export default function AgendaSettingsAusenciasForm({
    showForm,
    setShowForm,
}: {
    showForm: any;
    setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [showWeek, setShowWeek] = useState(false);
    const [loading, setLoading] = useState(false);
    const { errorSnackbar, successSnackbar } = useAppContext();
    const { loadAusencias, horarios, handleAddSlot, handleSlotChange, handleRemoveSlot, setHorarios } =
        useAusenciasContext();

    const [motivo, setMotivo] = useState<string>('');
    const [end, setEnd] = useState<moment.Moment | null>(null);
    const [start, setStart] = useState<moment.Moment | null>(null);
    const [atendentesIds, setAtendentesIds] = useState<string[]>([]);

    useEffect(() => {
        if (showForm?._id) {
            setLoading(true);
            service.findOne(showForm._id).then((response) => {
                const doc = response.data || {};

                if (Object.values(doc.horarios).some((e: any) => e.length) && !!doc.horarios) {
                    delete doc.horarios._id;
                    setHorarios(doc.horarios);
                    setShowWeek(true);
                } else {
                    setHorarios(initialHorarios);
                }

                if (doc.start) {
                    setStart(moment(doc.start));
                } else {
                    setStart(null);
                }

                if (doc.end) {
                    setEnd(moment(doc.end));
                } else {
                    setEnd(null);
                }

                if (doc?.atendentesIds?.length) {
                    setAtendentesIds(doc.atendentesIds);
                }

                if (doc?.motivo) {
                    setMotivo(doc.motivo);
                }

                setLoading(false);
            });
        }
    }, [showForm]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const formState: any = {
            atendentesIds,
            motivo,
        };

        if (!!start) formState.start = start.toDate();
        if (!!end) formState.end = end.toDate();
        if (showWeek) formState.horarios = horarios;

        try {
            setLoading(true);

            if (showForm?._id) {
                await service.update(showForm?._id, formState);
            } else {
                await service.create(formState);
            }

            successSnackbar(`Ausência ${showForm?.id ? 'editada' : 'criada'} com sucesso!`);
            setShowForm(false);
            loadAusencias();
        } catch (error) {
            errorSnackbar(`Ocorreu um erro ao ${showForm?.id ? 'editar' : 'criar'} a ausência.`);
        } finally {
            setLoading(false);
        }
    };

    const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value ? moment(e.target.value).local() : null;
        setStart(date);
    };

    const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value ? moment(e.target.value).local() : null;
        setEnd(date);
    };

    return (
        <div className={showForm ? 'block' : 'hidden'}>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Início</span>
                    </label>
                    <input
                        type="date"
                        name="start"
                        value={start ? start.format('YYYY-MM-DD') : ''}
                        onChange={handleStartChange}
                        required
                        className="input input-bordered"
                    />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Fim</span>
                    </label>
                    <input
                        required
                        type="date"
                        name="end"
                        value={end ? end.format('YYYY-MM-DD') : ''}
                        onChange={handleEndChange}
                        className="input input-bordered"
                    />
                </div>
                <div className="form-control">
                    <SelectAtendentes value={atendentesIds} setValue={setAtendentesIds} name="atendentes" />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Motivo</span>
                    </label>
                    <textarea
                        name="motivo"
                        value={motivo}
                        onChange={(e) => setMotivo(e.target.value)}
                        className="textarea textarea-bordered"
                    />
                </div>

                <div className="border-base-300 bg-white border p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                            <input
                                type="checkbox"
                                checked={showWeek}
                                onChange={(e) => setShowWeek(!showWeek)}
                                className="toggle"
                            />
                            <span className="text-xl font-medium">Horários Semana</span>
                        </div>
                    </div>

                    {showWeek && (
                        <div className="mt-4">
                            {Object.entries(horarios)?.map(([day, horario]) => {
                                return (
                                    <div
                                        key={day}
                                        className={day !== 'domingo' ? 'pb-6 mb-5 border-b border-gray-300' : ''}
                                    >
                                        <h3 className="text-xl font-semibold mb-2">{dayLabels?.[day]}</h3>
                                        {horario?.map((slot, slotIndex) => (
                                            <div key={slotIndex} className="flex items-center gap-4 mb-2">
                                                <input
                                                    type="time"
                                                    value={minutesToTime(slot.start)}
                                                    onChange={(e) =>
                                                        handleSlotChange(day, slotIndex, 'start', e.target.value)
                                                    }
                                                    className="input input-bordered w-28"
                                                />
                                                <input
                                                    type="time"
                                                    value={minutesToTime(slot.end)}
                                                    onChange={(e) =>
                                                        handleSlotChange(day, slotIndex, 'end', e.target.value)
                                                    }
                                                    className="input input-bordered w-28"
                                                />
                                                <button
                                                    type="button"
                                                    disabled={loading}
                                                    onClick={() => handleRemoveSlot(day, slotIndex)}
                                                    className="btn btn-error btn-sm"
                                                >
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            disabled={loading}
                                            onClick={() => handleAddSlot(day)}
                                            className="btn btn-sm"
                                        >
                                            Adicionar Intervalo
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="divider"></div>

                <div className="flex space-x-2">
                    <button disabled={loading} className="btn shadow-md" type="submit">
                        {showForm?._id ? 'Editar' : 'Criar'}
                    </button>
                    <button
                        onClick={() => setShowForm(false)}
                        className="btn shadow-md btn-error"
                        disabled={loading}
                        type="button"
                    >
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    );
}
