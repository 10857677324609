import CustomDataTable from '../../components/table/Table';
import { UserService } from '../configuracoes/usuario/Service';
import React, { useEffect, useState } from 'react';
import AvatarUser from '../../components/AvatarUser';
import { Modal } from '../../components/Modal';
import { Loading } from '../../components/Loading';
import { useClientsContext } from './Context';

const userService = new UserService();

interface DataRow {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
    phoneNumber: string;
    disabled: boolean;
    type: string;
    emailVerified: boolean;
}

export default function ClientsList() {
    const { data, loading, loadClients } = useClientsContext();

    const columns = [
        {
            name: 'Nome',
            selector: (row: DataRow) => row.displayName,
            sortable: true,
            cell: (row: DataRow) => (
                <>
                    <AvatarUser photoURL={row.photoURL} name={row.displayName || row.email} />
                    <div className="ml-4" style={{ textAlign: 'right' }}>
                        {row.displayName}
                    </div>
                </>
            ),
        },
        {
            name: 'Contato',
            selector: (row: DataRow) => row.email,
            sortable: true,
            cell: (row: DataRow) => (
                <div className="grid gap-2">
                    <div className="flex items-center gap-2">
                        {row.emailVerified ? (
                            <div className="tooltip fixed tooltip-bottom" data-tip={'Email verificado'}>
                                <i className="fas fa-check-circle text-green-600" />
                            </div>
                        ) : (
                            <div className="tooltip fixed tooltip-bottom" data-tip={'Email não verificado'}>
                                <i className="fas fa-times-circle text-red-600" />
                            </div>
                        )}

                        <div>{row.email}</div>
                    </div>
                    {!!row.phoneNumber && row.phoneNumber}
                </div>
            ),
        },
        {
            name: 'Ações',
            right: true,
            width: '250px',
            sortable: false,
            cell: (row: DataRow) => <Actions row={row} />,
        },
    ];

    useEffect(() => {
        loadClients();
    }, []);

    return <CustomDataTable loading={loading} columns={columns} data={data} pagination highlightOnHover striped />;
}

const Actions = ({ row }: { row: DataRow }) => {
    const { loadClients } = useClientsContext();
    const [loading, setLoading] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);

    const openWhatsApp = () => {
        if (row.phoneNumber) {
            const url = `https://wa.me/${row.phoneNumber}`;
            window.open(url, '_blank');
        }
    };

    const removeClient = async () => {
        try {
            setLoading(true);
            await userService.removeClient(row.uid);
            loadClients();
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <ul className="menu menu-horizontal rounded-lg p-0">
            {openRemove && (
                <Modal onClose={() => setOpenRemove(false)}>
                    <div className="card bg-base-100 w-96 shadow-xl text-black">
                        <div className="card-body">
                            <h2 className="card-title">Remover Cliente</h2>
                            <p>Deseja realmente remover o cliente?</p>
                            <div className="flex justify-between mt-4">
                                <button
                                    className="btn btn-ghost"
                                    onClick={() => setOpenRemove(false)}
                                    disabled={loading}
                                >
                                    Cancelar
                                </button>
                                <button className="btn btn-secondary" onClick={removeClient} disabled={loading}>
                                    {loading ? <Loading /> : 'Confirmar'}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <li className={!row.phoneNumber ? 'disabled' : ''}>
                <a onClick={openWhatsApp}>
                    <i className="fa-brands fa-whatsapp"></i>
                </a>
            </li>
            <li>
                <a onClick={() => setOpenRemove(true)}>
                    <i className="fa-solid fa-trash"></i>
                </a>
            </li>
        </ul>
    );
};
