import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging/sw';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: 'AIzaSyBnonmiJ4FdZSqaQgBhGw-neCLOTMPX3kc',
    authDomain: 'hemck-salon-flow.firebaseapp.com',
    databaseURL: 'https://hemck-salon-flow-default-rtdb.firebaseio.com',
    projectId: 'hemck-salon-flow',
    storageBucket: 'hemck-salon-flow.appspot.com',
    messagingSenderId: '102587740531',
    appId: '1:102587740531:web:1458c4a5d4def520481506',
    measurementId: 'G-3YT2SQ8GD6',
};

const keyPushNotificationWeb =
    'BFVT02DwaOF6xW76c23qJkdj3cSDqQ_20_l6o0gQ79T3iL2M6W3dXVG3BQwNYYI4ySC4_r8vqoeGuoMcXCqewRM';

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const messaging = getMessaging(firebaseApp);

export { firebaseApp, auth, keyPushNotificationWeb, messaging };
