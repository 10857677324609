import { useNavigate } from 'react-router-dom';
import { useNotificationsContext } from './NotificationsContext';
import { NotificationsService } from './Service';
import moment from 'moment';
import { baseURL } from '../AxiosConfig';

const service = new NotificationsService();

export default function NotificationItem({ notification }: any) {
    const navigate = useNavigate();
    const { setDropdownOpen, loadNotifications } = useNotificationsContext();

    const onClick = () => {
        if (!!notification.path) {
            navigate(notification.path);
            setDropdownOpen(false);
        }
    };

    return (
        <li
            key={notification._id}
            className="mb-3 w-full group/item"
            onClick={() => {
                service.view(notification._id).then(() => {
                    loadNotifications();
                });
            }}
        >
            <div
                className={`flex items-center justify-between w-full ${!notification.visualizedAt && 'bg-gray-100'}`}
                style={{ padding: '3px 3px', display: 'block' }}
            >
                <div className="flex items-center object-center mb-2">
                    <div className="avatar grid justify-center mr-2">
                        <div className="mask mask-squircle w-9">
                            <img src={`${baseURL}/users/profileImage/${notification.createdBy}`} alt={`Perfil`} />
                        </div>
                    </div>
                    <div className="w-full" onClick={onClick}>
                        <div className={`text-sm ${!notification.visualizedAt && 'font-extrabold'}`}>
                            {notification.title}
                        </div>
                        <div className="text-xs">{moment(notification.createdAt).fromNow()}</div>
                    </div>
                </div>

                <div className="flex justify-between">
                    <div
                        className={`text-xs ${!notification.visualizedAt ? 'font-medium' : 'font-light'}`}
                        onClick={onClick}
                    >
                        {notification.message}
                    </div>
                </div>
            </div>
        </li>
    );
}
