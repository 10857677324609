import { HistoryService, HistoryToView } from './Service';
import { useEffect, useState } from 'react';
import CustomDataTable from '../components/table/Table';

const historyService = new HistoryService();

export const Histories = ({ resource, resourceId }: { resource?: string; resourceId?: string }) => {
    const [loading, setLoading] = useState(true);
    const [historys, setHistorys] = useState<HistoryToView[]>([]);

    const loadHistorys = async () => {
        try {
            setLoading(true);

            await historyService
                .find({
                    resource,
                    resourceId,
                })
                .then((response) => {
                    setHistorys(response?.data?.map(historyService.parseHistory));
                });
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadHistorys();
    }, [resource, resourceId]);

    return (
        <CustomDataTable
            columns={[
                {
                    name: 'Histórico',
                    cell: (row: any) => row.message,
                },
                {
                    name: 'Data',
                    sortable: true,
                    cell: (row: any) => new Date(row.createdAt).toLocaleString(),
                },
                {
                    name: 'Usuário',
                    sortable: true,
                    cell: (row: any) => (
                        <div>
                            {row?.user?.name}
                            <div style={{ fontSize: '10px', lineHeight: 'initial' }}>
                                {row?.user?.email}
                                <br />
                                {row?.user?.phoneNumber}
                            </div>
                        </div>
                    ),
                },
            ]}
            data={historys}
            pagination
            paginationPerPage={5}
            loading={loading}
            highlightOnHover
            striped
        />
    );
};
