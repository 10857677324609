import React from 'react';
import { MapPin, Clock, Phone } from 'lucide-react';
import { useLandingPageContext } from '../Context';

export const Contact = () => {
    const { estab } = useLandingPageContext();

    return (
        <section id="contact" className="py-16">
            <div className="container mx-auto px-4">
                {/* Título com divisor */}
                <div className="flex items-center mb-12">
                    <hr className="flex-grow border-t border-gray-700" />
                    <h2 className="text-3xl font-bold text-center mx-4 text-white text-shadow">Contato</h2>
                    <hr className="flex-grow border-t border-gray-700" />
                </div>

                {/* Cards de contato */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Card Endereço */}
                    <div className="relative card bg-black/80 shadow-xl rounded-xl transition-transform transform hover:scale-105">
                        <div className="absolute inset-0 bg-gradient-to-bl from-neutral-900 via-gray-50 to-neutral-700 p-[1px] rounded-xl">
                            <div className="h-full w-full bg-black rounded-xl"></div>
                        </div>
                        <div className="relative z-10 card-body items-center text-center text-white p-6">
                            <MapPin className="w-8 h-8 text-blue-400 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Endereço</h3>
                            <p className="text-gray-300">{estab?.contact?.address}</p>
                            {estab?.contact?.addressUrl && (
                                <a
                                    href={estab.contact.addressUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-400 mt-4 inline-block font-medium hover:underline"
                                >
                                    Ver no mapa
                                </a>
                            )}
                        </div>
                    </div>

                    {/* Card Horário */}
                    <div className="relative card bg-black/80 shadow-xl rounded-xl transition-transform transform hover:scale-105">
                        <div className="absolute inset-0 bg-gradient-to-bl from-neutral-900 via-gray-50 to-neutral-700 p-[1px] rounded-xl">
                            <div className="h-full w-full bg-black rounded-xl"></div>
                        </div>
                        <div className="relative z-10 card-body items-center text-center text-white p-6">
                            <Clock className="w-8 h-8 text-blue-400 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Horário</h3>
                            <p className="text-gray-300">{estab?.contact?.hours}</p>
                        </div>
                    </div>

                    {/* Card Telefone / WhatsApp */}
                    <div className="relative card bg-black/80 shadow-xl rounded-xl transition-transform transform hover:scale-105">
                        <div className="absolute inset-0 bg-gradient-to-bl from-neutral-900 via-gray-50 to-neutral-700 p-[1px] rounded-xl">
                            <div className="h-full w-full bg-black rounded-xl"></div>
                        </div>
                        <div className="relative z-10 card-body items-center text-center text-white p-6">
                            <Phone className="w-8 h-8 text-blue-400 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Telefone</h3>
                            <p className="text-gray-300">{estab?.contact?.phone}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
