import React from 'react';
import { useLandingPageContext } from '../Context';

export const Services = () => {
    const { estab } = useLandingPageContext();

    return (
        <section id="services" className="py-16 ">
            <div className="container mx-auto px-4">
                <div className="flex items-center mb-8">
                    <hr className="flex-grow border-t border-gray-700" />
                    <h2 className="text-3xl font-bold text-center mx-4 text-white text-shadow">Nossos Serviços</h2>
                    <hr className="flex-grow border-t border-gray-700" />
                </div>
                <div className="flex flex-wrap justify-center gap-8">
                    {estab?.services?.map((service: any, index: any) => (
                        <div
                            key={index}
                            className="card relative bg-black/80 text-gray-200 backdrop-blur-lg border border-transparent
    transition-transform transform hover:scale-105 max-w-96 w-full shadow-lg rounded-xl"
                        >
                            <div className="absolute inset-0 rounded-xl bg-gradient-to-bl from-neutral-900 via-gray-50 to-neutral-700 p-[1px]">
                                <div className="h-full w-full bg-black/90 rounded-xl"></div>
                            </div>

                            <div className="relative z-10 card-body flex flex-col items-center text-center p-6">
                                <h3 className="card-title text-white font-semibold text-lg mb-2">{service.title}</h3>
                                <p className="text-gray-400 font-light text-sm mb-1">{service.duration} minutos</p>
                                <p className="text-gray-300 font-light mb-3">{service.description}</p>
                                <div className="text-2xl font-bold text-white mt-auto">
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(service.price)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
