export default function PageNotFound() {
    return (
        <div className="flex items-center justify-center bg-white pt-4">
            <div className="flex flex-col">
                <div className="flex flex-col items-center">
                    <div className="text-orange-500 font-bold text-5xl">404</div>

                    <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
                        Esta página não existe
                    </div>

                    <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
                        A página que você procura não foi encontrada.
                    </div>
                </div>
            </div>
        </div>
    );
}
