import { useAgendaSettingsContext } from './Context';
import {dayLabels, minutesToTime, timeToMinutes} from "../../Enum";

export default function AgendaSettingsHorarios() {
    const { horarios, setHorarios, submit, loading } = useAgendaSettingsContext();

    const handleAddSlot = (day: string) => {
        const newHorarios = { ...horarios };
        newHorarios[day].push({ start: 0, end: 0 });
        setHorarios(newHorarios);
    };

    const handleSlotChange = (day: string, slotIndex: number, field: 'start' | 'end', value: string) => {
        const newHorarios = { ...horarios };
        newHorarios[day][slotIndex][field] = timeToMinutes(value);
        setHorarios(newHorarios);
    };

    const handleRemoveSlot = (day: string, slotIndex: number) => {
        const newHorarios = { ...horarios };
        newHorarios[day].splice(slotIndex, 1);
        setHorarios(newHorarios);
    };

    return (
        <div className="p-4">
            <p className="mb-4 text-gray-700">
                Aqui você pode configurar os horários de atendimento para cada dia da semana. Para cada dia, adicione
                intervalos de horário especificando o início e o fim. Clique em "Adicionar Intervalo" para incluir novos
                horários e "Remover" para excluí-los.
            </p>

            <div className="divider"></div>

            {Object.entries(horarios)?.map(([day, horario]) => {
                return (
                    <div key={day} className={day !== 'domingo' ? 'pb-6 mb-5 border-b border-gray-300' : ''}>
                        <h3 className="text-xl font-semibold mb-2">{dayLabels?.[day]}</h3>
                        {horario?.map((slot, slotIndex) => (
                            <div key={slotIndex} className="flex items-center gap-4 mb-2">
                                <input
                                    type="time"
                                    value={minutesToTime(slot.start)}
                                    onChange={(e) => handleSlotChange(day, slotIndex, 'start', e.target.value)}
                                    className="input input-bordered w-28"
                                />
                                <input
                                    type="time"
                                    value={minutesToTime(slot.end)}
                                    onChange={(e) => handleSlotChange(day, slotIndex, 'end', e.target.value)}
                                    className="input input-bordered w-28"
                                />
                                <button
                                    type="button"
                                    disabled={loading}
                                    onClick={() => handleRemoveSlot(day, slotIndex)}
                                    className="btn btn-error btn-sm"
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            disabled={loading}
                            onClick={() => handleAddSlot(day)}
                            className="btn btn-sm"
                        >
                            Adicionar Intervalo
                        </button>
                    </div>
                );
            })}

            <div className="divider" />

            <button onClick={submit} disabled={loading} className="btn shadow-md" type="submit">
                Salvar Alterações
            </button>
        </div>
    );
}
