import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLandingPageContext } from '../Context';
import { motion, AnimatePresence } from 'framer-motion';

export const Hero = () => {
    const navigate = useNavigate();
    const { estab } = useLandingPageContext();
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % estab?.imagesHeader?.length);
        }, 9000);

        return () => clearInterval(interval);
    }, [estab?.imagesHeader?.length]);

    const generateRandomTransform = () => ({
        x: Math.random() * 40 - 20,
        y: Math.random() * 40 - 20,
        scale: 1 + Math.random() * 0.2,
    });

    return estab.imagesHeader?.length ? (
        <div className="hero min-h-[70vh] shadow-2xl relative overflow-hidden">
            <div className="absolute w-full h-full overflow-hidden">
                <AnimatePresence mode="sync">
                    <motion.div
                        key={estab?.imagesHeader?.[currentIndex]}
                        initial={{ opacity: 0, scale: 1.2 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 1 }}
                        transition={{
                            duration: 0.6,
                            ease: 'linear',
                            delay: 1,
                        }}
                        className="absolute w-full h-full"
                    >
                        <motion.div
                            className="w-full h-full"
                            initial={generateRandomTransform()}
                            animate={generateRandomTransform()}
                            transition={{
                                duration: 4,
                                ease: 'easeInOut',
                                repeat: Infinity,
                                repeatType: 'reverse',
                            }}
                        >
                            <img
                                src={estab?.imagesHeader?.[currentIndex]}
                                alt="header"
                                className="w-full h-full object-cover"
                            />
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            </div>

            <motion.div
                className="absolute inset-0 bg-black/30 backdrop-blur-md p-8 grid place-content-center text-center space-y-6 z-40 h-min my-auto"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                style={{
                    boxShadow: '#000000b5 0 15px 40px',
                }}
            >
                <h2 className="text-5xl font-bold text-white text-shadow">{estab?.title}</h2>
                <p className="text-gray-300 text-shadow">{estab?.description}</p>
                <button
                    onClick={() => navigate("/login", { state: { estabId: estab?._id } })}
                    className="btn bg-black flex items-center gap-2 shadow-2xl shadow-black"
                >
                    Agende seu Horário
                </button>
            </motion.div>
        </div>
    ) : (
        <div className="text-center text-white py-5 pt-24">
            <h1 className="mb-5 text-5xl font-bold text-white text-shadow">{estab?.title}</h1>
            {estab?.description && <p className="mb-5 text-gray-300 text-shadow">{estab?.description}</p>}

            <button
                onClick={() => navigate('/login')}
                className="btn btn-primary flex items-center gap-2 shadow-2xl shadow-black"
            >
                Agende seu Horário
            </button>
        </div>
    );
};
