import React, { useEffect, useState } from 'react';
import { useParamsContext } from '../../../contexts/ParamsContext';
import { PlanosService } from './Service';
import { useAppContext } from '../../../contexts/AppContext';
import { usePlanosContext } from './Context';

const service = new PlanosService();

export default function PlanosForm() {
    const { clear, id } = useParamsContext();
    const { data, reloadData } = usePlanosContext();
    const { successSnackbar, errorSnackbar } = useAppContext();
    const [disabledButtuon, setDisabledButton] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        const doc: any = data?.find((e) => e._id === id);

        if (!!doc) {
            setName(doc.name);
            setDescription(doc.description);
        }
    }, [id, data]);

    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        setDisabledButton(true);
        event.preventDefault();

        try {
            const plano: any = { name };

            if (!!description) {
                plano.description = description;
            }

            if (!!id) {
                await service.update(id, plano);
                setDisabledButton(false);
                successSnackbar('Plano editado com sucesso!');
            } else {
                const result = await service.create(plano);
                if (result) {
                    setDisabledButton(false);
                    successSnackbar('Plano criado com sucesso!');
                }
            }

            reloadData();
        } catch (e) {
            console.log(e);
            errorSnackbar('Ocorreu um erro ao criar o plano.');
        } finally {
            clear();
        }
    };

    return (
        <div>
            <form onSubmit={submit}>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Nome</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Nome do Plano"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Descrição</span>
                    </div>
                    <textarea
                        className="textarea textarea-bordered"
                        placeholder="Descrição do Plano"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </label>

                <div className="divider"></div>

                <button className="btn shadow-md" disabled={disabledButtuon} type="submit">
                    {!!id ? 'Editar' : 'Adicionar'}
                </button>
            </form>
        </div>
    );
}
