import moment from 'moment';
import { useParamsContext } from '../../../contexts/ParamsContext';
import ScheduleStatus from './components/ScheduleStatus';
import { Agendamento } from '../Enum';

moment.locale('pt-br');

interface ScheduleItemProps {
    schedule: Agendamento;
}

export default function ScheduleItem({ schedule }: ScheduleItemProps) {
    const { setParams } = useParamsContext();

    const start = moment(schedule.startAt);
    const end = moment(schedule.endAt);
    const isCurrentTime = moment().isBetween(start, end);

    return (
        <div
            className={`card shadow-md mb-4 cursor-pointer transition hover:shadow-lg ${
                isCurrentTime ? 'border-l-4 border-red-500' : 'border border-gray-50'
            }`}
            onClick={() => setParams({ action: 'view', id: schedule._id })}
        >
            <div className="card-body" style={{padding: '10px'}}>
                <div className="flex justify-between items-center text-lg font-bold">
                    <span className={isCurrentTime ? 'text-red-500' : 'text-gray-800'}>
                        {start.format('HH:mm')} - {end.format('HH:mm')} | {schedule.service.name}
                    </span>
                    <ScheduleStatus
                        key={schedule._id}
                        clientId={schedule.client.uid}
                        status={schedule?.status}
                        id={schedule._id}
                    />
                </div>

                <div className="text-sm text-gray-600">
                    <p>Cliente: {schedule.client.name}</p>
                    <p>Atendente: {schedule.attendant.name}</p>
                </div>

                {schedule?.confirmation?.date && (
                    <div className="badge badge-success badge-outline">
                        <i className="fa-solid fa-calendar-check mr-1"></i>
                        Confirmado {moment(schedule.confirmation.date).fromNow()}
                    </div>
                )}
                {schedule?.cancellation?.date && (
                    <div className="badge badge-error badge-outline">
                        <i className="fa-solid fa-calendar-xmark mr-1"></i>
                        Cancelado {moment(schedule.cancellation.date).fromNow()}
                    </div>
                )}
            </div>
        </div>
    );
}
