import React, { useState } from 'react';
import { useParamsContext } from '../../contexts/ParamsContext';
import Form from '../../components/Form';
import { UsuariosSistemaService } from './Service';
import { useAppContext } from '../../contexts/AppContext';
import { useUsersContext } from './Context';
const service = new UsuariosSistemaService();

export default function UsuariosSistemaForm() {
    const { clear } = useParamsContext();
    const { fetchUsers } = useUsersContext();
    const { successSnackbar, errorSnackbar } = useAppContext();

    const { id } = useParamsContext();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const result = await service.create({
                name,
                email,
            });

            if (result?.status === 201) {
                successSnackbar('Usuário criado com sucesso!');
                fetchUsers();
            } else {
                errorSnackbar('Ocorreu um erro ao criar o usuário.');
            }
        } catch (e) {
            errorSnackbar('Ocorreu um erro ao criar o usuário.');
        } finally {
            clear();
        }
    };

    return (
        <div>
            <Form onSubmit={submit}>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Nome</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Nome do usuário"
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Email</span>
                    </div>
                    <input
                        type="email"
                        className="input input-bordered"
                        placeholder="Email do usuário"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>

                <div className="divider"></div>

                <button className="btn shadow-md" type="submit">
                    {!!id ? 'Editar' : 'Adicionar'}
                </button>
            </Form>
        </div>
    );
}
