import AgendaSettingsAusencicas from '../../../ausencias/Ausencias';
import { AgendaSettingsProvider } from './Context';
import AgendaSettingsGeral from './Geral';
import AgendaSettingsHorarios from './Horarios';

function AgendaSettings() {
    return (

        <div className="join join-vertical w-full pt-2">
            <div className="collapse collapse-plus join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" defaultChecked />
                <div className="collapse-title text-xl font-medium">Geral</div>
                <div className="collapse-content">
                    <AgendaSettingsGeral />
                </div>
            </div>

            <div className="collapse collapse-plus join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title text-xl font-medium">Horarios</div>
                <div className="collapse-content">
                    <AgendaSettingsHorarios />
                </div>
            </div>

            <div className="collapse collapse-plus join-item border-base-300 border">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title text-xl font-medium">Ausencias</div>
                <div className="collapse-content">
                    <AgendaSettingsAusencicas />
                </div>
            </div>
        </div>
    );
}

export default function _() {
    return (
        <AgendaSettingsProvider>
            <AgendaSettings />
        </AgendaSettingsProvider>
    );
}
