import React, {useEffect, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {useLandingPageContext} from '../Context';

export const Gallery = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const {estab} = useLandingPageContext();

    const images = estab?.images || [];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % images.length);
        }, 5000);

        return () => clearInterval(timer);
    }, [images.length]);

    return (
        <section id="gallery" className="py-16">
            <div className="container mx-auto px-4 h-full">
                <div className="flex items-center mb-8">
                    <hr className="flex-grow border-t border-gray-700"/>
                    <h2 className="text-3xl font-bold text-center mx-4 text-white text-shadow">Galeria</h2>
                    <hr className="flex-grow border-t border-gray-700"/>
                </div>

                <div className="flex justify-center items-center">
                    <div className="w-full max-w-4xl relative overflow-hidden h-[600px] rounded-xl shadow-2xl">
                        {images.map((image: any, index: any) =>
                            index === currentSlide ? (
                                <motion.div
                                    key={index}
                                    initial={{opacity: 0, scale: 1.1}}
                                    animate={{opacity: 1, scale: 1}}
                                    exit={{opacity: 0, scale: 1.1}}
                                    transition={{duration: 1.5}}
                                    className="absolute inset-0 h-full w-full"
                                >
                                    <img
                                        src={image.url}
                                        alt="Imagem da galeria"
                                        className="w-full h-full object-cover z-40"
                                    />
                                    {image.caption && (
                                        <div
                                            className="absolute inset-x-0 bottom-0 bg-black bg-opacity-60 text-white p-6 backdrop-blur-sm">
                                            <h3 className="text-2xl font-semibold text-center">
                                                {image.caption}
                                            </h3>
                                        </div>
                                    )}
                                </motion.div>
                            ) : null
                        )}
                    </div>
                </div>
            </div>
            <div className="inset-x-0 flex justify-center space-x-3 mt-4 shadow-2xl">
                {images.map((_: any, index: any) => (
                    <button
                        key={index}
                        className={`w-3 h-3 transition rounded-full ${
                            index === currentSlide
                                ? 'bg-white scale-125 w-[25px]'
                                : 'bg-white hover:bg-white'
                        }`}
                        style={{boxShadow: '#000000 0 50px 40px !important'}}
                        onClick={() => setCurrentSlide(index)}
                        aria-label={`Ir para o slide ${index + 1}`}
                    />
                ))}
            </div>
        </section>
    );
};
