import { api } from '../../AxiosConfig';

class AtendentesService {
    create(data: any) {
        return api.post('users/atendentes/create', data);
    }

    getAll() {
        return api.get('users/atendentes/getAll');
    }

    getByEstabId(estabId: string) {
        return api.get(`users/atendentes/getByEstabId/${estabId}`);
    }

    delete(uid: string) {
        return api.post('users/atendentes/delete', { uid });
    }

    update(uid: string, update: {
        admin: string
    }) {
        return api.post('users/atendentes/update', { uid, update });
    }
}

export { AtendentesService };
