import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { EstabelecimentosService } from '../../../estabelecimentos/system-admin/Service';
import { useEstabsContext } from '../../../../contexts/EstabsContext';
import { useAppContext } from '../../../../contexts/AppContext';
import {Horarios, initialHorarios} from '../../Enum';

const serviceEstab = new EstabelecimentosService();

interface AgendaSettingsContextProps {
    daysOpen: number;
    setDaysOpen: React.Dispatch<React.SetStateAction<number>>;
    horarios: Horarios;
    setHorarios: React.Dispatch<React.SetStateAction<Horarios>>;
    requireApproval: boolean;
    setRequireApproval: React.Dispatch<React.SetStateAction<boolean>>;
    requireConfirmation: boolean;
    setRequireConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    hoursBeforeConfirmation: number;
    setHoursBeforeConfirmation: React.Dispatch<React.SetStateAction<number>>;
    sendWhatsapp: boolean;
    setSendWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
    sendEmail: boolean;
    setSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
    submit: () => void;
    loading: boolean;
}

const validateHorarios = (horarios: Horarios): Horarios => {
    const validatedHorarios: Horarios = {};
    for (const [day, slots] of Object.entries(horarios)) {
        validatedHorarios[day] = slots.filter((slot) => slot.start > 0 && slot.end > 0 && slot.start < slot.end);
    }
    return validatedHorarios;
};

const AgendaSettingsContext = createContext<AgendaSettingsContextProps | undefined>(undefined);

const AgendaSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { errorSnackbar, successSnackbar } = useAppContext();
    const { estabSession, reloadEstab } = useEstabsContext();

    const [loading, setLoading] = useState(false);
    const [daysOpen, setDaysOpen] = useState(10);
    const [horarios, setHorarios] = useState<Horarios>(initialHorarios);
    const [requireApproval, setRequireApproval] = useState(false);
    const [requireConfirmation, setRequireConfirmation] = useState(false);
    const [hoursBeforeConfirmation, setHoursBeforeConfirmation] = useState(24);
    const [sendWhatsapp, setSendWhatsapp] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);

    const submit = async () => {
        try {
            setLoading(true);
            const validatedHorarios = validateHorarios(horarios);

            await serviceEstab.update(estabSession._id, {
                agenda: {
                    daysOpen,
                    horarios: validatedHorarios,
                    requireApproval,
                    requireConfirmation,
                    hoursBeforeConfirmation,
                    sendWhatsapp,
                    sendEmail,
                },
            });

            successSnackbar('Agenda editada com sucesso!');
            reloadEstab();
        } catch (e) {
            errorSnackbar('Ocorreu um erro ao editar a agenda.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (estabSession) {
            setDaysOpen(estabSession?.agenda?.daysOpen || 0);
            setRequireApproval(estabSession?.agenda?.requireApproval || false);
            setRequireConfirmation(estabSession?.agenda?.requireConfirmation || false);
            setHoursBeforeConfirmation(estabSession?.agenda?.hoursBeforeConfirmation || 24);
            setSendWhatsapp(estabSession?.agenda?.sendWhatsapp || false);
            setSendEmail(estabSession?.agenda?.sendEmail || false);

            const doc = {
                ...initialHorarios,
                ...(estabSession?.agenda?.horarios || {}),
            };
            delete doc._id;
            setHorarios(doc);
        }
    }, [estabSession]);

    return (
        <AgendaSettingsContext.Provider
            value={{
                daysOpen,
                setDaysOpen,
                horarios,
                setHorarios,
                requireApproval,
                setRequireApproval,
                requireConfirmation,
                setRequireConfirmation,
                hoursBeforeConfirmation,
                setHoursBeforeConfirmation,
                sendWhatsapp,
                setSendWhatsapp,
                submit,
                loading,
                sendEmail,
                setSendEmail,
            }}
        >
            {children}
        </AgendaSettingsContext.Provider>
    );
};

const useAgendaSettingsContext = () => {
    const context = useContext(AgendaSettingsContext);
    if (context === undefined) {
        throw new Error('useAgendaSettingsContext must be used within an AgendaSettingsProvider');
    }
    return context;
};

export { AgendaSettingsProvider, useAgendaSettingsContext };
