import React, { useState, useEffect } from 'react';
import { useParamsContext } from '../../contexts/ParamsContext';
import Form from '../../components/Form';
import { AtendentesService } from './Service';
import { useAppContext } from '../../contexts/AppContext';
import { useAtendentesContext } from './Context';

const service = new AtendentesService();

export default function AtendentesForm() {
    const { clear } = useParamsContext();
    const { fetchAtendentes } = useAtendentesContext();
    const { successSnackbar, errorSnackbar } = useAppContext();

    const { id } = useParamsContext();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [admin, setAdmin] = useState(false);

    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const result = await service.create({
                name,
                email,
                admin,
            });

            if (result?.status === 201) {
                successSnackbar('Atendente criado com sucesso!');
                fetchAtendentes();
            } else {
                errorSnackbar('Ocorreu um erro ao criar o atendente.');
            }
        } catch (e) {
            errorSnackbar('Ocorreu um erro ao criar o atendente.');
        } finally {
            clear();
        }
    };

    return (
        <div>
            <Form onSubmit={submit}>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Nome</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Nome do atendente"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Email</span>
                    </div>
                    <input
                        type="email"
                        className="input input-bordered"
                        placeholder="Email do atendente"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>

                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Administrador</span>
                    </div>
                    <input
                        type="checkbox"
                        className="toggle toggle-accent"
                        checked={admin}
                        onChange={(e) => setAdmin(e.target.checked)}
                    />
                </label>

                <div className="divider"></div>

                <button className="btn shadow-md" type="submit">
                    {!!id ? 'Editar' : 'Adicionar'}
                </button>
            </Form>
        </div>
    );
}
