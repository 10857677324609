import { useParamsContext } from '../../contexts/ParamsContext';
import AtendentesForm from './Form';
import AtendentesDelete from './Delete';
import AtendentesList from './List';

export default function Atendentes() {
    const { action, id } = useParamsContext();

    switch (action) {
        case 'edit':
        case 'add': {
            return <AtendentesForm />;
        }
        case 'delete':
            return <AtendentesDelete />;
        case 'list':
        default:
            return <AtendentesList />;
    }
}
