import React, { useEffect, useState } from 'react';
import { useParamsContext } from '../../../contexts/ParamsContext';
import PriceInput from '../../../components/inputs/Price';
import DurationInput from '../../../components/inputs/Duration';
import { ServicosService } from '../Service';
import { useAppContext } from '../../../contexts/AppContext';
import { useServicosContext } from '../Context';
import SelectAtendentes from '../../../components/inputs/SelectAtendentes';
import FormServicoImages from './Images';

const service = new ServicosService();

export default function ServicosForm() {
    const [loading, setLoading] = useState(false);
    const { clear, id } = useParamsContext();
    const { reloadData, data } = useServicosContext();
    const { successSnackbar, errorSnackbar } = useAppContext();

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [imagesIds, setImagesIds] = useState([]);
    const [duration, setDuration] = useState(0);
    const [description, setDescription] = useState('');
    const [principalImage, setPrincipalImage] = useState('');
    const [attendantsIds, setAttendantsIds] = useState<string[]>([]);

    useEffect(() => {
        try {
            setLoading(true);

            const doc = data?.find((e) => e._id === id);

            if (!!doc?.name) setName(doc.name);
            if (!!doc?.price) setPrice(doc.price);
            if (!!doc?.duration) setDuration(doc.duration);
            if (!!doc?.imagesIds) setImagesIds(doc.imagesIds);
            if (!!doc?.description) setDescription(doc.description);
            if (!!doc?.attendantsIds) setAttendantsIds(doc.attendantsIds);
            if (!!doc?.principalImage) setPrincipalImage(doc.principalImage);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [id, data]);

    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            setLoading(true);
            if (!!id) {
                await service.update(id, {
                    name,
                    duration,
                    attendantsIds,
                    description,
                    price,
                    imagesIds,
                    principalImage,
                });
                successSnackbar('Serviço editado com sucesso!');
            } else {
                await service.create({
                    name,
                    duration,
                    attendantsIds,
                    description,
                    price,
                    imagesIds,
                    principalImage,
                });
                successSnackbar('Serviço criado com sucesso!');
            }

            reloadData();
        } catch (e) {
            console.log(e);
            errorSnackbar('Ocorreu um erro ao criar o Serviço.');
        } finally {
            clear();
            setLoading(false);
        }
    };

    return (
        <div>
            <form onSubmit={submit}>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Nome</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Nome do serviço que será exibido para seus clientes"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label className="form-control">
                    <DurationInput
                        value={duration}
                        onChange={(value) => setDuration(value)}
                        placeholder="Duração do serviço"
                        label="Duração"
                    />
                </label>
                <label className="form-control">
                    <SelectAtendentes value={attendantsIds} setValue={setAttendantsIds} name="atendentes" />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Descrição</span>
                    </div>
                    <textarea
                        className="textarea textarea-bordered"
                        placeholder="Descrição do serviço"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </label>
                <label className="form-control">
                    <PriceInput
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Preço do serviço"
                        label="Preço"
                    />
                </label>

                <label className="form-control">
                    <div className="label mb-2">
                        <span className="label-text">Imagens</span>
                    </div>

                    <FormServicoImages value={imagesIds} setValue={setImagesIds} disabled={loading} />
                </label>

                <div className="divider"></div>

                <button disabled={loading} className="btn shadow-md" type="submit">
                    {!!id ? 'Editar' : 'Adicionar'}
                </button>
            </form>
        </div>
    );
}
