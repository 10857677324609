import React from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import moment from 'moment';

const paginationComponentOptions = {
    rowsPerPageText: 'Linhas por página:',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

interface DataTableProps<T> extends TableProps<T> {
    loading?: boolean;
    data: Array<T & any>;
}

const CustomDataTable = <T extends any>({ loading, data, ...props }: DataTableProps<T>) => {
    const dataIsEmpty = !data || data.length === 0;

    if (loading) {
        return (
            <div className="h-10 items-center flex justify-center">
                <span className="loading loading-bars loading-lg" />
            </div>
        );
    }

    if (dataIsEmpty) {
        return <div className="text-center">Nenhum registro</div>;
    }

    const currentTime = moment();

    const conditionalRowStyles = [
        {
            when: (row: any) => {
                const createdAt = row.createdAt ? moment(row.createdAt) : null;
                const updatedAt = row.updatedAt ? moment(row.updatedAt) : null;
                const minTime = 5;

                const createdAtDifference = createdAt ? currentTime.diff(createdAt, 'minutes') : Infinity;
                const updatedAtDifference = updatedAt ? currentTime.diff(updatedAt, 'minutes') : Infinity;

                return createdAtDifference < minTime || updatedAtDifference < minTime;
            },
            style: {
                backgroundColor: '#00ff6f12',
            },
        },
    ];

    return (
        <DataTable
            pagination
            paginationRowsPerPageOptions={[5, 15, 20]}
            paginationComponentOptions={paginationComponentOptions}
            conditionalRowStyles={conditionalRowStyles}
            data={data}
            {...props}
        />
    );
};

export default CustomDataTable;
