import MultiSelect from '../../../components/inputs/MultiSelect';
import { StatusHorario, StatusHorarioLabels } from '../Enum';
import { useAgendaContext } from './ContextAgenda';

export const StatusFilter = () => {
    const { filter, setFilter } = useAgendaContext();

    const statusOptions = Object.values(StatusHorario).map((status) => ({
        label: StatusHorarioLabels[status],
        value: status,
    }));

    const handleStatusChange = (selectedValues: string[]) => {
        setFilter((prevFilter: any) => ({
            ...prevFilter,
            status: selectedValues,
        }));
    };

    return (
        <MultiSelect
            label="Status"
            options={statusOptions}
            placeholder="Selecione o status"
            value={filter?.status || []}
            onChange={handleStatusChange}
        />
    );
};
