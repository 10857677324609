import { useParamsContext } from '../../contexts/ParamsContext';
import UsuariosForm from './Form';
import UsuariosList from './List';

export default function Usuarios() {
    const { action, id } = useParamsContext();

    switch (action) {
        case 'edit':
        case 'add': {
            return <UsuariosForm />;
        }
        case 'list':
        default:
            return <UsuariosList />;
    }
}
