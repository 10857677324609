import { useEstabsContext } from '../../../contexts/EstabsContext';
import { useParamsContext } from '../../../contexts/ParamsContext';
import { EstabsClientAddFirst } from '../../estabelecimentos/client/AddFirst';
import { FormHorarioClienteProvider } from './Form/Context';
import { AgendaForm } from './Form/Form';
import { AgendaList } from './List/List';

export default function AgendaCliente() {
    const { estabsUser } = useEstabsContext();

    return estabsUser.length > 0 ? <Agenda /> : <EstabsClientAddFirst />;
}

const Agenda = () => {
    const { action } = useParamsContext();

    switch (action) {
        case 'add':
            return (
                <FormHorarioClienteProvider>
                    <AgendaForm />
                </FormHorarioClienteProvider>
            );
        default:
            return <AgendaList />;
    }
};
