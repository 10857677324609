import { api } from '../../AxiosConfig';

export class FileService {
    async upload(
        fileContent: string,
        fileName: string,
        size: number,
        fileType: string,
        lastModifiedDate?: Date,
        appId?: string
    ) {
        const base64Pattern = /^data:(.*?);base64,(.*)$/;

        const match = fileContent.match(base64Pattern);

        if (!match) {
            throw new Error('Invalid file content format. Ensure it is a valid base64 string.');
        }

        const base64Data = match[2];

        return api.post('/files/upload', {
            size,
            fileType,
            appId,
            fileName,
            lastModifiedDate,
            fileContent: base64Data,
        });
    }

    findOne(fileId: string) {
        return api.get(`/files/findOne/${fileId}`);
    }
}
