import moment from 'moment';
import ScheduleStatus from '../components/ScheduleStatus';
import { getInitialName } from '../../../../components/utils';
import { Agendamento } from '../../Enum';

export interface ScheduleViewHeaderProps {
    schedule: Agendamento;
}

export default function ScheduleViewHeader({ schedule }: ScheduleViewHeaderProps) {
    const start = moment(schedule.startAt);
    const end = moment(schedule.endAt);

    return (
        <div className="shadow w-full sm:stats">
            <div className="sm:stat grid justify-items-center pb-5">
                <div className="flex gap-2">
                    <div className="avatar h-14">
                        <div className="bg-neutral mask mask-squircle text-neutral-content content-center text-center">
                            {schedule.client.profileImageUrl ? (
                                <img src={schedule.client.profileImageUrl} />
                            ) : (
                                <span className="text-xl">{getInitialName(schedule.client.name)}</span>
                            )}
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="text-lg">{schedule.client.name}</div>
                        </div>
                        <button
                            onClick={() => {
                                if (schedule.client.phoneNumber) {
                                    const formattedPhone = schedule.client.phoneNumber.replace(/\D/g, '');
                                    const whatsappLink = `https://wa.me/${formattedPhone}`;
                                    window.open(whatsappLink, '_blank');
                                }
                            }}
                            disabled={!schedule.client.phoneNumber}
                            className="btn btn-circle btn-xs"
                        >
                            <i className="fa-brands fa-whatsapp"></i>
                        </button>
                    </div>
                </div>

                <div>
                    <div className="text-xs my-2">
                        {schedule.client.email}
                        {!!schedule.client.phoneNumber && ` | ${schedule.client.phoneNumber}`}
                    </div>
                </div>
                <div className="stat-desc text-secondary">
                    {(Number(schedule.client.amountSchedules) || 0) > 1
                        ? `${schedule.client.amountSchedules} agendamentos realizados`
                        : Number(schedule.client.amountSchedules) === 1
                          ? 'Primeiro agendamento realizado'
                          : 'Nenhum agendamento realizado'}
                </div>
            </div>

            <div className="sm:stat grid justify-items-center pb-5">
                <div className="text-xs opacity-70">{start.format('dddd, D [de] MMMM [de] YYYY')}</div>
                <div className="stat-value text-secondary">
                    {start.format('HH:mm')} - {end.format('HH:mm')}
                </div>

                <ScheduleStatus
                    clientId={schedule.client.uid}
                    key={schedule._id}
                    status={schedule.status}
                    id={schedule._id}
                />
            </div>
        </div>
    );
}
