import { useEffect } from 'react';
import { useEstabsContext } from '../../../../contexts/EstabsContext';
import { urlFile } from '../../../../AxiosConfig';
import { useFormHorarioClienteContext } from './Context';

export const SelectEstab = () => {
    const { estabsUser } = useEstabsContext();
    const { estab, setEstab } = useFormHorarioClienteContext();

    useEffect(() => {
        if (estabsUser.length === 1) {
            setEstab(estabsUser[0]);
        }
    }, [estabsUser]);

    return (
        <div className={`flex flex-wrap gap-4`}>
            {estabsUser
                .filter(({ type }) => type === 'client')
                .map((e: any, i: any) => (
                    <div
                        key={i}
                        className={`w-80 animate__animated animate__faster ${
                            !!estab?._id ? 'hidden' : 'animate__fadeInLeft animate__faster'
                        }`}
                        onClick={() => setEstab(e)}
                    >
                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl">
                            <div className="cursor-pointer">
                                {e?.imageId && (
                                    <figure className="p-4">
                                        <img src={`${urlFile}/${e?.imageId}`} alt="Shoes" />
                                    </figure>
                                )}
                                <div className="card-body">
                                    <h2 className="card-title justify-center">{e?.name}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
