import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { UserService } from './Service';
import { useAppContext } from '../../../contexts/AppContext';
import { Loading } from '../../../components/Loading'; // Certifique-se de importar o componente de loading

const service = new UserService();

interface UserSettings {
    emailNotifications: boolean;
    pushNotifications: boolean;
}

const ConfigCliente: React.FC = () => {
    const { errorSnackbar, successSnackbar } = useAppContext();
    const [settings, setSettings] = useState<UserSettings>({
        emailNotifications: false,
        pushNotifications: false,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        service
            .getSettings()
            .then(({ data }) => {
                if (data) {
                    setSettings({
                        emailNotifications: !!data.emailNotifications,
                        pushNotifications: !!data.pushNotifications,
                    });
                }
            })
            .catch(() => {
                errorSnackbar('Erro ao buscar configurações do usuário.');
            });
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = event.target;
        const checked = type === 'checkbox' ? (event.target as HTMLInputElement).checked : undefined;

        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        setLoading(true);

        service
            .updateSettings(settings)
            .catch(() => {
                errorSnackbar('Erro ao salvar configurações.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Receber notificações por email:</span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        name="emailNotifications"
                        checked={settings.emailNotifications}
                        onChange={handleInputChange}
                    />
                </label>
                <span className="text-sm text-gray-500">
                    Ative para receber notificações e atualizações por email, incluindo confirmações de agendamentos.
                </span>
            </div>

            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Receber notificações push:</span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        name="pushNotifications"
                        checked={settings.pushNotifications}
                        onChange={handleInputChange}
                    />
                </label>
                <span className="text-sm text-gray-500">
                    Ative para receber alertas rápidos diretamente no seu dispositivo, como lembretes e confirmações.
                </span>
            </div>

            <div className="divider" />

            <button className={`btn shadow-md ${loading ? 'btn-disabled' : ''}`} type="submit" disabled={loading}>
                {loading ? <Loading /> : 'Salvar'}
            </button>
        </form>
    );
};

const ConfigAtendente = () => {
    const { errorSnackbar } = useAppContext();
    const [settings, setSettings] = useState<UserSettings>({
        emailNotifications: false,
        pushNotifications: false,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        service
            .getSettings()
            .then(({ data }) => {
                if (data) {
                    setSettings({
                        emailNotifications: !!data.emailNotifications,
                        pushNotifications: !!data.pushNotifications,
                    });
                }
            })
            .catch(() => {
                errorSnackbar('Erro ao buscar configurações do usuário.');
            });
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = event.target;
        const checked = type === 'checkbox' ? (event.target as HTMLInputElement).checked : undefined;

        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        setLoading(true);

        service
            .updateSettings(settings)
            .catch(() => {
                errorSnackbar('Erro ao salvar configurações.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Receber notificações por email:</span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        name="emailNotifications"
                        checked={settings.emailNotifications}
                        onChange={handleInputChange}
                    />
                </label>
                <span className="text-sm text-gray-500">
                    Ative para receber notificações e atualizações por email, incluindo confirmações de agendamentos.
                </span>
            </div>

            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Receber notificações push:</span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        name="pushNotifications"
                        checked={settings.pushNotifications}
                        onChange={handleInputChange}
                    />
                </label>
                <span className="text-sm text-gray-500">
                    Ative para receber alertas rápidos diretamente no seu dispositivo, como lembretes e confirmações.
                </span>
            </div>

            <div className="divider" />

            <button className={`btn shadow-md ${loading ? 'btn-disabled' : ''}`} type="submit" disabled={loading}>
                {loading ? <Loading /> : 'Salvar'}
            </button>
        </form>
    );
};

export default function ConfiguracoesContaNotifications() {
    const { user } = useAuthContext();

    if (user?.client) {
        return <ConfigCliente />;
    } else {
        return <ConfigAtendente />;
    }
}
